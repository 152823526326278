import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { AppState } from 'reducers';
import React from 'react';
import './Cards.scss'
import { editImage } from '../../../lib/communication/orders';
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import { useSelector } from "react-redux";
import { EditAssortmentImage } from "lib/types";

interface EditImageModalCardProps {
	path: string;
	setModalVisibility: (visibility: boolean) => void;
	isModalOpen: boolean;
	name: string;
}

const EditImageModalCard = (props: EditImageModalCardProps) => {
	const [image, setImage] = useState<File>();
	const [responseMessage, setResponseMessage] = useState<EditAssortmentImage | null>();
	const [errorMessage, setErrorMessage] = useState<string>();
	const isFetching = useSelector((state: AppState) => state.data.orders.editImageUploadFetching);

	const setNewImage = (e: React.ChangeEvent<HTMLInputElement>) => {
		setImage(undefined);
		if (e.target.files !== null && e.target.files[0] !== undefined) {
			const fileExt = e.target.files[0].name.split('.').pop();
			if (fileExt !== 'png' && fileExt !== 'jpg' && fileExt !== 'svg') {
				setErrorMessage('Nieobsługiwany format pliku');
				setResponseMessage(null);
				setImage(undefined);
				return;
			}
			const newFile = new File([e.target.files[0]], props.path.replace('KWWW\\', '').replaceAll(/\\/g, "_") + '.' + fileExt);
			setImage(newFile);
		}
		setResponseMessage(null);
		setErrorMessage('');
	}

	const handleNewImageUpload = async () => {
		setResponseMessage(null);
		if (image instanceof File) {
			const response = await editImage(image);
			if (typeof response === 'boolean') setResponseMessage(null);
			else setResponseMessage(response);
			setErrorMessage('');
		}
	}

	return (
		<Modal onHide={() => { props.setModalVisibility(false); setResponseMessage(null); }} show={props.isModalOpen} centered className="edit-image-modal">
			<Modal.Header closeButton className="edit-image-modal-header">
				<Modal.Title>Edycja grafiki</Modal.Title>
			</Modal.Header>
			<Modal.Body className="edit-image-modal-body">
				<h5>{props.name} ({props.path.replace('KWWW\\', '').replaceAll(/\\/g, "_")})</h5>
				<input onChange={setNewImage} type="file" accept=".jpg, .png, .svg" />
				<p style={{ fontWeight: 'bold' }}>Maksymalny rozmiar pliku wynosi 2MB.</p>
				<p style={{ fontWeight: 'bold', color: 'red' }}>{errorMessage}</p>
				{responseMessage !== null && responseMessage !== undefined ?
					<p style={{ color: responseMessage.error ? 'red' : 'black', fontWeight: responseMessage.error ? 'bold' : 'normal' }}>{responseMessage.message} [{responseMessage.filename}]</p>
					:
					<></>
				}
			</Modal.Body>
			<Modal.Footer className="edit-image-modal-footer">
				<ButtonComponent
					text='Zastąp grafikę'
					onClick={handleNewImageUpload}
					variant='primary'
					margin='0.25rem'
				/>
				<div style={{ fontStyle: 'italic', marginRight: 'auto' }}> {isFetching ? <Spinner animation="border" /> : <></>} </div>
				<ButtonComponent
					text='Zamknij'
					onClick={() => props.setModalVisibility(false)}
					variant='secondary'
					margin='0.25rem'
				/>
			</Modal.Footer>
		</Modal>
	);
};

export default EditImageModalCard;