import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap'
import EditableText from 'components/EditableText';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { acceptTermsAndConditions, getTermsAndConditions, setTermsAndConditions } from 'lib/communication/promotionalProgram';
import { useUser } from 'reducers';
import Spinner from 'components/Spinner/Spinner';
import { User } from 'lib';
import store from 'store';
import { sessionActions } from 'reducers';
import { useHistory } from 'react-router-dom';

export default function TermsAcceptation() {
	const termsAndConditions = useSelector((state: AppState) => state.data.promotionalProgram.termsAndConditions);
	const isFetching = useSelector((state: AppState) => state.data.promotionalProgram.props.isFetching);
	const termName = useSelector((state: AppState) => state.conf.promotion);
	const [error, setError] = useState(false);
	const user = useUser();
	const [accepted, setAccepted] = useState(false);

	const history = useHistory();

	const onChangeHandle = (e: any) => {
		if (e.target !== undefined) {
			setAccepted(e.target.checked);
		}
	};

	const onClickHandle = async () => {
		try {
			if (user !== null) {
				const termsAccepted = await acceptTermsAndConditions();

				if (termsAccepted) {
					const D = new Date();
					const acceptDateString = `${D.getFullYear()}${(D.getMonth()+1).toString().padStart(2, '0')}${D.getDate().toString().padStart(2, '0')}`;
					let user2: User = {...user};
					user2.promotionalProgramEnroll = acceptDateString;
					user2.promotionTermsAccept = acceptDateString;
					store.dispatch(sessionActions.setUser(user2));
					history.replace(`${process.env.PUBLIC_URL}/promotions/articles`);
				}
			}
		}
		catch (err) {
			console.log("Error", err);
		}
	};

	useEffect(() => {
		if (termName !== "") {
			getTermsAndConditions(termName)
				.then(result => setError(!result));
		}
	}, [termName]);

	if (isFetching || error) {
		return <Spinner showError={error} />
	}

	return <>
		<EditableText value={termsAndConditions} onSubmit={val => setTermsAndConditions(val, termName)} />
		<div style={{ marginTop: '50px', display: 'flex' }}>
			<Form.Check name={'accept'} type="checkbox" style={{ margin: '0 5px 0 15px' }}
				defaultChecked={false}
				onChange={e => onChangeHandle(e)}
			/>
			<p>Zapoznałem się i akceptuję warunki, regulamin i politykę prywatności</p>
		</div>
		<Button
			style={{ boxShadow: 'none', display: 'flex', margin: '10px 0 0 15px' }}
			disabled={!accepted}
			onClick={() => onClickHandle()}
		>ZAPISZ SIĘ</Button>
	</>;
}