import React from 'react';
import { buildResourceLink } from 'lib/communication';
import { ContentBlock, ContentState, EditorState } from 'draft-js';
import styles from './ImageDraft.module.css';

interface ImageDraftProps {
	block: ContentBlock,
	entityKey: string,
	contentState: ContentState;
	blockProps: {
		setImage: (value: { entityKey: string, contentState: ContentState }) => void,
		config: {
			editorState: EditorState,
			onChange: (_: EditorState) => void
		},
		uploadFile: (file: File) => Promise<string>
	}
}

export default function ImageDraft(props: ImageDraftProps) {

	const { block, entityKey, contentState, blockProps } = props;

	if (block && !block.getEntityAt(0)) return null;

	const entityImage = contentState.getEntity(block ? block.getEntityAt(0) : entityKey);

	const { href, src, height, width, alt } = entityImage.getData()

	const content = (
		<img
			src={buildResourceLink(src)}
			alt={alt}
			style={{
				height: isNaN(height) ? height : height + 'px',
				width: isNaN(width) ? width : width + 'px',
			}}
		/>
	);

	if (!blockProps) {
		return (
			<div>
				{href ? <a href={buildResourceLink(href)} target="_blank" rel="noopener noreferrer">{content}</a> : content}
			</div>
		);
	}

	const { config, uploadFile } = blockProps;

	const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			uploadFile(event.target.files[0])
				.then((href: string) => {
					const entityKey = block.getEntityAt(0);
					contentState.mergeEntityData(
						entityKey,
						{ href },
					);
					config.onChange(EditorState.push(config.editorState, contentState, 'change-block-data'));
				})
		}
	}

	const changeImageSize = () => {
		const entityKey = block.getEntityAt(0);
		blockProps.setImage({ entityKey, contentState });
	}

	return (
		<div className={styles.root}>
			{content}
			<div className={styles.hyperlinkSettings}>
				<div>
					<input type="button" value="Zmień rozmiar obrazka" onClick={changeImageSize} />
				</div>
				<div>
					Link do zdjęcia: <a href={buildResourceLink(src)} target="_blank" rel="noopener noreferrer">{buildResourceLink(src)}</a>
				</div>
				{href ? (<>
					<div>
						Załączony dokument: <a href={buildResourceLink(href)} target="_blank" rel="noopener noreferrer">{buildResourceLink(href)}</a>
					</div>
					<div>Zastąp dokument:</div>
					<div>
						<input
							type="file"
							id="file"
							accept={'application/pdf'}
							onChange={selectFile}
						/>
					</div>
				</>) : (<>
					<div>Dodaj załącznik do zdjęcia:</div>
					<div>
						<input
							type="file"
							id="file"
							accept={'application/pdf'}
							onChange={selectFile}
						/>
					</div>
				</>)}
			</div>
		</div >
	);
}
