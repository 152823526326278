import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { LG } from 'lib/util';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppState, useUser } from 'reducers';
import './AssortmentSelection.scss';
import _ from 'lodash';
import { Product, CartContent, ViewId } from 'lib/types';
import { mapLocalCartToCartContents } from "lib/utilityMetods";
import { isLeaf, makeRoot, mapTree, TreeNode } from 'lib/tree';
import GutterCreator from 'components/GutterCreator';
import PlatesCreator from 'components/PlatesCreator';
import Cart from '../Cart';
import CartReset from '../CartReset';
import { dataActions } from 'reducers';
import store from 'store';
import { getAdditionalAssortmentInfo, getProductsByGroup } from 'lib/communication/orders';
import { getGroups } from 'lib/communication/orders';
import SimilarProducts from './ProductSelection/SimilarProducts';
import ProductSelection from './ProductSelection/ProductSelection';
import AdditionalAssortment from './ProductSelection/AdditionalAssortment';
import AssortmentSelectionHeader from './AssortmentSelectionHeader';
import BasicView from './BasicView';
import AdvancedView from './AdvancedView';
import { produce } from 'immer';
import ButtonComponent from 'components/ButtonComponent';
import { userService } from 'services';
import useDebounce from 'lib/hooks/useDebounce';

export const BASIC_GROUP = 'KWWW';
export const SPECIAL_GROUP = '';
export const GROUP_CATEGORY_NAME = ['Rodzaj', 'Typ', 'Powłoka', 'Grubość', 'Kolor'];
export const GROUP_ORDER = [1, 2, 3, 4, 5];

export const isSpecialGroup = (selectedPath: string): boolean => {
	return _.includes(pathToArray(selectedPath), SPECIAL_GROUP);
};

export const firstNGroups = (path: string, n: number | undefined): string => {
	return pathToArray(path).slice(0, n).join("\\")
}

export const pathToArray = (path: string): string[] => {
	return path.split('\\');
};

export const arrayPathToString = (pathArray: string[]): string => {
	return pathArray.join("\\")
}

export const showCartMessage = () => {
	const cartMessage = document.getElementsByClassName('AssortmentSelection-cartMessage')[0];

	cartMessage.className = "AssortmentSelection-cartMessage show";
	setTimeout(() => {
		cartMessage.className = "AssortmentSelection-cartMessage";
	}, 4500);
}

interface AssortmentSelectionProps {
	goToSummary: () => void;
	cartReset: boolean;
	setCartReset: (value: boolean) => void;
	loadedCart: React.MutableRefObject<boolean>;
}

export type AssortmentSelectionState = {
	selectedView: ViewId;
	selectedPath: string;
	selectedProduct?: Product | null;
	editedOrderId: string | null;
}

const ASSORTMENT_SELECTION_STATE: AssortmentSelectionState = {
	selectedView: 'basic',
	selectedPath: BASIC_GROUP,
	selectedProduct: null,
	editedOrderId: null
};

export default function AssortmentSelection(props: AssortmentSelectionProps) {

	const history = useHistory<AssortmentSelectionState>();

	const state: AssortmentSelectionState = history.location.state ? (history.location.state as AssortmentSelectionState) : ASSORTMENT_SELECTION_STATE;

	const isDesktop = useMediaQuery({ minWidth: LG });
	const edit = useSelector((state: AppState) => state.data.orders.edit);
	const groups = useSelector((state: AppState) => state.data.orders.groupsAndProducts.groups);
	const company = useSelector((state: AppState) => state.session.user?.company);
	const mag = useSelector((state: AppState) => state.session.user?.mag);
	const products = useSelector((state: AppState) => state.data.orders.groupsAndProducts.products);
	const localCart = useSelector((state: AppState) => edit ? state.data.orders.localEditCart : state.data.orders.localCart);
	const advancedTree = useSelector((state: AppState) => state.data.orders.advancedTree);
	const gutterScripts = useSelector((state: AppState) => state.conf.GutterCreatorScripts);
	const isFetchingGroups = useSelector((state: AppState) => state.data.orders.props.isFetchingGroups);
	const cartContent = useSelector((state: AppState) => edit ? state.data.orders.editCart : state.data.orders.cart);

	const user = useUser();
	const [defaultNewOrderViewDesktop, setDefaultNewOrderViewDesktop] = useState<string>();
	const [defaultNewOrderViewMobile, setDefaultNewOrderViewMobile] = useState<string>();
	const [showCartReset, setShowCartReset] = useState(!(_.isEmpty(cartContent.productGroups) && _.isEmpty(cartContent.gutterGroups)) && props.cartReset);

	useEffect(() => {
		const params = userService.getUserParams();
		params?.forEach(param => {
			if (param.ParamName === "DefaultNewOrderView") {
				setDefaultNewOrderViewDesktop(param.ParamValue);
			}
			if (param.ParamName === "DefaultNewOrderViewMobile") {
				setDefaultNewOrderViewMobile(param.ParamValue);
			}
		});
	});

	useEffect(() => {
		if (props.loadedCart) {
			if (!(_.isEmpty(cartContent.productGroups) && _.isEmpty(cartContent.gutterGroups)) && props.cartReset) {
				setShowCartReset(true);
			}
			else {
				props.setCartReset(false);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.loadedCart]);

	const [showCart, setShowCart] = useState(false);
	const [selectedView, setSelectedView] = useState((isDesktop ? defaultNewOrderViewDesktop : defaultNewOrderViewMobile) as ViewId);

	useEffect(() => {
		setSelectedView((isDesktop ? defaultNewOrderViewDesktop : defaultNewOrderViewMobile) as ViewId);
	}, [isDesktop, defaultNewOrderViewDesktop, defaultNewOrderViewMobile]);

	const [rememberedSelectedView, setRememberedSelectedView] = useState<ViewId>();
	const [ifSelectedView, setIfSelectedView] = useState(false);

	useEffect(() => {
		if (!ifSelectedView && selectedView !== undefined) {
			setRememberedSelectedView(selectedView);
			setIfSelectedView(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedView, defaultNewOrderViewDesktop, defaultNewOrderViewMobile]);

	const [selectedPath, setSelectedPath] = useState(state.selectedPath ? state.selectedPath : BASIC_GROUP);
	const [selectedProduct, setSelectedProduct] = useState((state.selectedProduct ? state.selectedProduct : null) as Product | null);
	const [currentLevel, setCurrentLevel] = useState(0);
	const [error, setError] = useState(false);
	const [filteredTree, setFilteredTree] = useState<TreeNode[] | null>(null);
	const [findInTree, setFindInTree] = useState('');
	const [additionalAssortmentFetching, setAdditionalAssortmentFetching] = useState(true);

	const inputRef = useRef<HTMLInputElement | null>(null);

	const debouncedFindInTree = useDebounce(findInTree, 300);

	useEffect(() => {
		// Load history state
		let sv = ['basic', 'advanced', 'treatment', 'gutters', 'plate'].includes(history.location.state?.selectedView) ?
			history.location.state.selectedView : (rememberedSelectedView !== undefined ? rememberedSelectedView : (isDesktop ? defaultNewOrderViewDesktop : defaultNewOrderViewMobile));
		setSelectedView(sv as ViewId);
		setSelectedProduct(history.location.state?.selectedProduct ? history.location.state.selectedProduct : null);
		setSelectedPath(history.location.state?.selectedPath ? history.location.state.selectedPath : BASIC_GROUP);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location.state, defaultNewOrderViewDesktop, defaultNewOrderViewMobile, rememberedSelectedView]);

	useEffect(() => {
		// Fetch groups
		if (!groups && !isFetchingGroups) {
			getGroups().then(result => setError(!result));
		}
		// eslint-disable-next-line
	}, [company, mag, groups]);

	useEffect(() => {
		const cartContentMapped: CartContent = mapLocalCartToCartContents(localCart);
		if (edit)
			store.dispatch(dataActions.setEditCart(cartContentMapped));
		else
			store.dispatch(dataActions.setCart(cartContentMapped));
	}, [localCart, edit]);

	const setProduct = (product: Product | null) => {
		setSelectedProduct(product);
		if (product) {
			// if new product has been selected push it to history state
			if (!_.isEqual(product, history.location.state?.selectedProduct)) {
				history.push({
					pathname: `${process.env.PUBLIC_URL}/new-order`,
					state: {
						...history.location.state,
						selectedPath: product.path.join('\\'),
						selectedProduct: product
					},
				});
			}
		}
	};

	const getProductsBySearch = async ({ path, key }: TreeNode, company: string | undefined) => {
		try {
			const status = await getProductsByGroup(path, company);
			if (!status) {
				toggleTreeNode(key);
			}
		} catch (error) {
			alert("Błąd podczas pobierania produktu");
			toggleTreeNode(key);
		}
	}

	useEffect(() => {
		if (!advancedTree) return;

		const deepCopyTree = mapTree((node: TreeNode) => {
			const isExpanded = _.isEqual(node.path, firstNGroups(selectedPath, node.level));
			// If node is expandable and contains products fetch those products
			if (isExpanded && isLeaf(node) && node.hasProducts && node.symKar === undefined && !isFetchingGroups) {
				getProductsBySearch(node, company);
			}
			// Return node with tag if node is expanded
			return ({
				...node,
				expanded: isExpanded,
			})
		}, makeRoot(advancedTree)).childrenNodes;

		const find = findInTree;
		// Search product in tree logic
		if (find) {
			const filter: (node: TreeNode) => boolean = (node: TreeNode) => {
				return _.includes(node.description.toLowerCase(), find.toLowerCase());
			}
			const filterTree = (treeNodes: TreeNode[]): TreeNode[] => {
				const tree: TreeNode[] = [] as TreeNode[];
				for (let node of treeNodes) {
					if (filter(node)) {
						node.expanded = false;
						tree.push(node);
					} else {
						const result = filterTree(node.childrenNodes);
						if (result.length > 0) {
							node.childrenNodes = result;
							node.expanded = true;
							tree.push(node);
						}
					}
				}
				return tree;
			}
			const filteredTree = filterTree(deepCopyTree);
			setFilteredTree(filteredTree);
			return;
		}

		setFilteredTree(deepCopyTree);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedFindInTree, advancedTree]);

	useEffect(() => {
		if (selectedView !== 'advanced') return;

		// Expand all nodes for selected product or selected path after changed product or selectedView
		const path = selectedProduct ? arrayPathToString(selectedProduct.path) : selectedPath;

		setFilteredTree(state => !state ? state :
			mapTree((node) => {
				return firstNGroups(path, node.level) === node.path ? { ...node, expanded: true } : node
			},
				makeRoot(state)
			).childrenNodes
		);

		// eslint-disable-next-line
	}, [selectedView, selectedProduct])


	const tree = useMemo(() => {
		if (!filteredTree) return null;

		const getNodeChildrenProducts = (parentNode: TreeNode) => {
			if (parentNode.symKar) {
				return [];
			}
			const path = parentNode.path.split('\\');
			const productsFromGroup = _.filter(products, (product) => product.path.toString() === path.toString());

			return _.map(productsFromGroup, (product, index) => {
				return {
					...product,
					key: `${product.description}_${path}_${index}`,
					childrenNodes: [],
				};
			});
		};

		const assignProducts = (treeNodes: TreeNode[]) => {
			for (let node of treeNodes) {
				if (isLeaf(node)) {
					node.childrenNodes = getNodeChildrenProducts(node);
				} else if (node.childrenNodes.length > 0 && node.hasProducts) {
					assignProducts(node.childrenNodes);
					node.childrenNodes.push(...getNodeChildrenProducts(node));
				} else {
					assignProducts(node.childrenNodes)
				}
			}
		}

		return produce(filteredTree, (draft) => assignProducts(draft));
	}, [filteredTree, products]);

	const toggleTreeNode = (nodeKey: string) => {
		setFilteredTree(state => !state ? state :
			mapTree((node) => {
				return node.key === nodeKey ? { ...node, expanded: !node.expanded } : node
			},
				makeRoot(state)
			).childrenNodes
		);
		setSelectedPath(nodeKey);
	}

	const onUpdate = (productSymKar: string, path: string[]) => {
		const product = _.find(products, (product) => product.symKar === productSymKar);

		setProduct(product ?? null);
		const group = _.find(groups, (group) => group.path === arrayPathToString(path));
		if (group) {
			setSelectedPath(group.path)
			setCurrentLevel(group.groups.length - 1);
		}

		if (!isDesktop) {
			window.scrollTo(0, 600);
		}
	};


	useEffect(() => {
		if (!selectedProduct?.additionalAssortment.length) setAdditionalAssortmentFetching(false);
		if (selectedProduct && selectedProduct?.additionalAssortment.length > 0) {
			setAdditionalAssortmentFetching(true);
			getAdditionalAssortmentInfo(selectedProduct?.additionalAssortment).then(() => {
				setAdditionalAssortmentFetching(false);
			});
		}
	}, [selectedProduct]);

	const focusFirstInput = () => {
		if (!inputRef) return;
		inputRef.current?.focus();
	}

	return (
		<div>
			<Cart show={showCart} close={() => setShowCart(false)} />
			<CartReset show={showCartReset} close={() => { setShowCartReset(false); props.setCartReset(false); }} />
			<Card>
				<AssortmentSelectionHeader {...{ currentLevel, selectedView, setShowCart, setSelectedView, selectedPath, selectedProduct }} isSpecialGroup={isSpecialGroup(selectedPath)} />
				<Card.Body
					className={selectedProduct || selectedView === 'advanced' ? `AssortmentSelection-${selectedView}View` : ''}
					style={!isDesktop || ['treatment', 'gutters', 'plate'].includes(selectedView) ? { padding: '10px' } : { minHeight: '660px' }}
				>
					{
						selectedView === 'basic' && <BasicView {...{
							currentLevel, selectedView, selectedPath, selectedProduct, error,
							setSelectedProduct, setError, setCurrentLevel, setSelectedPath, setProduct, toggleTreeNode
						}} />
					}
					{
						selectedView === 'advanced' && <AdvancedView {...{
							error, tree, findInTree, selectedProduct, selectedPath,
							setSelectedProduct, setFindInTree, toggleTreeNode, onUpdate, focusFirstInput
						}} />
					}
					{
						['basic', 'advanced'].includes(selectedView) && selectedProduct && (
							<>
								<ProductSelection selectedProduct={selectedProduct} inputRef={inputRef} />
								<SimilarProducts selectedProduct={selectedProduct} />
								<AdditionalAssortment selectedProduct={selectedProduct} additionalAssortmentFetching={additionalAssortmentFetching} />
							</>
						)
					}
					{
						selectedView === 'treatment' && (
							<div>
								<img src={process.env.PUBLIC_URL + '/obrobki.png'} alt="" width={1020} />
							</div>
						)
					}
					{(selectedView === 'gutters' && gutterScripts.length !== 0) ? <GutterCreator></GutterCreator> : ''}
					{selectedView === 'plate' && <PlatesCreator></PlatesCreator>}
				</Card.Body>
			</Card>
			<div className={isDesktop ? "NewOrder-footer NewOrder-footerBegin" : "NewOrder-footer NewOrder-footerBegin d-flex justify-content-center"} >
				<ButtonComponent
					text='PRZEJDŹ DO PODSUMOWANIA'
					onClick={props.goToSummary}
				/>
			</div>
			{user?.isAdmin && (selectedView === 'basic' || selectedView === 'advanced') && (
				<div className="AssortmentSelection-picture-info">
					Aby zastąpić zdjęcie produktu lub kategorii, umieść plik <i>[Nazwa pliku]</i> z rozszerzeniem .jpg lub .png w folderze /storage/app/public/groups.
				</div>
			)}
		</div>
	);
}
