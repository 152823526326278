import Spinner from "components/Spinner";
import { getStatistics } from "lib/communication/admin";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { addMonths } from 'date-fns';
import { AppState } from "reducers";
import './Statistics.scss';
import BarChart from "components/BarChart/BarChart";
import { useMediaQuery } from "react-responsive";
import { LG, getFormatedDate, getWeekDay, isSafari } from "lib/util";
import { Col, Form } from "react-bootstrap";
import ButtonComponent from "components/ButtonComponent";

const Statistics = () => {
	const stats = useSelector((state: AppState) => state.data.adminInfo.statistics);
	const loading = useSelector((state: AppState) => state.data.adminInfo.props.isFetching);

	const isDesktop = useMediaQuery({ minWidth: LG });

	const [from, setFrom] = useState(getFormatedDate(addMonths(new Date(), -1)));
	const [to, setTo] = useState(getFormatedDate(new Date()));
	const [daysExpanded, setDaysExpanded] = useState<{[key: string]: number}>({});

	useEffect(() => {
		getStatistics(from, to);
	}, [from, to]);

	useEffect(() => {
		const newDaysExpanded: {[key: string]: number} = {};
		const days = Object.keys(stats);

		_.forEach(stats, (day, key) => {
			newDaysExpanded[key] = key === days[0] ? 1 : 0
		});

		setDaysExpanded(newDaysExpanded);
	}, [stats]);

	const hoursChartData = useMemo(() => {
		const data: {[key: string]: {label: string; value: number}[]} = {};

		_.map(stats, (stat, day) => {
			const dayData: {label: string; value: number}[] = [];

			_.map(stat.hour_requests, (hourRequests, index) => {
				const hour = (index < 10 ? '0' : '') + index + ':00';
				
				dayData.push({
					label: hour,
					value: hourRequests
				});
			});

			data[day] = dayData;
		});

		return data;
	}, [stats]);

	const endpointsChartData = useMemo(() => {
		const data: {[key: string]: {label: string; value: number}[]} = {};

		_.map(stats, (stat, day) => {
			const dayData: {label: string; value: number}[] = [];

			_.map(stat.endpoints_requests, (endpointRequests, key) => {
				dayData.push({
					label: key,
					value: endpointRequests
				});
			});

			data[day] = _.sortBy(dayData, el => -el.value);
		});

		return data;
	}, [stats]);

	const hoursAverageData = useMemo(() => {
		const sums: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

		_.map(stats, (stat) => {
			_.map(stat.hour_requests, (hourRequests, index) => {
				sums[index] += hourRequests;
			});
		});

		return Object.keys(stats).length > 0 ? _.map(sums, (sum, index) => {
			const avg = +(sum / Object.keys(stats).length).toFixed(2);
			const hour = (index < 10 ? '0' : '') + index + ':00';

			return  {
				label: hour,
				value: avg
			}
		}) : [];
	}, [stats]);

	const endpointsAverageData = useMemo(() => {
		const sums: {[key: string]: number} = {};

		_.map(stats, (stat) => {
			_.map(stat.endpoints_requests, (endpointRequests, key) => {
				if (!(key in sums)) sums[key] = 0;
				sums[key] += endpointRequests; 
			});
		})

		return Object.keys(stats).length > 0 ? _.sortBy(_.map(sums, (sum, key) => {
			const avg = +(sum / Object.keys(stats).length).toFixed(2);

			return  {
				label: key,
				value: avg
			}
		}), el => -el.value) : [];
	}, [stats]);

	return <>
		<h2>Statystyki</h2>
		<Form.Row>
			<Form.Group className="flex-end" as={Col}>
				<Form.Label>DATA OD</Form.Label>
				<Form.Control type="date" id="from" placeholder="YYYY-MM-DD" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
					value={from}
					onChange={(e) => setFrom(e.target.value)} />
				{isSafari() && <Form.Control.Feedback type="invalid" tooltip>
					Użyj formatu YYYY-MM-DD
				</Form.Control.Feedback>}
			</Form.Group>
			<Form.Group className="flex-end" as={Col}>
				<Form.Label>DATA DO</Form.Label>
				<Form.Control type="date" id="to" placeholder="YYYY-MM-DD" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
					value={to}
					onChange={(e) => setTo(e.target.value)} />
				{isSafari() && <Form.Control.Feedback type="invalid" tooltip>
					Użyj formatu YYYY-MM-DD
				</Form.Control.Feedback>}
			</Form.Group>
		</Form.Row>
		{
			loading ? <Spinner /> : <> 
				<h3>Średnia</h3>
				<div className="statistics-avg" style={{ flexDirection: isDesktop ? 'row' : 'column' }}>
					<div style={{ flex: 1 }}>
						<h4>Requesty na godzinę</h4>
						<BarChart id="StatisticsChart-avg" datas={hoursAverageData} orientation="vertical"/>
					</div>
					<div style={{ flex: 1 }}>
						<h4>Requesty na endpointy</h4>
						{
							_.map(endpointsAverageData, el => <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
								<div>{el.label}</div> <div>{el.value}</div>
							</div>)
						}
					</div>
				</div>
				<br />
				<br />
				<h3>Poszczególne dni</h3>
				{
					_.map(stats, (stat, day) => <div key={`stats-${day}`} className="statistics-day">
						{day} ({getWeekDay(new Date(day))})
						<br />
						liczba użytkowników: {stat.users.length}
						<br />
						całkowita liczba zapytań: {stat.total_requests}
						<div className="statistics-day-header-buttons">
							<ButtonComponent text="Godziny" variant={daysExpanded[day] === 1 ? 'primary' : 'secondary'} onClick={() => { 
								const newDaysExpanded = {...daysExpanded};
								newDaysExpanded[day] = newDaysExpanded[day] === 1 ? 0 : 1;
								setDaysExpanded(newDaysExpanded);
								}}/>
							<ButtonComponent text="Endpointy" variant={daysExpanded[day] === 2 ? 'primary' : 'secondary'} onClick={() => { 
								const newDaysExpanded = {...daysExpanded};
								newDaysExpanded[day] = newDaysExpanded[day] === 2 ? 0 : 2;
								setDaysExpanded(newDaysExpanded);
								}}/>
						</div>
						<div style={{ width: '100%', height: isDesktop ? '300px' : 'auto', display: daysExpanded[day] === 1 ? 'block' : 'none' }}>
							<BarChart id={`StatisticsChart-${day}`} datas={hoursChartData[day]} orientation={isDesktop ? 'horizontal' : 'vertical'}/>
						</div>
						<div style={{ display: daysExpanded[day] === 2 ? 'inline-block' : 'none' }}>
							{
								_.map(endpointsChartData[day], el => <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
									<div>{el.label}</div> <div>{el.value}</div>
								</div>)
							}
						</div>
					</div>)
				}
			</>
		}
	</>
}

export default Statistics;