import React from "react";
import './SicilianFiesta.scss';

export default function SicilianFiesta() {

    return (
        <>
            <div className="container">
                <div className="image-container">
                    <img className="image" src={`${process.env.PUBLIC_URL}/images/Sycylia_1060x323_banner_zamowienia.png`} alt="Sycylijska fiesta" />
                </div>
                <br/>
                <strong className="title">Regulamin konkursu wsparcia sprzedaży</strong>
                <strong className="title">"SYCYLIJSKA FIESTA 2024"</strong>
                <p className="paragraph">§1 ZASADY OGÓLNE</p>
                <p className="text">1.1. Organizatorem sprzedaży premiowej (dalej zwanej: „KONKURSEM”) pod nazwą <strong>„SYCYLIJSKA FIESTA 2024”,</strong> jest PRUSZYŃSKI Spółka z ograniczoną odpowiedzialnością z siedzibą w Sokołowie, ul. Sokołowska 32B, 05-806 Komorów, NIP 5342139235, Regon 016480890, zarejestrowaną w Krajowym Rejestrze Sądowym prowadzonym przez Sąd Rejonowy dla m.st. Warszawy w Warszawie XIV Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000054020 zwana dalej „ORGANIZATOREM”. </p>
                <p className="text">1.2. PARTNEREM KONKURSU są firmy powiązane z Organizatorem sprzedające produkty Organizatora (<strong>Oddział Łódź</strong> – PRUSZYŃSKI-KALUŻNY Sp. z o. o. – 95-030 Rzgów, ul. Rudzka 43 NIP: 728-23-80-660; <strong>Oddział Szczecin</strong> – PRUSZYŃSKI-NOWICKI Sp. z o. o. Motaniec 2k, 73-108 Kobylanka) PARTNER partycypuje w kosztach organizacji konkursu na podstawie umów z Organizatorem.</p>
                <p className="text">1.3. Konkurs dotyczy zakupu u Organizatora i Partnerów Konkursu wyrobów marki Blachy Pruszyński, zwanych dalej „PRODUKTAMI PROMOWANYMI”. Za Produkty Promowane uznaje się wszystkie wyroby z oferty handlowej Organizatora, z wyłączeniem wyrobów inwestycyjnych – trapezów od T60 do T200, płyt warstwowych Pirtech i WOOLTECH, profili Z i C oraz Sigma, kaset ściennych oraz kasetonów.</p>
                <p className="text">1.4. Konkurs rozpoczyna się w dniem 17.05.2024 od ogłoszenia w newsletterze rozsyłanym do firm współpracujących (zwanych  dalej UCZESTNIKAMI) i trwa do dnia 30.09.2024 r. Organizator zastrzega sobie możliwość wydłużenia lub skrócenia czasu trwania Konkursu.</p>
                <p className="text">1.5. Regulamin obowiązuje Organizatora od chwili ogłoszenia zgodnie z pkt. 4.</p>
                <p className="text">1.6. Konkurs będzie prowadzony na terenie Rzeczypospolitej Polskiej</p>
                <p className="text">1.7. Celem Konkursu jest promowanie i zwiększenie sprzedaży wyrobów z oferty Organizatora.</p>
                <p className="text">1.8. Nagrodą w Konkursie jest tytuł LAUREATA KONKURSU, który upoważnia do otrzymania vouchera uczestnictwa w wycieczce na Sycylię  w dniach 17-23.10.2024 r.</p>
                <p className="text">1.9. Uczestnikami Konkursu mogą zostać jedynie WŁAŚCICIELE lub członkowie ścisłego zarządu firm współpracujących z Organizatorem, przez które to firmy rozumie się:</p>
                <p className="text list-element">1.9.1. podmioty których przedmiotem działalności jest sprzedaż pokryć dachowych oraz akcesoriów do pokryć dachowych</p>
                <p className="text list-element">1.9.2. podmioty dokonujące zakupów na polski NIP – chodzi tu o NIP odbiorcy</p>
                <p className="text">1.10. Udziału w Konkursie nie mogą brać pracownicy Organizatora oraz członkowie ich rodzin.</p>
                <p className="text">1.11. Udziału w Konkursie nie mogą brać podmioty z GRUPY PRUSZYŃSKI, które współpracują handlowo, których lista stanowi załącznik nr 1 do niniejszego Regulaminu.</p>
                <p className="text">1.12. Liczba uczestników biorących udział w Konkursie jest nieograniczona.</p>
                <p className="text">1.13. Nadzór nad Konkursem sprawuje Komisja Konkursowa (Komisja) złożona z pracowników Organizatora: dyrektora ds. marketingu, dyrektora ds. sieci, dyrektora handlowego oraz dwóch pracowników firmy Pruszyński Sp. z o.o. Komisja jest uprawniona do wyłonienia zwycięzców Konkursu oraz rozstrzygania kwestii spornych oraz reklamacji. Kwestie sporne oraz reklamacje rozstrzygane są w drodze głosowania. </p>
                <br/>
                <p className="paragraph">§2 WARUNKI KONKURSU - <span className="underlined">RANKING ROKU 2024</span></p>
                <p className="text-with-bold">2.1. <strong className="underlined">ETAP I KONKURSU</strong> Aby otrzymać tytuł Laureata Konkursu i otrzymać JEDEN VOUCHER Uczestnik musi spełnić poniższe warunki:</p>
                <p className="text list-element">2.1.1. Wysłać zgłoszenie udziału w konkursie na adres <a href="mailto:konkurs@pruszynski.com.pl">konkurs@pruszynski.com.pl</a> w nieprzekraczalnym terminie do 31.07.2024 r.  </p>
                <p className="text list-element">2.1.2. Nie posiadać żadnych zaległości w płatnościach wobec Organizatora na dzień 30.09.2024 do g. 16.00</p>
                <p className="text list-element">2.1.3. W okresie od 1 stycznia do 31 maja 2024 r. wartość wyfakturowanych na niego zakupów Produktów Promowanych osiągnęła albo przekroczyła 500 000 zł netto (słownie: pięćset tysięcy złotych netto).</p>
                <p className="text list-element">2.1.4. W okresie od 1.06.2024 do 30.09.2024 wartość wyfakturowanych na niego zakupów Produktów Promowanych musi przekroczyć poziom 100% (sto procent) zakupów dokonanych w okresie od 1.01.2024 do 31.05.2024r.  Wartość wystawionych faktur utworzy ranking Laureatów Konkursu. Z uwagi na ograniczoną liczbę VOUCHERÓW przy ich  przyznawaniu liczyć się będzie pozycja w rankingu (zwanego dalej RANKINGIEM).</p>
                <p className="text list-element">2.1.5. Wartość wyfakturowanych na niego zakupów WIATROWNICY PANELOWEJ musi wynosić nie mniej niż 1%  (jeden procent) zakupów blachodachówek dokonanych w okresie od 1.06.2024 do 30.09.2024 r.</p>
                <p className="text-with-bold">2.2. <strong className="underlined">ETAP II KONKURSU</strong> Po spełnieniu warunków ETAPU I KONKURSU Laureat Konkursu, aby ubiegać się o otrzymanie DRUGIEGO VOUCHERA (dla 1 osoby na wyjazd i zakwaterowanie w pokoju dwuosobowym razem z Laureatem Konkursu) musi dokonać zakupów Produktów Promowanych w okresie od 1.06.2024 do 30.09.2024 na poziomie 120% (sto dwadzieścia procent) zakupów dokonanych w §2 pkt. 1.3. Możliwość uzyskania drugiego vouchera uzależniona jest od dostępności miejsc. Pierwszeństwo otrzymania vouchera mają Laureaci ETAPU I  oraz  kategorii LIDER ROKU (§3 pkt. 1).</p>
                <p className="paragraph">§3 WARUNKI KONKURSU - <span className="underlined">LIDER ROKU</span></p>
                <p className="text">3.1. LIDER ROKU to trzy firmy, które osiągną najwyższy poziom sprzedaży Produktów Promowanych.</p>
                <p className="text">3.2. LIDER ROKU otrzyma  nagrodę w postaci DWÓCH VOUCHERÓW.</p>
                <p className="text">3.3. LIDER ROKU, aby otrzymać nagrodę musi spełnić poniższe warunki:</p>
                <p className="text list-element">3.3.1. wysłać zgłoszenie udziału w konkursie na adres <a href="mailto:konkurs@pruszynski.com.pl">konkurs@pruszynski.com.pl</a> w nieprzekraczalnym terminie do 31.07.2024 r.</p>
                <p className="text list-element">3.3.2. nie posiadać żadnych zaległości w płatnościach wobec Organizatora na dzień 30.09.2024 do g. 16.00</p>
                <p className="text list-element">3.3.3. osiągną najwyższy poziom sprzedaży Produktów Promowanych w okresie od 1.01.2024 do 30.09.2024 r. Liczy się pozycja osiągnięta w RANKINGU.</p>
                <p className="text">3.4. Nagrody nie łączą się. LIDER ROKU otrzymuje wyłącznie nagrodę w swojej kategorii.</p>
                <p className="paragraph">§4 WARUNKI KONKURSU - <span className="underlined">REALIZACJA ROKU 2024</span></p>
                <p className="text">4.1. Komisja Konkursowa ma prawo przyznać nagrody specjalne w postaci Voucherów za ciekawą realizację zawierającą kompletne pokrycie dachu wykonane z Produktów Promowanych. Uczestnik, aby przystąpić do tej konkurencji musi:</p>
                <p className="text list-element">4.1.1. Wysłać zgłoszenie udziału w konkursie na adres <a href="mailto:konkurs@pruszynski.com.pl">konkurs@pruszynski.com.pl</a> w nieprzekraczalnym terminie do 31.07.2024 r.</p>
                <p className="text list-element">4.1.2. Nie posiadać żadnych zaległości w płatnościach wobec Organizatora na dzień 30.09.2024 do g. 16.00</p>
                <p className="text list-element">4.1.3. W okresie od 1 stycznia do 31 maja 2024 r. wartość wyfakturowanych na niego zakupów Produktów Promowanych (określonych w §1 pkt 3 niniejszego Regulaminu) osiągnęła albo przekroczyła 500 000 zł netto (słownie: pięćset tysięcy złotych netto).</p>
                <p className="text list-element">4.1.4. Wysłać dwa zdjęcia oraz opis obiektu na adres <a href="mailto:konkurs@pruszynski.com.pl">konkurs@pruszynski.com.pl</a> w  nieprzekraczalnym terminie : 10.09.2024 r.</p>
                <p className="text list-element">4.1.5. Zgłoszony obiekt musi mieć zamontowany jeden z poniżej określonych zestawów wyrobów: </p>
                <p className="text second-list-element">4.1.5.a. Pierwszy zestaw wyrobów: blachodachówka, rynny NIAGARA, obróbki, w tym wiatrownica panelowa  </p>
                <p className="text second-list-element">4.1.5.b. Drugi komplet wyrobów:  panel na rąbek, rynny NIGARA, obróbki, okno lub wyłaz AURA</p>
                <p className="text">4.2. Pula nagród w tej kategorii wynosi maksymalnie sześć Voucherów.</p>
                <p className="text">4.3. Jeden Laureat tej kategorii może otrzymać maksymalnie dwa vouchery.</p>
                <p className="text">4.4. Organizator ma prawo nie przyznać nagrody w tej kategorii.</p>
                <p className="text">4.5. Nagrody nie łączą się. LAUREAT NAGRODY REALIZACJA ROKU 2024 otrzymuje wyłącznie nagrodę w swojej kategorii.</p>
                <p className="paragraph">§5 NAGRODY</p>
                <p className="text">5.1. Nagrodą w konkursie jest voucher na wyjazd na Sycylię w dniach 17 – 23.10.2024 dla jednej, pełnoletniej osoby – WŁAŚCICIELA FIRMY lub członka ścisłego zarządu firmy.</p>
                <p className="text">5.2. Voucher – gwarantuje przelot, zakwaterowanie w pokoju dwuosobowym z wyżywieniem oraz udział w zaplanowanych wycieczkach i programie animacyjnym.</p>
                <p className="text">5.3. Liczba nagród jest ograniczona i  wynosi 100 voucherów.</p>
                <p className="text">5.4. Istnieje możliwość dokupienia drugiego vouchera, jednak jest ona uzależniona od dostępności voucherów. </p>
                <p className="text">5.5. Pierwszeństwo zakupu mają Laureaci Konkursu ETAPU PIERWSZEGO, którzy osiągnęli najwyższą pozycję w RANKINGU. </p>
                <p className="text">5.6. Wycieczki fakultatywne proponowane Uczestnikom będą płatne przez Uczestników samodzielnie, o planowanych wycieczkach i ich cenie Laureaci będą informowani przed wyjazdem.</p>
                <p className="text">5.7. Wymiana otrzymanej nagrody na jakiekolwiek inne świadczenie jest niemożliwa.</p>
                <p className="text">5.8. Organizator nie ponosi odpowiedzialności za konsekwencje wynikające z braków w dokumentacji Uczestników bądź nieprzekazania potrzebnych danych dla potrzeb realizacji vouchera.</p>
                <p className="text">5.9. Laureaci Konkursu zobowiązani są dostarczyć wszystkie niezbędne do udziału w wyjeździe informacje za pośrednictwem platformy internetowej przygotowanej do obsługi wyjazdu przez podwykonawcę realizującego wyjazd na zlecenie Organizatora. </p>
                <p className="text">5.10. Organizator nie jest odpowiedzialny za okoliczności uniemożlwiające faktyczne skorzystanie z wyjazdu na Sycylię, które są od niego niezależne, w szczególności - odwołanie lotu lub odstąpienie przez biuro podróży od organizacji imprezy turystycznej, błędne lub niekompletne dane podane przez Laureata. W przypadku uzyskania przez zwycięzców prawa do nabycia wycieczki na Sycylię, Organizator odpowiada wyłącznie za zawarcie umowy o imprezę turystyczną na rzecz Uczestnika.</p>
                <p className="paragraph">§6 OGŁOSZENIE WYNIKÓW KONKURSU</p>
                <p className="text">6.1. Naliczanie kwot zakupionych Produktów Promowanych (określonych w §1 pkt 3 niniejszego Regulaminu)  następuje w momencie wystawienia Uczestnikowi Konkursu faktury sprzedaży przez Organizatora.</p>
                <p className="text">6.2. Wstępne wyniki Konkursu zostaną przesłane do Uczestników w terminie: 2 października 2024 do g. 12.00, jednakże z uwagi na konieczność spełnienia warunku dotyczącego braku zaległości w płatnościach, określonego w §2 pkt. 2.2. niniejszego Regulaminu ostateczna Lista Laureatów zostanie wygenerowana 3 października 2024 r. do g. 16.00.</p>
                <p className="text">6.3. Laureaci Konkursu o otrzymaniu nagrody zostaną poinformowani mailowo w dniu wygenerowania Listy Laureatów </p>
                <p className="text">6.4. Laureaci Konkursu informacje o przyznaniu dodatkowego miejsca otrzymają nie później niż 4 października 2024 r. </p>
                <p className="text">6.5. Podczas trwania Konkursu Uczestnicy mogą otrzymać informacje dotyczące Konkursu:</p>
                <p className="text list-element">- swojego opiekuna handlowego,</p>
                <p className="text list-element">- dyrektora  handlowego		tel. 604 304 898,</p>
                <p className="text list-element">- dyrektora ds. sieci 	    tel. 608 080 336.</p>
                <p className="paragraph">§7 ROZLICZENIE PODATKOWE</p>
                <p className="text bold">7.1. Laureaci Konkursu zobowiązani są samodzielnie rozliczyć i opodatkować dochód powstały z tytułu otrzymania nagrody, zgodnie z właściwymi dla nich zasadami opodatkowania.</p>
                <p className="text">7.2. Wzór protokołu odbioru nagrody stanowi załącznik Nr. 2 do niniejszego Regulaminu. </p>
                <p className="paragraph">§8 ZASADY POSTĘPOWANIA REKLAMACYJNEGO</p>
                <p className="text-with-bold">8.1. Wszelkie reklamacje z tytułu organizowanego Konkursu, mogą być składane na piśmie na adres Organizatora do Dyrektora działu marketingu lub na adres <a href="mailto:konkurs@pruszynski.com.pl">konkurs@pruszynski.com.pl</a> z dopiskiem <strong>REKLAMACJA KONKURS „SYCYLIJSKA FIESTA 2024”</strong> przez cały czas trwania Konkursu, ale nie później niż w terminie do 3 (trzech) dni, licząc od daty wygenerowania Listy Laureatów.</p>
                <p className="text">8.2. Za datę wniesienia reklamacji uważa się datę stempla pocztowego bądź datę poprawnie przesłanej informacji email.</p>
                <p className="text">8.3. Reklamacja powinna być sporządzona na piśmie i powinna zawierać następujące informacje:</p>
                <p className="text list-element">a) Nazwę podmiotu, imię i nazwisko osoby do kontaktu, NIP oraz adres Uczestnika zgłaszającego reklamację;</p>
                <p className="text list-element">b) przedmiot reklamacji z uzasadnieniem przyczyn jej wniesienia;</p>
                <p className="text list-element">c) datę i podpis uczestnika.</p>
                <p className="text">8.4. Reklamacje będą rozpatrywane w terminie 3 dni od daty doręczenia.</p>
                <p className="text">8.5. Osoba składająca reklamację zostanie pisemnie poinformowana o decyzji listem poleconym, wysłanym na adres podany w reklamacji w terminie 5 dni od daty rozpatrzenia reklamacji. Organizator może ponadto powiadomić za pośrednictwem poczty elektronicznej.</p>
                <p className="paragraph">§9 PRZETWARZANIE DANYCH OSOBOWYCH</p>
                <p className="text">9.1. Przesłanie zgłoszenia do Konkursu jest równoznaczne z akceptacją warunków niniejszego Regulaminu oraz zgodą na przetwarzanie danych osobowych do celów związanych z przeprowadzeniem Konkursu, w szczególności w celu:</p>
                <ul>
                    <li className="custom-li">weryfikacji zgłoszeń konkursowych,</li>
                    <li className="custom-li">komunikacji z Uczestnikami Konkursu,</li>
                    <li className="custom-li">ogłoszenia wyników Konkursu,</li>
                    <li className="custom-li">przekazania nagród,</li>
                    <li className="custom-li">ewentualnego rozpatrzenia reklamacji związanych z Konkursem.</li>
                </ul>
                <p className="text">9.2. Administratorem danych jest PRUSZYŃSKI Spółka z ograniczoną odpowiedzialnością z siedzibą w Sokołowie ul. Sokołowska 32B 05-806 Komorów NIP 5342139235 Regon 016480890 zarejestrowaną w Krajowym Rejestrze Sądowym prowadzonym przez Sąd Rejonowy dla m.st. Warszawy w Warszawie XIV Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000054020.</p>
                <p className="text">9.3. Dane osobowe Uczestnika przetwarzane są wyłącznie dla celów związanych z przeprowadzeniem Konkursu.</p>
                <p className="text">9.4. Podanie danych osobowych nie jest obowiązkowe jednakże ich niepodanie spowoduje, że przystąpienie do Konkursu będzie niemożliwe.</p>
                <p className="text">9.5. Dane osobowe Uczestników są przekazywane wyłącznie podmiotom zaangażowanym w przeprowadzenie i rozliczenie Konkursu. Takie podmioty przetwarzają dane na podstawie umowy z Organizatorem i tylko zgodnie z poleceniami Organizatora.</p>
                <p className="text">9.6. Dane Uczestników będą przechowywane nie dłużej niż jest to konieczne, tj. przez okres 6 miesięcy od dnia rozstrzygnięcia Konkursu.</p>
                <p className="text">9.7. Uczestnikowi przysługuje prawo dostępu do treści danych oraz ich sprostowania, usunięcia lub ograniczenia przetwarzania, a także prawo sprzeciwu, zażądania zaprzestania przetwarzania i przenoszenia danych, jak również prawo do cofnięcia zgody w dowolnym momencie oraz prawo do wniesienia skargi do organu nadzorczego.</p>
                <p className="text">9.8. Administrator stosuje odpowiednie środki techniczne i organizacyjne, aby zapewnić ochronę danych osobowych przed przypadkowym lub niezgodnym z prawem zniszczeniem, utratą, modyfikacją, nieuprawnionym ujawnieniem lub dostępem do danych osobowych.</p>
                <p className="paragraph">§10 POSTANOWIENIA KOŃCOWE</p>
                <p className="text">10.1. Konkurs będzie prowadzony przez Organizatora na zasadach niniejszego Regulaminu. We wszystkich sprawach nieuregulowanych niniejszym Regulaminem mają zastosowanie przepisy prawa polskiego, a w szczególności przepisy Ustawy z dnia 23 kwietnia 1964 roku Kodeks cywilny.</p>
                <p className="text">10.2. Regulamin jest dostępny na stronie <a href="https://www.pruszynski.com.pl" target="_blank" rel="noopener noreferrer"> www.pruszynski.com.pl </a>  oraz w siedzibie firmy Pruszyński Sp. z o.o. </p>
                <p className="text">10.3. Organizator zastrzega sobie prawo do dokonania w dowolnym momencie zmian w Regulaminie – zwłaszcza w przypadku zamknięcia granic i braku możliwości zorganizowania wyjazdu w zakładanym terminie z powodu pandemii lub zaistnienia siły wyższej. W powyższym przypadku strony dopuszczają następujące rozwiązania: zmianę terminu i destynacji wyjazdu, wstrzymania Konkursu, jego przedłużenia lub zawieszenia na czas określony. O wszelkich zmianach Laureaci Konkursu będą informowani w odrębnej informacji mailowej.</p>
                <p className="text">10.4. Uprawnienia wynikające z uczestnictwa w Konkursie nie mogą być przeniesione przez Uczestnika na osobę trzecią</p>
                <p className="text">10.5. Zasady przeprowadzania Konkursu określa wyłącznie Regulamin.</p>
                <p className="paragraph">Załączniki</p>
                <p className="text"><a href={`${process.env.PUBLIC_URL}/Załącznik_nr_1.pdf`} download> Załącznik nr 1</a></p>
                <p className="text"><a href={`${process.env.PUBLIC_URL}/Załącznik_nr_2.pdf`} download> Załącznik nr 2</a></p>
            </div>
        </>
    )
}