import { combineReducers } from '@reduxjs/toolkit';
import dataReducer from './data';
import requestsReducer from './requests';
import sessionReducer from './session';
import locationReducer from './location';
import confReducer from './conf';
import volatileReducer from './volatile';

const appReduer = combineReducers({
	data: dataReducer,
	requests: requestsReducer,
	session: sessionReducer,
	location: locationReducer,
	conf: confReducer,
	volatile: volatileReducer,
});

export default appReduer;

export type AppState = ReturnType<typeof appReduer>;
