import React from 'react';
import { Modal } from 'react-bootstrap';
import { dataActions } from 'reducers';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import store from 'store';

export default function Notification() {

	const previousSummaryPrice = useSelector((state: AppState) => state.data.orders.previousSummaryPrice);
	const cartProdNotAvailable = useSelector((state: AppState) => state.data.orders.cartProdNotAvailable);
	const edit = useSelector((state: AppState) => state.data.orders.edit);
	const cart = useSelector((state: AppState) => edit ? state.data.orders.editCart : state.data.orders.cart);

	return (
		<>
			<Modal show={ previousSummaryPrice !== null && previousSummaryPrice !== cart.summaryPrice } 
				onHide={()=>{store.dispatch(dataActions.setPreviousSummaryPrice(null))}} centered>
						<Modal.Header closeButton>
							<Modal.Title>Uwaga</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div style={{textAlign: "center"}}>Stan koszyka został zaktualizowany.</div>
						</Modal.Body>
			</Modal>
            <Modal show={ cartProdNotAvailable } 
				onHide={()=>{store.dispatch(dataActions.setCartProdNotAvailable(false))}} centered>
						<Modal.Header closeButton>
							<Modal.Title>Uwaga</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div style={{textAlign: "center"}}>Zawartość koszyka została zaktualizowana, niedostępne produkty zostały usunięte.</div>
						</Modal.Body>
			</Modal>
		</>
	)
}