
import React, { ChangeEvent, useState } from 'react';
import { Table, Form, Col, Collapse, Alert, ListGroup, Spinner } from 'react-bootstrap';
import './PromotionProducts.scss';
import ImageThumbnail from 'components/ImageThumbnail';
import { useMediaQuery } from 'react-responsive';
import { LG, USER_ROLE_TRADER, USER_ROLE_ROOFER } from 'lib/util';
import { AppState, useUser } from 'reducers';
import { useSelector } from 'react-redux';
import { DeliveryOption, PromotionalProduct, UserDeliveryAddress } from 'lib/types';
import { buildResourceLink } from 'lib/communication';
import ButtonComponent from 'components/ButtonComponent';
import DeliveryAddressSelect from 'components/DeliveryAddressSelect';
import { Options } from './PromotionProductsDeliveryOptions.json';


interface PromotionProductsSummaryProps {
	grandTotalPoints: number;
	remainingPoints: number;
	selectedCount: Map<PromotionalProduct, number[]>;
	deliveryOption: DeliveryOption | null;
	setDeliveryOption: (_: DeliveryOption | null) => void;
	setShowSummary: (_: boolean) => void;
	submitNewOrder: (_: React.FormEvent<HTMLFormElement>) => void;
}

export default function PromotionProductsSummary({ grandTotalPoints, remainingPoints, selectedCount, deliveryOption, setDeliveryOption, setShowSummary, submitNewOrder }: PromotionProductsSummaryProps) {
	const isDesktop = useMediaQuery({ minWidth: LG });
	const [deliveryOptions, setDeliveryOptions] = useState<UserDeliveryAddress[]>(Options.map((option) => new UserDeliveryAddress(option)));
	const userDeliveryAddresses = useSelector((state: AppState) => state.data.userInfo.userDeliveryAddresses); // addresses should be fetched in an earlier view
	const delivery = useSelector((state: AppState) => state.data.promotionalProgram.delivery);
	const isAddingOrder = useSelector((state: AppState) => state.data.promotionalProgram.props.isAddingOrder);
	const user = useUser()

	const handleDeliveryChange = (event: ChangeEvent<HTMLInputElement>) => {
		setDeliveryOption(event.currentTarget.value as DeliveryOption);
	}

	return (
		<Form onSubmit={submitNewOrder}>
			<h3>Podsumowanie zamówienia</h3>
			{isDesktop ? (
				<Table borderless striped className="tr2 tr3 tr4">
					<thead>
						<tr>
							<th>Opis</th>
							<th>Rozmiar</th>
							<th>Ilość</th>
							<th>Suma punktów</th>
						</tr>
					</thead>
					<tbody>
						{Array.from(selectedCount.entries()).flatMap(([product, counts]) =>
							Array.from(counts.entries())
								.filter(([_, value]) => value !== 0)
								.map(([index, value]) => (
									<tr key={`${product.symKar}_${index}_${value}`}>
										<td>{product.description}</td>
										<td>
											{product.options?.[index] !== ""
												? product.options?.[index]
												: 'N/D'}
										</td>
										<td>{value}</td>
										<td>{value * product.points}</td>
									</tr>
								))
						)}
						{deliveryOption === 'inputAddress' && (
							<tr>
								<td>{delivery.description}</td>
								<td>N/D</td>
								<td>1</td>
								<td>{delivery.points}</td>
							</tr>
						)}
					</tbody>
					<tfoot>
						<tr>
							<td colSpan={3} />
							<td>{grandTotalPoints}</td>
						</tr>
					</tfoot>
				</Table>
			) : (
				<>
					<ListGroup variant="flush">
						{Array.from(selectedCount.entries()).flatMap(([product, counts]) =>
							Array.from(counts.entries())
								.filter(([_, value]) => value !== 0)
								.map(([index, value]) => (
									<ListGroup.Item key={`${product.symKar}_${index}_${value}`}>
										<p className="text-left clearfix">
											<span className="PromotionProducts-photo-td float-left mr-3">
												<ImageThumbnail
													src={buildResourceLink(product.photo)}
													alt=""
												/>
											</span>
											<span>{product.description}</span>
										</p>
										<div>
											{
												<>
													Rozmiar:{' '}
													{product.options?.[index] !== ''
														? product.options?.[index]
														: 'N/D'}
													<br />
												</>
											}
											Ilość: {value}
											<br />
											Suma punktów: {value * product.points}
										</div>
									</ListGroup.Item>
								))
						)}
						{deliveryOption === 'inputAddress' && (
							<ListGroup.Item key="deliveryItem">
								<p className="text-left clearfix">
									<span>{delivery.description}</span>
								</p>
								<div>
									Rozmiar: N/D
									<br />
									Ilość: 1<br />
									Suma punktów: {delivery.points}
								</div>
							</ListGroup.Item>
						)}
					</ListGroup>
					<p className="mt-3">
						<b>Suma punktów zamówienia: {grandTotalPoints}</b>
					</p>
				</>
			)}
			<div className="PromotionProducts-delivery mx-3 mb-3">
				<h3>Dostawa</h3>
				{Array.from(selectedCount.entries()).flatMap(([product, counts]) =>
					Array.from(counts.entries())
						.filter(([_, value]) => value !== 0)
						.map(_ => product.isElectronicShipping)
				).includes(true) ?
					(<Form.Check
						type="radio"
						label={`Wysyłka elektroniczna`}
						checked
						className="pb-3"
					/>) : (<>
						{delivery.delivery === 'available' && (
							<Form.Check
								type="radio"
								label={`${delivery.description}`}
								name="delivery"
								id="delivery-1"
								value="inputAddress"
								checked={deliveryOption === 'inputAddress'}
								onChange={handleDeliveryChange}
								className="pb-3"
								required
							/>
						)}
						<Collapse in={deliveryOption === 'inputAddress'}>
							<div>
								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>Imię</Form.Label>
										<Form.Control
											type="text"
											id="firstName"
											required={deliveryOption === 'inputAddress'}
										/>
									</Form.Group>
									<Form.Group as={Col}>
										<Form.Label>Nazwisko</Form.Label>
										<Form.Control
											type="text"
											id="lastName"
											required={deliveryOption === 'inputAddress'}
										/>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>Nazwa firmy</Form.Label>
										<Form.Control type="text" id="companyName" />
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}>
										<Form.Label>Adres dostawy</Form.Label>
										<DeliveryAddressSelect name="deliveryAddress" required addresses={userDeliveryAddresses} />
									</Form.Group>
								</Form.Row>
							</div>
						</Collapse>
						{
							user?.role !== USER_ROLE_ROOFER && user?.role !== USER_ROLE_TRADER ? (
								<>
									<Form.Check
										type="radio"
										label="odbiór własny z oddziałów firmy"
										name="delivery"
										id="delivery-2"
										value="selfPickup"
										checked={deliveryOption === 'selfPickup'}
										onChange={handleDeliveryChange}
										className="pb-3"
										required
									/>
									<Collapse in={deliveryOption === 'selfPickup'}>
										<div>
											<Form.Control as="select" id="branch" className="mb-3">
												<option value="filia1">Filia 1</option>
												<option value="filia2">Filia 2</option>
												<option value="filia3">Filia 3</option>
											</Form.Control>
										</div>
									</Collapse>
									<Form.Check
										type="radio"
										label="wysyłka na adres dystrybutora"
										name="delivery"
										id="delivery-3"
										value="distributorAddress"
										checked={deliveryOption === 'distributorAddress'}
										onChange={handleDeliveryChange}
										className="pb-3"
										required
									/>
								</>
							) : null
						}
					</>)
				}
			</div>
			{remainingPoints < 0 && (
				<Alert variant="danger" className="my-3">
					Niewystarczająca ilość punktów.
				</Alert>
			)}
			<div id="PromotionProductsSummary-confirm-block">
				<ButtonComponent
					marginLeft="0"
					marginRight="18px"
					width='8em'
					text='Wróć'
					onClick={() => {
						setDeliveryOption(null);
						setShowSummary(false);
						window.scrollTo(0, 0);
					}}
				/>
				{
					isAddingOrder ? (
						<Spinner animation="border" className="ml-5" />
					) : (
						<ButtonComponent
							marginLeft="0"
							marginRight="0"
							width='8em'
							text='Zamów'
							type="submit"
							disabled={remainingPoints < 0}
						/>
					)
				}
			</div>
		</Form>
	);
};
