import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	News, Billing, SaleAgent, Product, Order, DetailedOrder, PromotionalProduct, PromotionalOrder, PromotionalDelivery, GroupsAndProducts, UserDeliveryAddress,
	RegisteredUserData, CartContent, LocalCart, ProductInLocalCart, ProductPrice, ProductsType, IsodProduct, ProductsGroup, GutteringSystemData,
	NotificationComponentData, WebserviceTestResult, GutteringSystem, GutteringAssortment, ServiceWorkType, OrderStatus, OrderPartStatus, OrderStatusNames, OrderStatusName,
	CacheManagement, GutteringSystemDataV2,
	Statistics
} from 'lib/types';
import _ from 'lodash';
import { DataFromGutterings } from 'components/GutterCreator/GutterCreator';
import { TreeNode } from 'lib/tree';
import { NewsResponse } from 'lib/communication/communicationTypes';

export interface UserRegistrationInfo {
	user: {
		firstName: string;
		lastName: string;
		nip?: string;
		treasuryOffice?: string;
		pesel?: string;
		phone?: string;
		email: string;
		street?: string;
		buildingNo?: string;
		apartmentNo?: string;
		postalCode?: string;
		town?: string;
	};
	company: {
		name?: string;
		street?: string;
		streetNumber?: string;
		flatNumber?: string;
		postalCode?: string;
		city?: string;
		regon?: string;
	};
}

const dataSlice = createSlice({
	name: 'data',
	initialState: {
		notificationComponent: {
			props: {
				isVisible: false as boolean,
			},
			notificationMessage: '' as string,
			notificationVariant: '' as string,
		},
		userInfo: {
			props: {
				isFetching: false as boolean,
			},
			userInfo: null as UserRegistrationInfo | null,
			userDeliveryAddresses: null as UserDeliveryAddress[] | null,
			currentOrIncomigServiceWorks: {} as ServiceWorkType
		},
		news: {
			props: {
				isFetching: false as boolean,
			},
			news: [] as News[],
			totalCount: 0,
		},
		orders: {
			props: {
				isFetching: false,
				pdfIsFetching: false,
				isFetchingGroups: false,
				isFetchingOrders: false,
				isErrorGettingDetailedOrder: false,
				isAddingOrder: false,
				isGutteringsDownloaded: false,
				isCartFetching: true,
				isSearchingProducts: false,
				isFetchingProductInfo: false,
				productsOfGroupsFetching: [] as string[],
				isOrderStatusFetching: false
			},
			orders: Object.fromEntries(_.map(OrderStatusNames, (name) => [name, null])) as { [NAME in OrderStatusName]: Order[] | null },
			localCart: { productGroups: [], gutterings: [], isod: [] } as LocalCart,
			localEditCart: { productGroups: [], gutterings: [], isod: [] } as LocalCart,
			cartProdNotAvailable: false,
			previousSummaryPrice: null as number | null,
			cart: {
				productGroups: [] as CartContent["productGroups"],
				gutterGroups: [] as CartContent["gutterGroups"]
			} as CartContent,
			edit: false as boolean,
			editCart: {
				productGroups: [] as CartContent["productGroups"],
				gutterGroups: [] as CartContent["gutterGroups"]
			} as CartContent,
			additionalAssortmentPrice: [] as ProductPrice[],
			groupsAndProducts: {} as GroupsAndProducts,
			detailedOrder: {} as DetailedOrder,
			advancedTree: null as TreeNode[] | null,
			gutteringSystems: [] as (GutteringSystem[] | GutteringAssortment),
			orderStatus: {
				show: false,
				order: {} as Order,
				parts: [] as OrderPartStatus[]
			},
			editImageUploadFetching: false,
			editImagesUploadFetching: false,
			isAssortmentGraphicsFetching: false as boolean,
		},
		settlements: {
			props: {
				settlementsIsFetching: false as boolean,
				pdfIsFetching: false as boolean,
			},
			settlements: [] as Billing[],
		},
		promotionalProgram: {
			props: {
				isFetching: false,
				isFetchingProducts: false,
				isFetchingPoints: false,
				isAddingOrder: false,
				traderBlockIsFetching: false,
			},
			salesAgents: [] as SaleAgent[],
			groups: [] as string[],
			products: [] as PromotionalProduct[],
			delivery: {} as PromotionalDelivery,
			orders: [] as PromotionalOrder[],
			points: 0,
			pointsSpent: 0,
			termsAndConditions: '',
			registeredUserData: {} as RegisteredUserData,
			cookiePolicy: ''
		},
		webserviceTestResults: {
			props: {
				isFetching: false as boolean,
			},
			results: [] as WebserviceTestResult[],
		},
		adminInfo: {
			props: {
				isFetching: false,
			},
			serviceWorks: [] as ServiceWorkType[],
			cacheManagement: [] as CacheManagement[],
			isCacheManagementFetching: false as boolean,
			statistics: {} as Statistics
		},
		gutterCreator: {
			props: {
				filters: {} as GutteringSystemDataV2,
				toBeUpdated: false
			}
		}
	},
	reducers: {
		setGutterCreatorFilters(state, action: PayloadAction<GutteringSystemDataV2>){
			state.gutterCreator.props.filters = action.payload;
		},
		setGutterToBeUpdated(state, action: PayloadAction<boolean>){
			state.gutterCreator.props.toBeUpdated = action.payload;
		},
		setGutterCreatorFilter(state, action: PayloadAction<{filterName: string, value: string}>) {
			state.gutterCreator.props.filters[action.payload.filterName as keyof GutteringSystemDataV2] = action.payload.value;
		},
		setIsCacheManagementFetching(state, action: PayloadAction<boolean>) {
			state.adminInfo.props.isFetching = action.payload;
		},
		setCacheManagement(state, action: PayloadAction<CacheManagement[]>) {
			state.adminInfo.cacheManagement = action.payload;
		},
		setAdminInfoFetching(state, action: PayloadAction<boolean>) {
			state.adminInfo.props.isFetching = action.payload;
		},
		setServiceWorks(state, action: PayloadAction<ServiceWorkType[]>) {
			state.adminInfo.serviceWorks = action.payload;
		},
		setStatistics(state, action: PayloadAction<Statistics>) {
			state.adminInfo.statistics = action.payload;
		},
		setCurrentOrIncomingServiceWorks(state, action: PayloadAction<ServiceWorkType>) {
			state.userInfo.currentOrIncomigServiceWorks = action.payload;
		},
		clearUserDeliveryAddresses(state) {
			state.userInfo.userDeliveryAddresses = null;
		},
		setUserDeliveryAddresses(state, action: PayloadAction<UserDeliveryAddress[]>) {
			state.userInfo.userDeliveryAddresses = action.payload;
		},
		setUserInfo(state, action: PayloadAction<UserRegistrationInfo | null>) {
			state.userInfo.userInfo = action.payload;
		},
		setUserInfoFetching(state, action: PayloadAction<boolean>) {
			state.userInfo.props.isFetching = action.payload;
		},
		setNewsData(state, action: PayloadAction<NewsResponse>) {
			state.news.news = action.payload.news;
			state.news.totalCount = action.payload.totalCount;
		},
		updateNews(state, action: PayloadAction<News>) {
			const index = state.news.news.findIndex(e => e.id === action.payload.id);
			if (index >= 0) {
				state.news.news[index] = action.payload;
			}
		},
		setIsNewsArchived(state, action: PayloadAction<{ id: number, value: boolean }>) {
			const index = state.news.news.findIndex(e => e.id === action.payload.id);
			if (index >= 0) {
				state.news.news[index].isArchived = action.payload.value;
			}
		},
		setNewsFetching(state, action: PayloadAction<boolean>) {
			state.news.props.isFetching = action.payload;
		},
		setSettlementsList(state, action: PayloadAction<Billing[]>) {
			state.settlements.settlements = action.payload;
		},
		setSettlements(state, action: PayloadAction<any>) {
			state.settlements = action.payload;
		},
		setPromotionalProgram(state, action: PayloadAction<Object>) {
			state.promotionalProgram = {
				...state.promotionalProgram,
				...action.payload,
			};
		},
		setPromotionalProgramPoints(state, action: PayloadAction<number>) {
			state.promotionalProgram.points = action.payload;
		},
		setPromotionalProgramPointsSpent(state, action: PayloadAction<number>) {
			state.promotionalProgram.pointsSpent = action.payload;
		},
		setSaleAgents(state, action: PayloadAction<SaleAgent[]>) {
			state.promotionalProgram.salesAgents = action.payload;
		},
		activateSalesAgent(state, action: PayloadAction<SaleAgent>) {
			const index = state.promotionalProgram.salesAgents.findIndex(e => e.login === action.payload.login);
			if (index >= 0) {
				state.promotionalProgram.salesAgents[index].blocked = false;
			}
		},
		blockSalesAgent(state, action: PayloadAction<SaleAgent>) {
			const index = state.promotionalProgram.salesAgents.findIndex(e => e.login === action.payload.login);
			if (index >= 0) {
				state.promotionalProgram.salesAgents[index].blocked = true;
			}
		},
		setGroupsAndProducts(state, action: PayloadAction<GroupsAndProducts>) {
			state.orders.groupsAndProducts = action.payload;
		},
		clearGroupsAndProducts(state) {
			state.orders.groupsAndProducts = {} as GroupsAndProducts;
		},
		setGroups(state, action: PayloadAction<ProductsGroup[]>) {
			state.orders.groupsAndProducts.groups = action.payload;
		},
		setOrders(state, action: PayloadAction<any>) {
			state.orders = action.payload;
		},
		addProducts(state, action: PayloadAction<Product[]>) {
			const oldProducts = _.filter(state.orders.groupsAndProducts.products, (product) =>
				!_.includes(
					_.map(action.payload, (fetchedProduct) => fetchedProduct.symKar),
					product.symKar
				)
			)
			state.orders.groupsAndProducts.products = [...oldProducts, ...action.payload].sort(({description: a}, {description: b}) => a.localeCompare(b));
		},
		setOrdersList(state, action: PayloadAction<{status: OrderStatusName, data: Order[]}>) {
			state.orders.orders[action.payload.status] = action.payload.data;
		},
		resetOrdersList(state) {
			state.orders.orders= Object.fromEntries(_.map(OrderStatusNames, (name) => [name, null])) as { [NAME in OrderStatusName]: Order[] | null };
		},
		setDetailedOrder(state, action: PayloadAction<DetailedOrder>) {
			state.orders.detailedOrder = action.payload;
		},
		setCart(state, action: PayloadAction<CartContent>) {
			state.orders.cart = action.payload;
			state.orders.previousSummaryPrice = null;
		},
		setEdit(state, action: PayloadAction<boolean>) {
			state.orders.edit = action.payload;
		},
		setEditCart(state, action: PayloadAction<CartContent>) {
			state.orders.editCart = action.payload;
			state.orders.previousSummaryPrice = null;
		},
		addToLocalCart(state, action: PayloadAction<{ groupPath: string, product: ProductInLocalCart, productsType: ProductsType }>) {
			const index = _.findIndex(state.orders.localCart.productGroups, (pg) => pg.groupPath === action.payload.groupPath);
			if (index >= 0) {
				const products = state.orders.localCart.productGroups[index][action.payload.productsType]
				const similarProductIndex = _.findIndex(products, (product) =>
					product.product.description === action.payload.product.product.description &&
					product.dimension === action.payload.product.dimension &&
					_.isEqual(product.additionsSelected, action.payload.product.additionsSelected) && 
					product.mainProductSymkar === action.payload.product.mainProductSymkar && 
					_.isEqual(product.unit, action.payload.product.unit)
				);
				if (similarProductIndex >= 0) {
					const [product] = products.splice(similarProductIndex, 1);
					product.quantity += action.payload.product.quantity;
					products.push(product);	
				} else {
					products.push(action.payload.product);
				}
			} else {
				state.orders.localCart.productGroups.push({
					groupPath: action.payload.groupPath,
					mainProducts: [],
					additionalProducts: [],
					[action.payload.productsType]: [action.payload.product]
				});
			}
		},
		clearLocalEditCart(state) {
			state.orders.localEditCart = { productGroups: [], gutterings: [], isod: [] };
		},
		addToLocalEditCart(state, action: PayloadAction<{ groupPath: string, product: ProductInLocalCart, productsType: ProductsType }>) {
			const index = _.findIndex(state.orders.localEditCart.productGroups, (pg) => pg.groupPath === action.payload.groupPath);
			if (index >= 0) {
				const products = state.orders.localEditCart.productGroups[index][action.payload.productsType]
				const similarProductIndex = _.findIndex(products, (product) =>
					product.product.description === action.payload.product.product.description &&
					product.dimension === action.payload.product.dimension &&
					_.isEqual(product.additionsSelected, action.payload.product.additionsSelected) &&
					product.mainProductSymkar === action.payload.product.mainProductSymkar &&
					_.isEqual(product.unit, action.payload.product.unit)
				);
				if (similarProductIndex >= 0) {
					const [product] = products.splice(similarProductIndex, 1);
					product.quantity += action.payload.product.quantity;
					products.push(product);	
				} else {
					products.push(action.payload.product);
				}
			} else {
				state.orders.localEditCart.productGroups.push({
					groupPath: action.payload.groupPath,
					mainProducts: [],
					additionalProducts: [],
					[action.payload.productsType]: [action.payload.product]
				});
			}
		},
		deleteFromLocalEditCart(state, action: PayloadAction<{ groupPath: string, index: number, productsType: ProductsType, symKar: string }>) {
			const index = _.findIndex(state.orders.localEditCart.productGroups, (pg) => pg.groupPath === action.payload.groupPath);
			if (index >= 0) {
				const group = state.orders.localEditCart.productGroups[index];
				group[action.payload.productsType].splice(action.payload.index, 1);
				if (group.mainProducts.length === 0 && group.additionalProducts.length === 0) {
					state.orders.localEditCart.productGroups.splice(index, 1);
				}
			} else {
				const isodIndex = _.findIndex(state.orders.localEditCart.isod, (isod) => _.some(isod, i => i.product.symKar === action.payload.symKar));
				if (isodIndex >= 0) {
					state.orders.localEditCart.isod[isodIndex].splice(action.payload.index, 1);
					if (state.orders.localEditCart.isod[isodIndex].length === 0) {
						state.orders.localEditCart.isod.splice(isodIndex, 1);
					}
				}
			}
		},
		editProductsInLocalEditCart(state, action: PayloadAction<{ groupPath: string, index: number, product: ProductInLocalCart, productsType: ProductsType }>) {
			const groupIndex = _.findIndex(state.orders.localEditCart.productGroups, (pg) => pg.groupPath === action.payload.groupPath);
			if (groupIndex >= 0) {
				const products = state.orders.localEditCart.productGroups[groupIndex][action.payload.productsType]
				const similarProductIndex = _.findIndex(products, (product) =>
					product.product.description === action.payload.product.product.description &&
					product.dimension === action.payload.product.dimension &&
					_.isEqual(product.additionsSelected, action.payload.product.additionsSelected) && 
					product.mainProductSymkar === action.payload.product.mainProductSymkar &&
					_.isEqual(product.unit, action.payload.product.unit)
				);
				if (similarProductIndex !== action.payload.index && similarProductIndex >= 0) {
					products[similarProductIndex].quantity += action.payload.product.quantity;
					state.orders.localEditCart.productGroups[groupIndex][action.payload.productsType].splice(action.payload.index, 1);
				} else {
					state.orders.localEditCart.productGroups[groupIndex][action.payload.productsType][action.payload.index] = action.payload.product;
				}
			} else {
				const isodIndex = _.findIndex(state.orders.localEditCart.isod, (isod) => _.some(isod, i => i.product.symKar === action.payload.product.product.symKar));
				if (isodIndex >= 0) {
					state.orders.localEditCart.isod[isodIndex][action.payload.index] = {
						product: action.payload.product.product,
						quantity: action.payload.product.quantity,
						dimension: action.payload.product.dimension as number,
						additionsSelected: action.payload.product.additionsSelected
					};
				}
			}
		},
		clearAdditionalAssortmentPrice(state, action: PayloadAction<number>) {
			state.orders.additionalAssortmentPrice = Array<ProductPrice>(action.payload);
		},
		setAdditionalAssortmentPrice(state, action: PayloadAction<{ productPrice: ProductPrice, index: number }>) {
			state.orders.additionalAssortmentPrice[action.payload.index] = action.payload.productPrice;
		},
		addGutteringsToLocal(state, action: PayloadAction<DataFromGutterings[]>) {
			const group = state.orders.localCart.gutterings;
			_.remove(group, function (element) {
				return _.isEqual(element.systemData, action.payload[0].systemData);
			});
			_.map(action.payload, (data) => {
				const index = _.findIndex(group, (g) => g.product.symKar === data.product.symKar && _.isEqual(g.systemData, data.systemData));
				if (index > -1) {
					if (data.quantity === 0) {
						group.splice(index, 1);
					} else {
						group[index].quantity = data.quantity;
					}
				} else {
					group.push(data);
				}
			});
		},
		addGutteringsToEditLocal(state, action: PayloadAction<DataFromGutterings[]>) {
			const group = state.orders.localEditCart.gutterings;
			_.remove(group, function (element) {
				return _.isEqual(element.systemData, action.payload[0].systemData);
			});
			_.map(action.payload, (data) => {
				const index = _.findIndex(group, (g) => g.product.symKar === data.product.symKar && _.isEqual(g.systemData, data.systemData));
				if (index > -1) {
					if (data.quantity === 0) {
						group.splice(index, 1);
					} else {
						group[index].quantity = data.quantity;
					}
				} else {
					group.push(data);
				}
			});
		},
		deleteGutteringsFromEditCart(state, action: PayloadAction<{ symKar: string, systemData: GutteringSystemData }>) {
			const group = state.orders.localEditCart.gutterings;
			const index = _.findIndex(group, (g) => g.product.symKar === action.payload.symKar && _.isEqual(g.systemData, action.payload.systemData));
			group.splice(index, 1);
		},
		editGutteringsInEditLocal(state, action: PayloadAction<{ symKar: string, quantity: number, systemData: GutteringSystemData }>) {
			const index = _.findIndex(state.orders.localEditCart.gutterings, (g) => g.product.symKar === action.payload.symKar && _.isEqual(g.systemData, action.payload.systemData));
			state.orders.localEditCart.gutterings[index].quantity = action.payload.quantity;
		},
		deleteFromLocalCart(state, action: PayloadAction<{ groupPath: string, index: number, productsType: ProductsType, symKar: string }>) {
			const index = _.findIndex(state.orders.localCart.productGroups, (pg) => pg.groupPath === action.payload.groupPath);
			if (index >= 0) {
				const group = state.orders.localCart.productGroups[index];
				group[action.payload.productsType].splice(action.payload.index, 1);
				if (group.mainProducts.length === 0 && group.additionalProducts.length === 0) {
					state.orders.localCart.productGroups.splice(index, 1);
				}
			} else {
				const isodIndex = _.findIndex(state.orders.localCart.isod, (isod) => _.some(isod, i => i.product.symKar === action.payload.symKar));
				if (isodIndex >= 0) {
					state.orders.localCart.isod[isodIndex].splice(action.payload.index, 1);
					if (state.orders.localCart.isod[isodIndex].length === 0) {
						state.orders.localCart.isod.splice(isodIndex, 1);
					}
				}
			}
		},
		deleteGutteringsFromCart(state, action: PayloadAction<{ symKar: string, systemData: GutteringSystemData }>) {
			const group = state.orders.localCart.gutterings;
			const index = _.findIndex(group, (g) => g.product.symKar === action.payload.symKar && _.isEqual(g.systemData, action.payload.systemData));
			group.splice(index, 1);
		},
		editProductsInLocalCart(state, action: PayloadAction<{ groupPath: string, index: number, product: ProductInLocalCart, productsType: ProductsType }>) {
			const groupIndex = _.findIndex(state.orders.localCart.productGroups, (pg) => pg.groupPath === action.payload.groupPath);
			if (groupIndex >= 0) {
				const products = state.orders.localCart.productGroups[groupIndex][action.payload.productsType]
				const similarProductIndex = _.findIndex(products, (product) =>
					product.product.description === action.payload.product.product.description &&
					product.dimension === action.payload.product.dimension &&
					_.isEqual(product.additionsSelected, action.payload.product.additionsSelected) && 
					product.mainProductSymkar === action.payload.product.mainProductSymkar &&
					_.isEqual(product.unit, action.payload.product.unit)
				);
				if (similarProductIndex !== action.payload.index && similarProductIndex >= 0) {
					products[similarProductIndex].quantity += action.payload.product.quantity;
					state.orders.localCart.productGroups[groupIndex][action.payload.productsType].splice(action.payload.index, 1);
				} else {
					state.orders.localCart.productGroups[groupIndex][action.payload.productsType][action.payload.index] = action.payload.product;
				}
			} else {
				const isodIndex = _.findIndex(state.orders.localCart.isod, (isod) => _.some(isod, i => i.product.symKar === action.payload.product.product.symKar));
				if (isodIndex >= 0) {
					state.orders.localCart.isod[isodIndex][action.payload.index] = {
						product: action.payload.product.product,
						quantity: action.payload.product.quantity,
						dimension: action.payload.product.dimension as number,
						additionsSelected: action.payload.product.additionsSelected
					};
				}
			}
		},
		editGutteringsInLocal(state, action: PayloadAction<{ symKar: string, quantity: number, systemData: GutteringSystemData }>) {
			const index = _.findIndex(state.orders.localCart.gutterings, (g) => g.product.symKar === action.payload.symKar && _.isEqual(g.systemData, action.payload.systemData));
			state.orders.localCart.gutterings[index].quantity = action.payload.quantity;
		},
		addIsodToLocal(state, action: PayloadAction<IsodProduct[]>) {
			_.forEach(action.payload, (prd) => {
				let path = prd.product.path;
				let groupExists = false;
				_.forEach(state.orders.localCart.isod, (cartGrp) => {
					if(JSON.stringify(cartGrp[0].product.path) === JSON.stringify(path)){
						groupExists = true;
						let productExists = false;
						_.forEach(cartGrp, (cartPrd) => {
							if(	cartPrd.product.description === prd.product.description &&
								cartPrd.dimension === prd.dimension &&
								_.isEqual(cartPrd.additionsSelected, prd.additionsSelected)){
									productExists = true;
									cartPrd.quantity += prd.quantity;
									return false;
								}
						});
						if(!productExists){
							cartGrp.push(prd);
						}
						return false;
					}
				});
				if(!groupExists){
					state.orders.localCart.isod.push([prd]);
				}
			});
		},
		addIsodToEditLocal(state, action: PayloadAction<IsodProduct[]>) {
			_.forEach(action.payload, (prd) => {
				let path = prd.product.path;
				let groupExists = false;
				_.forEach(state.orders.localEditCart.isod, (cartGrp) => {
					if(JSON.stringify(cartGrp[0].product.path) === JSON.stringify(path)){
						groupExists = true;
						let productExists = false;
						_.forEach(cartGrp, (cartPrd) => {
							if(	cartPrd.product.description === prd.product.description &&
								cartPrd.dimension === prd.dimension &&
								_.isEqual(cartPrd.additionsSelected, prd.additionsSelected)){
									productExists = true;
									cartPrd.quantity += prd.quantity;
									return false;
								}
						});
						if(!productExists){
							cartGrp.push(prd);
						}
						return false;
					}
				});
				if(!groupExists){
					state.orders.localEditCart.isod.push([prd]);
				}
			});
		},
		clearIsodCart(state) {
			state.orders.localCart.isod = [] as IsodProduct[][];
		},
		setLocalCart(state, action: PayloadAction<LocalCart>) {
			state.orders.localCart = action.payload;
		},
		setCartProdNotAvailable(state, action: PayloadAction<boolean>) {
			state.orders.cartProdNotAvailable = action.payload;
		},
		setPreviousSummaryPrice(state, action: PayloadAction<number|null>) {
			state.orders.previousSummaryPrice = action.payload;
		},
		clearLocalCart(state) {
			state.orders.localCart = { productGroups: [], gutterings: [], isod: [] } as LocalCart;
		},
		setAdvancedTree(state, action: PayloadAction<TreeNode[]>) {
			state.orders.advancedTree = action.payload;
		},
		setIsErrorGettingDetailedOrder(state, action: PayloadAction<boolean>) {
			state.orders.props.isErrorGettingDetailedOrder = action.payload;
		},
		clearLocalGutteringsCart(state, action: PayloadAction<GutteringSystemData>) {
			const group = state.orders.localCart.gutterings;
			_.remove(group, function (element) {
				return _.isEqual(element.systemData, action.payload);
			});
		},
		clearLocalEditGutteringsCart(state, action: PayloadAction<GutteringSystemData>) {
			const group = state.orders.localEditCart.gutterings;
			_.remove(group, function (element) {
				return _.isEqual(element.systemData, action.payload);
			});
		},
		setWebserviceTestsFetching(state, action: PayloadAction<boolean>) {
			state.webserviceTestResults.props.isFetching = action.payload;
		},
		setWebserviceTestsResults(state, action: PayloadAction<WebserviceTestResult[]>) {
			state.webserviceTestResults.results = action.payload;
		},
		setWebserviceTests(state, action: PayloadAction<any>) {
			state.webserviceTestResults = action.payload;
		},
		setGutteringSystems(state, action: PayloadAction<any>) {
			state.orders.gutteringSystems = action.payload;
		},
		setCartFetching(state, action: PayloadAction<boolean>) {
			state.orders.props.isCartFetching = action.payload;
		},
		setNotificationComponent(state, action: PayloadAction<NotificationComponentData>) {
			state.notificationComponent.props.isVisible = action.payload.isVisible;
			state.notificationComponent.notificationMessage = action.payload.message;
			state.notificationComponent.notificationVariant = action.payload.variant;
		},
		hideNotificationComponent(state) {
			state.notificationComponent.props.isVisible = false;
		},
		setProductsOfGroupsFetching(state, action: PayloadAction<string[]>) {
			state.orders.props.productsOfGroupsFetching = action.payload;
		},
		setIsOrderStatusFetching(state, action: PayloadAction<boolean>) {
			state.orders.props.isOrderStatusFetching = action.payload;
		},
		setOrderStatus(state, action: PayloadAction<OrderStatus>) {
			state.orders.orderStatus = action.payload;
		},
		setIsEditImageUploadFetching(state, action: PayloadAction<boolean>) {
			state.orders.editImageUploadFetching = action.payload;
		},
		setIsEditImagesUploadFetching(state, action: PayloadAction<boolean>) {
			state.orders.editImagesUploadFetching = action.payload;
		},
		setIsAssortmentGraphicsFetching(state, action: PayloadAction<boolean>) {
			state.orders.isAssortmentGraphicsFetching = action.payload;
		}
	},
});

export default dataSlice.reducer;

export const dataActions = dataSlice.actions;
