import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { AppState } from 'reducers';

import LoginForm from './LoginForm';
import ResetPassword from './ResetPassword';

const LoginContainer = () => {
	const logo = useSelector((state: AppState) => state.conf.logo);
	const company = useSelector((state: AppState) => state.conf.companyId);

	return (
		<>
			<div className="Login">
				<div className="Login-header">
					{company !== null && <img src={`${process.env.PUBLIC_URL}/${logo}`} alt="Blachy Pruszyński" className="my-5" />}
					<h1 className="mb-4">System obsługi zleceń online</h1>
				</div>
				<Switch>
					<Route path={`${process.env.PUBLIC_URL}/resetPassword`} exact>
						<ResetPassword />
					</Route>
					<Route path="/">
						<LoginForm />
					</Route>
				</Switch>
			</div>
		</>
	);
};

export default LoginContainer;
