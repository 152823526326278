
import './NumberInput.scss';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import useCallbackState from 'lib/hooks/useCallbackState';

interface NumberInputProps {
	value: number;
	defaultValue?: number;
	setValue: (value: any, ...args: any[]) => void;
	correct?: boolean;
	setCorrect?: (value: any, ...args: any[]) => void;
	reset?: boolean;
	setReset?: (value: any, ...args: any[]) => void;
	min?: number;
	max?: number;
	maxLength?: number;
	step?: number;
	fractDigits?: number;
	minDesktopResolution?: number;
	className?: string;
	disabled?: boolean;
	disabledEdit?: boolean;
	stopPropagation?: boolean;
	tabIndex?: number;
	inputRef?: React.RefObject<HTMLInputElement>;
	showMinMaxOnInvalidRange?: boolean;
}

export default function NumberInput({
	value,
	defaultValue,
	setValue,
	correct,
	setCorrect,
	reset,
	setReset,
	inputRef,
	min = 1,
	max = Math.pow(10, 5),
	maxLength = 20,
	step = 1,
	fractDigits = 0,
	className = "",
	disabled = false,
	disabledEdit = false,
	tabIndex = -1,
	showMinMaxOnInvalidRange = false,
}: NumberInputProps) {

	const getValue = (valueN: number) => {
		return valueN === undefined ? min.toFixed(fractDigits) : valueN.toFixed(fractDigits);
	}

	const [visibleValue, setVisibleValue] = useCallbackState<string>(getValue(value));
	const [isHintVisible, setIsHintVisible] = useState(false);
	const hintTimer = useRef<NodeJS.Timeout | null>(null);
	
	const handleChange = (newVal: any, event: any) => {
		event.stopPropagation();
		changeValue(newVal);
	}

	useEffect(() => {
		if (reset && defaultValue !== undefined) {
			setVisibleValue(getValue(defaultValue), () => inputRef?.current?.select());
			if (setReset !== undefined) {
				setReset(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reset]);

	useEffect(() => {
		setVisibleValue(getValue(value));
		// eslint-disable-next-line
	}, [value]);

	const showHint = () => {
		if (!showMinMaxOnInvalidRange) return;
		setIsHintVisible(true);
		if (hintTimer.current) {
			clearTimeout(hintTimer.current);
		}
		hintTimer.current = setTimeout(() => {
			setIsHintVisible(false);
		}, 2000);
		
	}

	const changeValue = (newVal: any) => {
		setIsHintVisible(false);
		if (min > newVal) {
			showHint();
			setValue(min);
			changeVisibleValue(min.toFixed(fractDigits));
		}
		else if (max < newVal) {
			showHint();
			setValue(max);
			changeVisibleValue(max.toFixed(fractDigits));
		}
		else {
			setValue(Number(Number(newVal).toFixed(fractDigits)));
			changeVisibleValue(Number(newVal).toFixed(fractDigits));
		}
	}

	const sanitizeValue = () => {
		changeValue(visibleValue);
	}

	const keyEventHandler = (event: any) => {
		event.stopPropagation();
		switch (event.key) {
			case "ArrowUp":
				handleChange(value + step, event);
				break;
			case "ArrowDown":
				handleChange(Number(value - step), event)
				break;
			case " ":
				event.preventDefault();
				sanitizeValue();
				break;
			case "Enter":
				if (correct === false) {
					event.preventDefault();
				}
				sanitizeValue();
				break;
		}
	}

	const changeVisibleValue = (newVal: string) => {
		const val = newVal.replace(',', '.');
		if (!isNaN(Number(val))) {
			if (val.endsWith('.') && !fractDigits) {
				return;
			}
			setVisibleValue(val);
			if (val && Number(val) >= min) {
				if (!val.startsWith('0') || val.startsWith('0.')) {
					const fraction = val.split('.');
					if (fraction.length >= 1) {
						if (!val.endsWith('.')) {
							if (Number(val) > max) {
								if (setCorrect !== undefined) {
									setCorrect(false);
									return;
								}
							}
							if (fraction.length > 1 && fraction[1].length > fractDigits) {
								const noRoundVal = Number(fraction[0] + '.' + fraction[1].substring(0, fractDigits));
								setVisibleValue(noRoundVal.toFixed(fractDigits));
							}
							if (setCorrect !== undefined) {
								setCorrect(true);
								return;
							}
						}
					}
				}
			}
			if (setCorrect !== undefined) {
				setCorrect(false);
			}
		}
	}

	const handleFocus = (event: any) => event.target.select();

	return (
		<div className={`number-input ${className ? className : ""}`}>
			<Button tabIndex={tabIndex} onClick={(event) => event.detail && handleChange(Number(value - step), event)} disabled={disabled || disabledEdit}>-</Button>
			<OverlayTrigger
			show={isHintVisible}
			placement="bottom"
			overlay={<Tooltip id="tooltip">Wartość musi mieścić się w przedziale od {min.toFixed(fractDigits)} do {max.toFixed(fractDigits)}</Tooltip>}>
				<Form.Control type="text" size="sm" className="NumberInput-form-control m-0" min={min}
						step={step}
						value={visibleValue}
						onChange={(event) => changeVisibleValue(event.currentTarget.value)}
						onClick={(event: any) => { event.stopPropagation() }}
						disabled={disabled}
						onKeyDown={keyEventHandler}
						ref={inputRef}
						onFocus={(event: any) => handleFocus(event)}
						onBlur={sanitizeValue}
						autoComplete='off'
						style={correct === false ? { background: '#ecacac' } : { background: undefined }}
						maxLength={maxLength}
					/>
			</OverlayTrigger>
			<Button tabIndex={tabIndex} onClick={(event) => event.detail && handleChange(value + step, event)} disabled={disabled}>+</Button>
		</div>
	)
}
