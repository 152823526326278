import React from 'react';
import './PromotionRegulations.scss';

export default function PromotionRegulations() {
	return <>
		<img src={`${process.env.PUBLIC_URL}/images/infografika_1.jpg`} alt="Infografika 1" style={{ width: "100%" }} />
		<div className='PromotionRegulations'></div>
		<img src={`${process.env.PUBLIC_URL}/images/infografika_2.jpg`} alt="Infografika 2" style={{ width: "100%" }} />
		<div className='PromotionRegulations'></div>
		<img src={`${process.env.PUBLIC_URL}/images/infografika_3.jpg`} alt="Infografika 3" style={{ width: "100%" }} />
	</>;
}