import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './LinkItem.scss';
import { Role } from 'lib';
import { useUser } from 'reducers';
import _ from 'lodash';

interface LinkItemProps {
	to: string;
	children: React.ReactNode;
	eventKey?: string | undefined;
	roles?: [Role];
}

export default function LinkItem({ to, children, eventKey = undefined, roles = undefined }: LinkItemProps) {
	const user = useUser();

	if (roles !== undefined && !_.includes(roles, user?.role)) {
		return null;
	}

	return <>
		<Nav.Item className="LinktItem">
			<Link to={to} className="LinkItem-anchor">
				<Nav.Link as="div" eventKey={!eventKey ? to : eventKey}>
					{children}
				</Nav.Link>
			</Link>
		</Nav.Item>
	</>;
}