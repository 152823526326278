import React, { useCallback, useEffect, useState, useRef } from 'react';
import AssortmentSelection from './AssortmentSelection/AssortmentSelection';
import NewOrderSummary from './NewOrderSummary';
import './NewOrder.scss';
import { useSelector } from 'react-redux';
import { AppState, dataActions } from 'reducers';
import _ from 'lodash';
import { ISODPackageData, ISODProduct, ImportedProduct } from 'lib/types';
import { useMediaQuery } from 'react-responsive';
import { LG } from 'lib/util';
import { getDetailedOrder, getImportedProductsInfo, getOrderById, loadSessionCart, setSessionCart, resetIsodCart } from 'lib/communication/orders';
import { useHistory } from 'react-router-dom';
import store from 'store';
import { UnregisterCallback } from 'history';
import { ISOD_STORAGE_KEY } from 'containers/ISOD/ISOD';
import Notification from './AssortmentSelection/Notification';
import { getUserDeliveryAddresses } from 'lib/communication/userInfo';

const STEPS = 3;

type NewOrderState = {
	editedOrderId: string | null;
	copiedOrderId: string | null;
}

interface cartResetProps {
	cartReset: boolean;
	setCartReset: (value: boolean) => void;
}

export default function NewOrder(props: cartResetProps) {
	const history = useHistory<NewOrderState>();
	const editedOrderId = history.location.state ? history.location.state.editedOrderId : null;
	const copiedOrderId = history.location.state ? history.location.state.copiedOrderId : null;

	const isDesktop = useMediaQuery({ minWidth: LG });
	const edit = useSelector((state: AppState) => state.data.orders.edit);
	const detailedOrder = useSelector((state: AppState) => state.data.orders.detailedOrder);
	const localCart = useSelector((state: AppState) => state.data.orders.localCart);
	const isFetching = useSelector((state: AppState) => state.data.orders.props.isFetching);
	const cartContent = useSelector((state: AppState) => edit ? state.data.orders.editCart : state.data.orders.cart);
	const gutterVersion = useSelector((state: AppState) => state.conf.GutterCreatorVersion);

	const [step, setStep] = useState(edit ? 2 : 1);

	const isloadedCart = useRef(false);
	const unblock = useRef<UnregisterCallback>();

	const [isCancelModeOn, setIsCancelModeOn] = useState<boolean>(false);

	useEffect(() => {
		if (!isloadedCart.current) {
			return;
		}

		setSessionCart(localCart, cartContent.summaryPrice);
	}, [localCart, cartContent]);

	useEffect(() => {
		getUserDeliveryAddresses();
		unblock.current = history.block((location) => {
			if (isCancelModeOn) return;
			const msg = location.pathname === `${process.env.PUBLIC_URL}/isod` ? 'Uwaga, przejście do aplikacji ISOD spowoduję utratę dotychczasowych zmian dokonanych przy edycji zamówienia!' : 'Uwaga, opuszczenie strony spowoduje utracenie zmian i wyjście z trybu edycji zamówienia!';
			if (editedOrderId &&
				location.pathname !== `${process.env.PUBLIC_URL}/new-order` &&
				!window.confirm(msg)) {
				return false;
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCancelModeOn]);

	useEffect((): any => {
		if (editedOrderId) {
			window.onbeforeunload = (event: Event) => {
				const e = event || window.event;
				e.preventDefault();
				return '';
			};

			setStep(2);
			store.dispatch(dataActions.setEdit(true));
			store.dispatch(dataActions.setCartFetching(true));
			getDetailedOrder(editedOrderId).then(result => {
				store.dispatch(dataActions.setIsErrorGettingDetailedOrder(!result));
				fetchOrdersFromSessionStorage().then(() => {
					store.dispatch(dataActions.setCartFetching(false));
				});
			});
		} else if (copiedOrderId) {
			setStep(2);
			store.dispatch(dataActions.setCartFetching(true));
			getOrderById(copiedOrderId, true).then(result => {
				store.dispatch(dataActions.setCartFetching(false));
				isloadedCart.current = true;
				if (result === false) {
					store.dispatch(dataActions.setIsErrorGettingDetailedOrder(true));
				} else {
					store.dispatch(dataActions.setDetailedOrder(result));
					history.replace({
						pathname: `${process.env.PUBLIC_URL}/new-order`,
						state: {
							...(typeof history.location.state === 'object' ? history.location.state : {} as NewOrderState),
							copiedOrderId: null
						}
					});
				}
			});
		} else {
			store.dispatch(dataActions.setCartFetching(true));
			loadSessionCart().then(result => {
				if (result) {
					isloadedCart.current = true;

					fetchOrdersFromSessionStorage().then(() => {
						store.dispatch(dataActions.setCartFetching(false));
					});
				}
			});
		}

		// stop the listener when component unmounts
		return () => {
			window.onbeforeunload = null;
			unblock.current && unblock.current();
		}
		// eslint-disable-next-line
	}, []);

	// fetch guttering systems info (needed only in v1 version)
	useEffect(() => {
		if (gutterVersion === '1') {
			fetch(`${process.env.PUBLIC_URL}/rynny/rynny-config.json`).then(resp => {
				resp.json().then(rynnyConf => {
					const articlesPath = rynnyConf['articles'];
					fetch(`${process.env.PUBLIC_URL}/${articlesPath}`).then(resp => {
						resp.json().then(articles => store.dispatch(dataActions.setGutteringSystems(articles)));
					});
				});
			});
		} 
	}, [gutterVersion]);

	const fetchOrdersFromSessionStorage = useCallback(async () => {
		let assortment: ImportedProduct[] = [];
		if (ISOD_STORAGE_KEY in sessionStorage) {
			const parsed = JSON.parse(sessionStorage.getItem(ISOD_STORAGE_KEY)!) as ISODPackageData[];
			if (!parsed[0].scalKoszyk) {
				resetIsodCart();
			}
			_.forEach(parsed, (data: ISODPackageData) => {
				const isodAsortyment: ImportedProduct[] = _.map(data.asortyment, (a: ISODProduct) => {
					return {
						symKar: a.symkar,
						quantity: a.ilosc,
						dimension: parseFloat(a.dlugosc),
						unit: a.jm,
						additionsSelected: [data.folia],
					};
				});
				assortment.push(...isodAsortyment);
		});
			sessionStorage.removeItem(ISOD_STORAGE_KEY);
		};
		if (assortment.length > 0) {
			await getImportedProductsInfo(assortment, editedOrderId ? 'addIsodToEditLocal' : 'addIsodToLocal');
		}
		// eslint-disable-next-line
	}, []);

	const goToSummary = () => {
		setStep(2);
		!isDesktop && window.scrollTo(0, 0);
	}

	const completeAction = () => {
		store.dispatch(dataActions.resetOrdersList());
		unblock.current && unblock.current();
		history.push(`${process.env.PUBLIC_URL}/orders`);
		!isDesktop && window.scrollTo(0, 0);
		store.dispatch(dataActions.setEdit(false));
	}



	const assortmentSelection = <AssortmentSelection goToSummary={() => goToSummary()} cartReset={props.cartReset} setCartReset={props.setCartReset} loadedCart={isloadedCart} />;
	const summary = <NewOrderSummary isCancelModeOn={isCancelModeOn} setIsCancelModeOn={setIsCancelModeOn} goToAssortmentSelection={() => setStep(1)} goToSummary={() => setStep(2)} goToOrderForm={() => setStep(3)} detailedOrder={useSelector((state: AppState) => state.data.orders.detailedOrder)} completeAction={completeAction} />;
	return (
		<div className="NewOrder-container">
			<Notification />
			<div className='NewOrder-steps-and-edit-mode'
				style={isDesktop ? (
					step === 1 ? { paddingBottom: '4px' } :
						undefined) : step === 1 ? { paddingBottom: '4px' } : undefined}>
				{edit && !isFetching ?
					<div className={"NewOrder-edit-mode-sign" + (isDesktop ? " NewOrder-edit-mode-sign-desktop" : "")}>
						EDYCJA ZAMOWIENIA: <b>{detailedOrder.orderNumber}</b>
					</div> : <div></div>
				}
				<div className="NewOrder-steps">
					Krok {step} z {STEPS}
				</div>
			</div>
			{step === 1 ? assortmentSelection : summary}
		</div>
	);
}