import _ from "lodash";
import React, { useMemo } from "react";
import './BarChart.scss';

export interface BarChartProps {
	id: string;
	datas: {
		label: string;
		value: number;
	}[];
	orientation?: 'vertical' | 'horizontal';
	fill?: string;
}

const BarChart = ({
	id,
	datas,
	orientation = 'horizontal',
	fill = 'red'
}: BarChartProps) => {
	const maxValue: number = useMemo(() => {
		return datas.length === 0 ? 0 : _.maxBy(datas, d => d.value)!.value;
	}, [datas]);

	return <div id={id} className="BarChart" style={{ 
			flexDirection: orientation === 'vertical' ? 'column' : 'row', 
			width: '100%', 
			height: '100%' 
		}}>
		{
			_.map(datas, data => (
				<div key={`${id}-${data.label}`} className="BarChart-element" style={{ 
					height: orientation === 'horizontal' ? '100%' : 'auto',
					width: orientation === 'vertical' ? '100%' : 'auto',
					flexDirection: orientation === 'horizontal' ? 'column-reverse' : 'row'
				}}>
					<div className="BarChart-element-label" style={{ textAlign: orientation === 'vertical' ? 'left' : 'center' }}>{data.label}</div>
					{
						data.value > 0 && 
						<div className="BarChart-element-bar" style={{ 
							height: orientation === 'horizontal' ? ((data.value / maxValue) * 100) + '%' : 'auto', 
							width: orientation === 'vertical' ? ((data.value / maxValue) * 100) + '%' : 'auto', 
							writingMode: orientation === 'horizontal' ? 'vertical-lr' : 'unset',
							textAlign: orientation === 'vertical' ? 'right' : 'unset',
							padding: orientation === 'vertical' ? '0 5px' : '5px 0',
							background: fill, 
						}}>
								{data.value}
						</div>
					}
				</div>
			))
		}
	</div>
}

export default BarChart;