import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState, dataActions } from "reducers";
import Draggable from "react-draggable";
import './Order.scss';
import { Modal, Table } from "react-bootstrap";
import _ from "lodash";
import Spinner from "components/Spinner";
import { useMediaQuery } from "react-responsive";
import { LG } from "lib/util";

const OrderStatus = () => {
	const dispatch = useDispatch();

	const isDesktop = useMediaQuery({ minWidth: LG });

	const orderStatus = useSelector((state: AppState) => state.data.orders.orderStatus);
	const isFetching = useSelector((state: AppState) => state.data.orders.props.isOrderStatusFetching);

	const closePopup = () => {
		dispatch(dataActions.setOrderStatus({
			...orderStatus,
			show: false
		}));
	}

	return isDesktop ? <Draggable>
		<div className="OrderStatusPopup" style={ orderStatus.show ? {} : { display: 'none' } }>
			<div className="modal-header OrderStatusPopup-header">
				<p className="modal-title">Status realizacji zamówienia: { orderStatus.order.orderNumber }</p>
				<button className="close" onClick={closePopup}>
					<span aria-hidden="true">x</span>
					<span className="sr-only">Close</span>
				</button>
			</div>
			{
				isFetching ? <Spinner /> : 
				<div className="OrderStatusPopup-content">
					<Table className="Order-table OrderStatus-table" size="sm" borderless striped>
						<thead>
							<tr>
								<th style={{ width: '50px' }}>LP</th>
								<th>KARTOTEKA</th>
								<th style={{ width: '110px', textAlign: 'left' }}>STATUS</th>
								<th style={{ width: '110px', textAlign: 'left' }}>PRODUCENT</th>
							</tr>
						</thead>
						<tbody>
							{_.map(orderStatus.parts, (part, index) => {
								return <tr key={`${orderStatus.order.id}-${index}`}>
									<td>{index + 1}</td>
									<td>{part.kar}</td>
									<td>{part.status}</td>
									<td>{part.producer}</td>
								</tr>
							})}
						</tbody>
					</Table>
				</div>
			}
		</div>
	</Draggable> : <Modal show={orderStatus.show} onHide={closePopup}>
		<Modal.Header closeButton style={{ position: 'sticky', top: 0, zIndex: 100}}>
			<Modal.Title style={{ fontSize: '1rem' }}>
				<span>Status realizacji zamówienia: <br /> { orderStatus.order.orderNumber }</span>
			</Modal.Title>
		</Modal.Header>
		<Modal.Body style={{ padding: 0 }}>
			<div className="OrderStatus-body-mobile">
				{
					isFetching ? <Spinner centered={false} /> : _.map(orderStatus.parts, (part, index) => {
						return <div className="OrderStatus-body-mobile-part" key={`${orderStatus.order.id}-${index}`}>
							<h6>{ part.kar }</h6>
							<div>
								<span>Status:</span>
								<hr />
								<span>{ part.status }</span>
							</div>
							{(part.producer.length > 0) && <div>
								<span>Producent:</span>
								<hr />
								<span>{ part.producer }</span>
							</div>}
						</div>
					})
				}
			</div>
		</Modal.Body>
	</Modal>
}

export default OrderStatus;