import React from 'react';
import _ from 'lodash';
import { ProductAddition } from 'lib/types';
import { MdClose } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';
import { ProductInCart } from 'lib/types';
import { Form } from 'react-bootstrap';

interface AdditionalProductsProps {
	addition: ProductAddition;
	addIndex: number;
	change: () => void;
	product: ProductInCart;
	index: number;
	similarEdit: number | null;
}

const AdditionalProducts = (props: AdditionalProductsProps) => {
	return (
		<div key={'smiAdd' + props.addIndex}>
			<span>{props.addition.name}: </span>
			<hr />
			{_.some(props.product.product.additions, (add) => add.name === props.addition.name) && props.product.additionsSelected ? (
				props.similarEdit === props.index ? (
					<Form.Check
						name={props.addition.name}
						type="checkbox"
						defaultChecked={props.product.additionsSelected[props.addIndex]}
						disabled={props.product.product.additions[props.addIndex].default}
						onChange={props.change}
						onClick={(event: any) => event.stopPropagation()}
					/>
				) : props.product.additionsSelected[props.addIndex] ? (
					<FaCheck />
				) : (
					<MdClose />
				)
			) : (
				'N/A'
			)}
		</div>
	);
};


export default AdditionalProducts;
