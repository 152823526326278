import React, { useState } from 'react';
import { FormControl } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { useUser } from 'reducers';
import ButtonComponent from 'components/ButtonComponent';

interface EditableTextProps {
	value: string;
	onSubmit: (value: string) => void;
	editable?: boolean;
}

const EditableText = ({ value, onSubmit, editable }: EditableTextProps) => {
	const [currVal, setCurrVal] = useState(null as string | null);
	const user = useUser();
	const updateText = () => {
		if (currVal !== null) {
			if (currVal !== value) {
				onSubmit(currVal);
			}
			setCurrVal(null);
		}
	}
	return <div className="mx-3">
		{(editable ?? (user?.isAdmin ?? false)) &&
			<div className="text-right mb-2">
				{currVal === null
					? <ButtonComponent onClick={() => setCurrVal(value)}>Edytuj</ButtonComponent>
					: <>
						<ButtonComponent onClick={() => setCurrVal(null)}>Anuluj</ButtonComponent>
						<ButtonComponent onClick={() => updateText()}>Zapisz</ButtonComponent>
					</>
				}
			</div>
		}
		<div style={{ textAlign: "left" }}>
			{currVal === null
				? <ReactMarkdown source={value} escapeHtml={false} />
				: <FormControl as="textarea" rows={20} value={currVal} onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => setCurrVal(evt.target.value)} />
			}
		</div>
	</div>;
}

export default EditableText;