import React, { useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import './Admin.scss';
import { LinkItem } from "components";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Admins from "./Admins/Admins"
import ServiceWorks from "./ServiceWorks/ServiceWorks";
import TestsResults from "./TestsResults/TestsResults";
import { useUser } from "reducers";
import { useMediaQuery } from "react-responsive";
import { MD } from "lib/util";
import AssortmentGraphics from "./AssortmentGraphics/AssortmentGraphics";
import CacheManagement from "./CacheManagement/CacheManagement";
import Statistics from "./Statistics/Statistics";

export default function Admin() {
	const [activeKey, setActiveKey] = useState("admins");
	const location = useLocation();
	const isDesktop = useMediaQuery({ minWidth: MD });
	const history = useHistory();
	const user = useUser();

	useEffect(() => {
		if (location.pathname === `${process.env.PUBLIC_URL}/admin`) {
			history.replace(`${process.env.PUBLIC_URL}/admin/admins`);
		}
		if (location.pathname.includes(`${process.env.PUBLIC_URL}/admin/admins`)) {
			setActiveKey("admins")
		} else if (location.pathname.includes(`${process.env.PUBLIC_URL}/admin/service-works`)) {
			setActiveKey("service-works")
		} else if (location.pathname.includes(`${process.env.PUBLIC_URL}/admin/test-results`)) {
			setActiveKey("test-results")
		} else if (location.pathname.includes(`${process.env.PUBLIC_URL}/admin/assortment-graphics`)) {
			setActiveKey("assortment-graphics")
		} else if (location.pathname.includes(`${process.env.PUBLIC_URL}/admin/cache-management`)) {
			setActiveKey("cache-management")
		} else if (location.pathname.includes(`${process.env.PUBLIC_URL}/admin/statistics`)) {
			setActiveKey("statistics")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname])

	return <>
		{(user?.isAdmin && isDesktop) && <Navbar className="sub-navbar" expand="lg">
			<Nav
				justify
				variant="pills"
				className="flex-grow-1 flex-shrink-1"
				activeKey={activeKey}
			>
				<LinkItem to={`${process.env.PUBLIC_URL}/admin/admins`} eventKey="admins">Administratorzy</LinkItem>
				<LinkItem to={`${process.env.PUBLIC_URL}/admin/service-works`} eventKey="service-works">Prace serwisowe</LinkItem>
				<LinkItem to={`${process.env.PUBLIC_URL}/admin/test-results`} eventKey="test-results">Wyniki testów</LinkItem>
				<LinkItem to={`${process.env.PUBLIC_URL}/admin/assortment-graphics`} eventKey="assortment-graphics">Grafiki asortymentu</LinkItem>
				<LinkItem to={`${process.env.PUBLIC_URL}/admin/cache-management`} eventKey="cache-management">Cache</LinkItem>
				<LinkItem to={`${process.env.PUBLIC_URL}/admin/statistics`} eventKey="statistics">Statystyki</LinkItem>
			</Nav>
		</Navbar>}
		<Switch>
			<Route path={`${process.env.PUBLIC_URL}/admin/admins`}>
				{user?.isAdmin && <Admins />}
			</Route>
			<Route path={`${process.env.PUBLIC_URL}/admin/service-works`}>
				{user?.isAdmin && <ServiceWorks />}
			</Route>
			<Route path={`${process.env.PUBLIC_URL}/admin/test-results`}>
				{user?.isAdmin && <TestsResults />}
			</Route>
			<Route path={`${process.env.PUBLIC_URL}/admin/assortment-graphics`}>
				{user?.isAdmin && <AssortmentGraphics />}
			</Route>
			<Route path={`${process.env.PUBLIC_URL}/admin/cache-management`}>
				{user?.isAdmin && <CacheManagement />}
			</Route>
			<Route path={`${process.env.PUBLIC_URL}/admin/statistics`}>
				{user?.isAdmin && <Statistics />}
			</Route>
		</Switch>
	</>;
}
