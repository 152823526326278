import React, { useState, useEffect, useMemo, } from 'react';
import { Form, Button, Col, Row, FormLabel, Table, Card, Collapse, Modal } from 'react-bootstrap';
import { getBillings, getBillingPdf } from 'lib/communication/billing';
import Pagination from 'components/Pagination/Pagination';
import { Billing, OrderDirection, SummaryBilling } from 'lib/types';
import './Settlements.scss';
import SortButtons from 'components/SortButtons';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { SortButtonsValue } from '../../components/SortButtons/SortButtons';
import { getTodayDate, getLastMonthDate, LG } from 'lib/util';
import Spinner from 'components/Spinner/Spinner';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import pl from 'date-fns/locale/pl';

registerLocale('pl', pl);

type SettlementsState = {
	orderNumber: string;
	dateFrom: string;
	dateTo: string;
	orderBy: string;
	orderDirection: OrderDirection;
	pageSize: number;
}

const SETTLEMENT_STATE: SettlementsState = {
	orderNumber: '',
	dateFrom: '',
	dateTo: '',
	orderBy: 'paymentDeadline',
	orderDirection: OrderDirection.Desc,
	pageSize: 10,
}

type SettlementRow = {
	id: string;
	number: string;
	date: string;
	payment: string;
	paymentDeadline: string;
	owing: number;
	have: number;
	onBalance: number;
}


export default function Settlements() {

	const isDesktop = useMediaQuery({ minWidth: LG });

	const history = useHistory();
	const location = useLocation();

	const state: SettlementsState = location.state as SettlementsState || SETTLEMENT_STATE;

	const { page } = useParams<{ page: string | undefined }>();

	const settlementsIsFetching = useSelector((state: AppState) => state.data.settlements.props.settlementsIsFetching);
	const pdfIsFetching = useSelector((state: AppState) => state.data.settlements.props.pdfIsFetching);
	const settlements = useSelector((state: AppState) => state.data.settlements.settlements);

	const [error, setError] = useState(false);
	const [errorPdf, setErrorPdf] = useState(false);
	const [validated, setValidated] = useState(false);

	const [orderNumber, setOrderNumber] = useState('');
	const [dateFrom, setDateFrom] = useState<Date | null>(null);
	const [dateTo, setDateTo] = useState<Date | null>(null);
	const [orderBy, setOrderBy] = useState('paymentDeadline');
	const [orderDirection, setOrderDirection] = useState(OrderDirection.Desc);

	const [pageSize, setPageSize] = useState(10);
	const [opens, setOpens] = useState([false]);

	const [filteredSettlements, setFilteredSettlements] = useState([] as Billing[]);
	const [summary, setSummary] = useState({} as SummaryBilling);

	const pageNumber = parseInt(page ?? '1') - 1;
	const calculateNumberOfPagesForPageSize = (pageSize: number): number =>
		Math.max(1, _.ceil(_.size(filteredSettlements) / pageSize));
	const numberOfPages = calculateNumberOfPagesForPageSize(pageSize);
	const selectedSettlements = isDesktop
		? _.take(_.slice(filteredSettlements, pageNumber * pageSize), pageSize)
		: _.take(filteredSettlements, (pageNumber + 1) * pageSize);
	const opensArr = _.fill(Array(filteredSettlements.length), false, 0, filteredSettlements.length);

	const ref = React.createRef<HTMLDivElement>();

	const summaryColors: any = {
		sum7orLess: '#21EE00',
		sum8to14: '#67A4F2',
		sum15to21: '#FFD200',
		sum22orMore: '#FF0000',
	};
	
	const [maxOwing, setMaxOwing] = useState(0);
	const [maxHave, setMaxHave] = useState(0);
	const [maxOnBalance, setMaxOnBalance] = useState(0);

	const owingWidth = useMemo(() => {
		const d = document.createElement('div');
		d.style.fontSize = '14px';
		d.innerHTML = maxOwing.toFixed(2);
		d.classList.add('measurer');
		document.querySelector('.App-body')?.appendChild(d);
		return d.getBoundingClientRect().width;
	}, [maxOwing]);
	const haveWidth = useMemo(() => {
		const d = document.createElement('div');
		d.style.fontSize = '14px';
		d.innerHTML = maxHave.toFixed(2);
		d.classList.add('measurer');
		document.querySelector('.App-body')?.appendChild(d);
		return d.getBoundingClientRect().width;
	}, [maxHave]);
	const onBalanceWidth = useMemo(() => {
		const d = document.createElement('div');
		d.style.fontSize = '14px';
		d.innerHTML = maxOnBalance.toFixed(2);
		d.classList.add('measurer');
		document.querySelector('.App-body')?.appendChild(d);
		return d.getBoundingClientRect().width;
	}, [maxOnBalance]);
	
	useEffect(() => {
		if (selectedSettlements.length > 0) {
			setMaxOwing((_.maxBy(selectedSettlements, settlement => settlement.owing.toFixed(2).length) as SettlementRow).owing);
			setMaxHave((_.maxBy(selectedSettlements, settlement => settlement.have.toFixed(2).length) as SettlementRow).have);
			setMaxOnBalance((_.maxBy(selectedSettlements, settlement => settlement.onBalance.toFixed(2).length) as SettlementRow).onBalance);
		}
	}, [selectedSettlements]);

	useEffect(() => {
		state.dateTo = getTodayDate();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		getBillings().then(result => setError(!result));
	}, []);

	useEffect(() => {
		if (settlements && settlements.length > 0) {
			const sortedSettlements = _.sortBy(settlements, ({date}) => date);
			state.dateFrom = sortedSettlements[0].date;
		} else {
			state.dateFrom = getLastMonthDate();
		}
		// eslint-disable-next-line
	}, [settlements]);

	useEffect(() => {
		setOrderNumber(state.orderNumber);
		setDateFrom(state.dateFrom !== '' ? new Date(state.dateFrom) : null);
		setDateTo(state.dateTo !== '' ? new Date(state.dateTo) : null);
		setOrderBy(state.orderBy);
		setOrderDirection(state.orderDirection);
		setPageSize(state.pageSize);
		let filteredSettlements = _.map(settlements, (settlement) => {
			const lateDays = Math.floor((new Date().getTime() - new Date(settlement.paymentDeadline).getTime()) / (1000 * 60 * 60 * 24));
			const color = lateDays <= 7 ? '#21EE00' : lateDays < 15 && lateDays > 7 ? '#67A4F2' : lateDays >= 15 && lateDays <= 21 ? '#FFD200' : '#FF0000';
			return (settlement = { ...settlement, lateDays: lateDays < 0 ? 0 : lateDays, color: color, });
		});
		if (state.orderNumber) {
			filteredSettlements = _.filter(
				filteredSettlements,
				(settlement) => settlement.number.includes(state.orderNumber)
			);
		}
		if (state.dateFrom) {
			filteredSettlements = _.filter(
				filteredSettlements,
				(settlement) => new Date(settlement.date) >= new Date(state.dateFrom)
			);
		}
		if (state.dateTo) {
			filteredSettlements = _.filter(
				filteredSettlements,
				(settlement) => new Date(settlement.date) <= new Date(state.dateTo)
			);
		}
		filteredSettlements = _.orderBy(filteredSettlements, state.orderBy, state.orderDirection);
		setFilteredSettlements(filteredSettlements);
	}, [settlements, state.orderNumber, state.dateFrom, state.dateTo, state.orderBy, state.orderDirection, state.pageSize])

	useEffect(() => {
		let owing = 0, owing7orLess = 0, owing8to14 = 0, owing15to21 = 0, owing22orMore = 0;
		let have = 0, have7orLess = 0, have8to14 = 0, have15to21 = 0, have22orMore = 0;
		let onBalance = 0, onBalance7orLess = 0, onBalance8to14 = 0, onBalance15to21 = 0, onBalance22orMore = 0;
		for (const settlement of filteredSettlements) {
			owing += settlement.owing;
			have += settlement.have;
			onBalance += settlement.onBalance;
			if (settlement.lateDays! < 8) {
				owing7orLess += settlement.owing;
				have7orLess += settlement.have;
				onBalance7orLess += settlement.onBalance;
			} else if (settlement.lateDays! < 15) {
				owing8to14 += settlement.owing;
				have8to14 += settlement.have;
				onBalance8to14 += settlement.onBalance;
			} else if (settlement.lateDays! < 22) {
				owing15to21 += settlement.owing;
				have15to21 += settlement.have;
				onBalance15to21 += settlement.onBalance;
			} else {
				owing22orMore += settlement.owing;
				have22orMore += settlement.have;
				onBalance22orMore += settlement.onBalance;
			}
		}
		const summary: SummaryBilling = {
			sum7orLess: { owing: owing7orLess, have: have7orLess, onBalance: onBalance7orLess },
			sum8to14: { owing: owing8to14, have: have8to14, onBalance: onBalance8to14 },
			sum15to21: { owing: owing15to21, have: have15to21, onBalance: onBalance15to21 },
			sum22orMore: { owing: owing22orMore, have: have22orMore, onBalance: onBalance22orMore },
			sum: { owing, have, onBalance }
		}
		setSummary(summary);
	}, [filteredSettlements])

	_.map(summary, (summ, index) => {
		const colorMa = summ.have === null || summ.have === 0 ? '#000000' : summaryColors[index];
		const colorOwing = summ.owing === null || summ.owing === 0 ? '#000000' : summaryColors[index];
		const colorOnBalance = summ.onBalance === null || summ.onBalance === 0 ? '#000000' : summaryColors[index];
		summaryColors[index] = {
			ma: colorMa,
			owing: colorOwing,
			onBalance: colorOnBalance
		};
	});

	const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		ref?.current?.scrollIntoView({ behavior: 'smooth' });
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			setValidated(true);
			return;
		}
		let dateTo = form.to.value;
		if (form.from.value && !form.to.value) {
			dateTo = getTodayDate();
		}
		setOpens(_.fill(opensArr, false, 0, opensArr.length));
		history.push(`${process.env.PUBLIC_URL}/settlements`, {
			...state,
			orderNumber: orderNumber.trim(),
			dateFrom: form.from.value,
			dateTo: dateTo,
			orderBy,
			orderDirection
		});
	};

	const handleSortChange = (value: SortButtonsValue) => {
		if (value.group && value.direction) {
			history.push(`${process.env.PUBLIC_URL}/settlements`, {
				...state,
				orderBy: value.group,
				orderDirection: value.direction
			});
		}
	};

	const handlePdfDownload = () => {
		if (!pdfIsFetching) {
			getBillingPdf(state.orderNumber, state.dateFrom, state.dateTo, state.orderBy, state.orderDirection, state.pageSize, pageNumber)
				.then(result => setErrorPdf(!result))
		}
	};

	const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newPageSize = parseInt(event.currentTarget.value);
		const newNumberOfPages = calculateNumberOfPagesForPageSize(newPageSize);
		const pushedPageNumber = pageNumber + 1 > newNumberOfPages ? newNumberOfPages : pageNumber + 1;
		history.push(`${process.env.PUBLIC_URL}/settlements/${pushedPageNumber}`, {
			...state,
			pageSize: newPageSize
		});
	};

	if (settlementsIsFetching || error) {
		return <Spinner showError={error} />;
	}

	return (
		<div className="Settlements-block">
			<h2>Szukaj rozliczenia</h2>
			<Form onSubmit={handleSearchSubmit} noValidate validated={validated} className="Settlements-form">
				<Form.Row>
					<Form.Group className="flex-end" style={isDesktop ? {} : { flexBasis: 'auto' }} as={Col}>
						<Form.Label>SYMBOL ROZLICZENIA</Form.Label>
						<Form.Control type="text" id="orderNumber" value={orderNumber}
							onChange={e => setOrderNumber(e.target.value)} />
					</Form.Group>
					<Form.Group className="flex-end" style={isDesktop ? {} : { flexBasis: 'auto' }} as={Col}>
						<Form.Label>DATA OD</Form.Label>
						<DatePicker 
							id="from"
							locale="pl"
							selected={dateFrom}
							onChange={(date) => (setDateFrom(date))}
							dateFormat="yyyy-MM-dd"
							className="date-picker"
						/>
					</Form.Group>
					<Form.Group className="flex-end" style={isDesktop ? {} : { flexBasis: 'auto' }} as={Col}>
						<Form.Label>DATA DO</Form.Label>						
						<DatePicker 
							id="to"
							locale="pl"
							selected={dateTo}
							onChange={(date) => (setDateTo(date))}
							dateFormat="yyyy-MM-dd"
							className="date-picker"
						/>
					</Form.Group>
					<Col xs="auto"
						style={isDesktop ? { display: 'flex', alignItems: 'flex-end', marginBottom: '17px' } : { marginTop: '10px', width: '100%' }}>
						<Button style={isDesktop ? {} : { width: '100%' }} type="submit" variant="dark">
							Szukaj
						</Button>
					</Col>
				</Form.Row>
			</Form >
			{isDesktop && (
				<>
					<Row className="justify-content-end align-items-center mb-1">
						<FormLabel column xs="auto" htmlFor="rowCount" className="pr-0">
							Ilość na stronie
						</FormLabel>
						<Col xs="auto">
							<Form.Control
								as="select"
								size="sm"
								id="rowCount"
								value={pageSize}
								onChange={handleChangePageSize}
							>
								<option value={10}>10</option>
								<option value={20}>20</option>
								<option value={50}>50</option>
								<option value={100}>100</option>
							</Form.Control>
						</Col>
						<div className="d-flex justify-content-end">
							<Pagination
								size={numberOfPages}
								href={(page) => `${process.env.PUBLIC_URL}/settlements/${page + 1}`}
								onSelect={(page) => history.push(`${process.env.PUBLIC_URL}/settlements/${page + 1}`, state)}
								selected={pageNumber}
							/>
						</div>
					</Row>
				</>
			)
			}
			<div className="SettlementList-block">
				<div className="SettlementList-summary">
					<Card>
						<Card.Header className="p-2">
							<Card.Title>Podsumowanie</Card.Title>
						</Card.Header>
						<Card.Body className="p-2">
							<table className="tr2 tr3 SettlementList-summary-table">
								<thead>
									<tr>
										<th>Dni spóźnienia</th>
										<th>Winien</th>
										<th>Ma</th>
										<th>Per saldo</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>0-7</td>
										<td style={{ color: summaryColors.sum7orLess.owing }}>
											{summary.sum7orLess ? (summary.sum7orLess.owing || 0).toFixed(2) : 0.00}
										</td>
										<td style={{ color: summaryColors.sum7orLess.ma }}>
											{summary.sum7orLess ? (summary.sum7orLess.have || 0).toFixed(2) : 0.00}
										</td>
										<td style={{ color: summaryColors.sum7orLess.onBalance }}>
											{summary.sum7orLess ? (summary.sum7orLess.onBalance || 0).toFixed(2) : 0.00}
										</td>
									</tr>
									<tr>
										<td>8-14</td>
										<td style={{ color: summaryColors.sum8to14.owing }}>
											{summary.sum8to14 ? (summary.sum8to14.owing || 0).toFixed(2) : 0.00}
										</td>
										<td style={{ color: summaryColors.sum8to14.ma }}>
											{summary.sum8to14 ? (summary.sum8to14.have || 0).toFixed(2) : 0.00}
										</td>
										<td style={{ color: summaryColors.sum8to14.onBalance }}>
											{summary.sum8to14 ? (summary.sum8to14.onBalance || 0).toFixed(2) : 0.00}
										</td>
									</tr>
									<tr>
										<td>15-21</td>
										<td style={{ color: summaryColors.sum15to21.owing }}>
											{summary.sum15to21 ? (summary.sum15to21.owing || 0).toFixed(2) : 0.00}
										</td>
										<td style={{ color: summaryColors.sum15to21.ma }}>
											{summary.sum15to21 ? (summary.sum15to21.have || 0).toFixed(2) : 0.00}
										</td>
										<td style={{ color: summaryColors.sum15to21.onBalance }}>
											{summary.sum15to21 ? (summary.sum15to21.onBalance || 0).toFixed(2) : 0.00}
										</td>
									</tr>
									<tr>
										<td>powyżej 21</td>
										<td style={{ color: summaryColors.sum22orMore.owing }}>
											{summary.sum22orMore ? (summary.sum22orMore.owing || 0).toFixed(2) : 0.00}
										</td>
										<td style={{ color: summaryColors.sum22orMore.ma }}>
											{summary.sum22orMore ? (summary.sum22orMore.have || 0).toFixed(2) : 0.00}
										</td>
										<td style={{ color: summaryColors.sum22orMore.onBalance }}>
											{summary.sum22orMore ? (summary.sum22orMore.onBalance || 0).toFixed(2) : 0.00}
										</td>
									</tr>
									<tr>
										<td>w sumie</td>
										<td>{summary.sum ? (summary.sum.owing || 0).toFixed(2) : 0.00}</td>
										<td>{summary.sum ? (summary.sum.have || 0).toFixed(2) : 0.00}</td>
										<td>{summary.sum ? (summary.sum.onBalance || 0).toFixed(2) : 0.00}</td>
									</tr>
								</tbody>
							</table>
						</Card.Body>
					</Card>
					<p style={{ margin: '25px auto' }}>
						Pobierz listę w formacie{' '}
						<span onClick={() => handlePdfDownload()} style={{ color: '#FF0000', cursor: 'pointer' }}>PDF</span>
					</p>
					<Modal show={errorPdf || pdfIsFetching} onHide={() => setErrorPdf(false)} centered className="pdf-modal">
						<Modal.Header closeButton={errorPdf} className="pdf-modal-header" />
						<Modal.Body className="pdf-modal-body">
							<Spinner showError={errorPdf} toPdf={true} />
						</Modal.Body>
						<Modal.Footer className="pdf-modal-footer">
							{!pdfIsFetching && (
								<Button variant="dark" onClick={() => setErrorPdf(false)}>
									Zamknij
								</Button>
							)}
						</Modal.Footer>
					</Modal>
				</div>
				{isDesktop ? (
					<div className="SettlementList-main-table">
						<Table size="sm" borderless striped>
							<thead>
								<tr>
									<th className="cell">
										<div className="cell-content">
											<div>Symbol</div>
											<SortButtons
												value={{ direction: orderDirection, group: 'number' }}
												onChange={handleSortChange}
												orderBy={orderBy}
											/>
										</div>
									</th>
									<th className="cell">
										<div className="cell-content">
											<div>Data</div>
											<SortButtons
												value={{ direction: orderDirection, group: 'date' }}
												onChange={handleSortChange}
												orderBy={orderBy}
											/>
										</div>
									</th>
									<th className="cell">Rodzaj płatności</th>
									<th className="cell">
										<div className="cell-content">
											<div>Termin płatności</div>
											<SortButtons
												value={{ direction: orderDirection, group: 'paymentDeadline' }}
												onChange={handleSortChange}
												orderBy={orderBy}
											/>
										</div>
									</th>
									<th className="cell">
										<div className="cell-content">
											<div>Dni spóźnienia</div>
											<SortButtons
												value={{ direction: orderDirection, group: 'lateDays' }}
												onChange={handleSortChange}
												orderBy={orderBy}
											/>
										</div>
									</th>
									<th className="cell"><div className="cell-content">Winien</div></th>
									<th className="cell"><div className="cell-content">Ma</div></th>
									<th className="cell"><div className="cell-content">Per saldo</div></th>
								</tr>
							</thead>
							{_.isEmpty(selectedSettlements) ? (
								<tbody>
									<tr>
										<td colSpan={7}>Brak wyników wyszukiwania.</td>
									</tr>
								</tbody>
							) : (
								<tbody>
									{_.map(selectedSettlements, (settlement, index) => {
										return (
											<tr key={index}>
												<td className="cell">{settlement.number}</td>
												<td className="cell">{settlement.date}</td>
												<td className="cell">{settlement.payment}</td>
												<td className="cell">{settlement.paymentDeadline}</td>
												<td className="cell" style={{ color: settlement.color }}>
													{settlement.lateDays}
												</td>
												<td className="cell"><div className="price-td-div" style={{ width: owingWidth }}>{settlement.owing.toFixed(2)}</div></td>
												<td className="cell"><div className="price-td-div" style={{ width: haveWidth }}>{settlement.have.toFixed(2)}</div></td>
												<td className="cell"><div className="price-td-div" style={{ width: onBalanceWidth }}>{settlement.onBalance.toFixed(2)}</div></td>
											</tr>
										);
									})}
								</tbody>
							)}
						</Table>
					</div>
				) : (
					<>
						<div ref={ref} style={{ width: '100%', marginBottom: '25px' }}>
							<div>
								<span>Sortuj:</span>
								<select
									style={{ margin: 'auto', marginBottom: '15px', width: '90%' }}
									id="sorting"
									onChange={(event) => {
										const orderBy = event.currentTarget.value.split('-')[0];
										const orderDirection = event.currentTarget.value.split('-')[1] === 'asc'
											? OrderDirection.Asc
											: OrderDirection.Desc;
										setOpens(_.fill(opensArr, false, 0, opensArr.length));
										history.push(`${process.env.PUBLIC_URL}/settlements`, {
											...state,
											orderBy: orderBy,
											orderDirection: orderDirection
										});
									}}
								>
									<option value="number-desc">Po symbolu malejąco</option>
									<option value="number-asc">Po symbolu rosnąco</option>
									<option value="date-desc">Po dacie malejąco</option>
									<option value="date-asc">Po dacie rosnąco</option>
									<option value="paymentDeadline-desc">Po terminie płatności malejąco</option>
									<option value="paymentDeadline-asc">Po terminie płatności rosnąco</option>
									<option value="lateDays-desc">Po dniach spóźnienia malejąco</option>
									<option value="lateDays-asc">Po dniach spóźnienia rosnąco</option>
								</select>
							</div>
							{_.isEmpty(selectedSettlements) ? (
								<div>
									<b>Brak wyników wyszukiwania.</b>
								</div>
							) : (
								<>
									{_.map(selectedSettlements, (settlement, index) => (
										<div
											key={index}
											className={'block-item' + (opens[index] ? ' rotate' : '')}
											onClick={() => {
												const arr = opens.splice(0);
												arr[index] = !arr[index];
												setOpens(arr);
											}}
										>
											<div>
												<span>Symbol:</span>
												<hr />
												<b>{settlement.number}</b>
											</div>
											<div>
												<span>Data:</span>
												<hr />
												<b>{settlement.date}</b>
											</div>
											<Collapse in={opens[index]}>
												<section>
													<div>
														<span>Rodzaj płatności:</span>
														<hr />
														<b>{settlement.payment}</b>
													</div>
													<div>
														<span>Termin płatności:</span>
														<hr />
														<b>{settlement.paymentDeadline}</b>
													</div>
													<div>
														<span>Dni spóźnienia:</span>
														<hr />
														<b style={{ color: settlement.color }}>{settlement.lateDays}</b>
													</div>
													<div>
														<span>Winien:</span>
														<hr />
														<b>{settlement.owing.toFixed(2)}</b>
													</div>
													<div>
														<span>Ma:</span>
														<hr />
														<b>{settlement.have.toFixed(2)}</b>
													</div>
													<div>
														<span>Per saldo:</span>
														<hr />
														<b>{settlement.onBalance.toFixed(2)}</b>
													</div>
												</section>
											</Collapse>
										</div>
									))}
								</>
							)}
							{!isDesktop && !_.isEmpty(settlements) && (
								<Button
									style={{ marginTop: 10 }}
									onClick={() => history.push(`${process.env.PUBLIC_URL}/settlements/${+(page ? page : 1) + 1}`, state)}
									disabled={(pageNumber + 1) * pageSize > _.size(settlements)}
								>
									Pokaż więcej
								</Button>
							)}
						</div>
					</>
				)}
			</div >
		</div>
	);
}
