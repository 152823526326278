import React, { useState, useEffect } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import './UserRegistrationForm.scss';
import { UserRegistrationInfo } from 'reducers';
import Select from 'react-select';
import treasuryOfficeListJson from './urzedy.json';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { LG } from 'lib/util';

export type UserType = 'roofer' | 'trader';

interface UserRegistrationProps {
	userType: UserType;
	submitText?: string;
	onSubmit?: (info: UserRegistrationInfo) => void;
	userInfo?: UserRegistrationInfo;
	invalidEmail?: boolean;
}

interface UserRegistrationElements extends HTMLFormControlsCollection {
	firstName: HTMLInputElement;
	lastName: HTMLInputElement;
	company: HTMLInputElement;
	street: HTMLInputElement;
	streetNumber: HTMLInputElement;
	flatNumber?: HTMLInputElement;
	postalCode: HTMLInputElement;
	city: HTMLInputElement;
	nip?: HTMLInputElement;
	regon?: HTMLInputElement;
	treasuryOffice?: HTMLInputElement;
	pesel?: HTMLInputElement;
	phone?: HTMLInputElement;
	email: HTMLInputElement;
}

const UserRegistrationForm = ({
	userType,
	submitText = 'Zarejestruj',
	onSubmit = undefined,
	userInfo = undefined,
	invalidEmail = false,
}: UserRegistrationProps) => {
	const isDesktop = useMediaQuery({ minWidth: LG });

	const [validated, setValidated] = useState(false);
	const [requireRegonNip, setRequireRegonNip] = useState(true);
	type OptionType = { label: string; value: string };
	const [treasuryOffice, setTreasuryOffice] = useState({ label: userInfo?.user.treasuryOffice || '', value: '' });
	const [nip, setNip] = useState('');
	const [regon, setRegon] = useState('');
	const [pesel, setPesel] = useState('');
	const [showColor, setShowColor] = useState(false);
	const [isValid, setIsValid] = useState(true);
	const treasuryOfficeLabel = userInfo?.user.treasuryOffice;
	const selectTheme = (showColor && treasuryOffice.label === '') ? 'red' : (showColor && treasuryOffice.label !== '' ? 'green' : '#ced4da');
	const treasuryOfficeList: OptionType[] = _.map(treasuryOfficeListJson, (elem, index) => {
		return {
			label: elem,
			value: index.toString(),
		}
	})

	useEffect(() => {
		setTreasuryOffice({ label: treasuryOfficeLabel || '', value: '' })
	}, [treasuryOfficeLabel])

	useEffect(() => {
		if (nip === '' && regon === '') {
			setRequireRegonNip(false);
		}
		if (treasuryOffice.label === '' && pesel === '') {
			setRequireRegonNip(true);
		}
		setIsValid(nip === '' && regon === '' && treasuryOffice.label === '' && pesel === '' && userType === 'trader');
	}, [treasuryOffice, nip, regon, pesel, userType]);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		setShowColor(true);
		event.preventDefault();
		event.stopPropagation();

		const form = event.currentTarget;
		if (form.checkValidity() === false || (requireRegonNip === false && treasuryOffice.label === '')) {
			setValidated(true);

			return;
		}

		setValidated(false);

		const elements = form.elements as UserRegistrationElements;
		const registrationInfo: UserRegistrationInfo = {
			user: {
				firstName: elements.firstName.value,
				lastName: elements.lastName.value,
				nip: elements?.nip?.value,
				treasuryOffice: treasuryOffice.label,
				pesel: elements?.pesel?.value,
				phone: elements?.phone?.value,
				email: elements.email.value,
				street: elements.street.value,
				buildingNo: elements.streetNumber.value,
				apartmentNo: elements?.flatNumber?.value,
				postalCode: elements.postalCode.value,
				town: elements.city.value,
			},
			company: {
				name: elements.company.value,
				regon: elements?.regon?.value,
			},
		};

		onSubmit && onSubmit(registrationInfo);
	};

	const filterOption = (option: OptionType, rawInput: string) => {
		const words = rawInput.split(' ');
		return words.reduce(
			(acc, cur) => acc && option.label.toLowerCase().includes(cur.toLowerCase()),
			true,
		);
	};

	return (
		<Form className="mx-3" onSubmit={handleSubmit} noValidate validated={validated}>
			<Form.Row>
				<Form.Group as={Col} controlId="firstName">
					<Form.Label>Imię</Form.Label>
					<Form.Control type="text" required defaultValue={userInfo?.user?.firstName} isValid={invalidEmail} />
					<Form.Control.Feedback type="invalid">Podaj imię.</Form.Control.Feedback>
				</Form.Group>
				<Form.Group as={Col} controlId="lastName">
					<Form.Label>Nazwisko</Form.Label>
					<Form.Control type="text" required defaultValue={userInfo?.user?.lastName} isValid={invalidEmail} />
					<Form.Control.Feedback type="invalid">Podaj nazwisko.</Form.Control.Feedback>
				</Form.Group>
			</Form.Row>
			<Form.Row>
				<Form.Group as={Col} controlId="company">
					<Form.Label>Nazwa firmy</Form.Label>
					<Form.Control type="text" required defaultValue={userInfo?.company?.name} isValid={invalidEmail} />
					<Form.Control.Feedback type="invalid">Podaj nazwę firmy.</Form.Control.Feedback>
				</Form.Group>
			</Form.Row>
			<Form.Row>
				<Form.Group className="mb-auto" style={isDesktop ? {} : { maxWidth: "50%" }} as={Col} xs={6} controlId="street">
					<Form.Label>Ulica</Form.Label>
					<Form.Control type="text" required defaultValue={userInfo?.user?.street} isValid={invalidEmail} />
					<Form.Control.Feedback type="invalid" >Podaj ulicę.</Form.Control.Feedback>
				</Form.Group>
				<Form.Group as={Col} controlId="streetNumber" className="mb-auto" style={isDesktop ? {} : { maxWidth: "25%" }}>
					<Form.Label style={isDesktop ? {} : { whiteSpace: "nowrap", marginLeft: "-5px" }}>{isDesktop ? "Numer budynku" : "Nr budynku"}</Form.Label>
					<Form.Control type="text" pattern="[0-9]+[a-zA-Z]*" required defaultValue={userInfo?.user?.buildingNo} isValid={invalidEmail} />
					<Form.Control.Feedback type="invalid">Podaj numer budynku.</Form.Control.Feedback>
				</Form.Group>
				<Form.Group as={Col} controlId="flatNumber" className="mb-auto" style={isDesktop ? {} : { maxWidth: "25%" }}>
					<Form.Label style={isDesktop ? {} : { whiteSpace: "nowrap" }}>{isDesktop ? "Numer lokalu" : "Nr lokalu"}</Form.Label>
					<Form.Control type="text" pattern="[0-9]*" defaultValue={userInfo?.user?.apartmentNo} isValid={invalidEmail} />
				</Form.Group>
			</Form.Row>
			<Form.Row>
				<Form.Group as={Col} controlId="postalCode">
					<Form.Label>Kod pocztowy</Form.Label>
					<Form.Control
						type="text"
						required
						pattern="[0-9]{2}-[0-9]{3}"
						defaultValue={userInfo?.user?.postalCode}
						isValid={invalidEmail}
					/>
					<Form.Control.Feedback type="invalid">Podaj kod pocztowy.</Form.Control.Feedback>
				</Form.Group>
				<Form.Group as={Col} controlId="city">
					<Form.Label>Miejscowość</Form.Label>
					<Form.Control type="text" required defaultValue={userInfo?.user?.town} isValid={invalidEmail} />
					<Form.Control.Feedback type="invalid">Podaj miejscowość.</Form.Control.Feedback>
				</Form.Group>
			</Form.Row>
			{/* {(userType === 'trader' && showColor && (requireRegonNip || requirePeselOffice)) && <p>Podaj NIP i REGON, lub PESEL i urząd skarbowy</p>} */}
			{userType === 'roofer' && <Form.Row>
				<Form.Group as={Col} controlId="nip">
					<Form.Label>NIP</Form.Label>
					<Form.Control
						className={(!isValid || invalidEmail) ? "form-control" : "form-control-default"}
						required={requireRegonNip}
						type="text"
						pattern="[0-9]{10}"
						defaultValue={userInfo?.user?.nip !== 'null' ? userInfo?.user?.nip : ''}
						onChange={e => setNip(e.target.value)}
						isValid={invalidEmail}
					/>
					<Form.Control.Feedback type="invalid">{!isValid && "Podaj poprawny numer NIP."}</Form.Control.Feedback>
				</Form.Group>
				<Form.Group as={Col} controlId="regon">
					<Form.Label>REGON</Form.Label>
					<Form.Control
						className={(!isValid || invalidEmail) ? "form-control" : "form-control-default"}
						type="text"
						required={requireRegonNip}
						pattern="[0-9]{9}([0-9]{5})?"
						defaultValue={userInfo?.company?.regon !== 'null' ? userInfo?.company?.regon : ''}
						onChange={e => setRegon(e.target.value)}
						isValid={invalidEmail}
					/>
					<Form.Control.Feedback type="invalid">{!isValid && "Podaj poprawny numer REGON."}</Form.Control.Feedback>
				</Form.Group>
			</Form.Row>}
			{userType === 'trader' && ( isDesktop ? (
				<Form.Row>
					<Form.Group as={Col} controlId="pesel">
						<Form.Label>PESEL</Form.Label>
						<Form.Control
							type="text"
							pattern="[0-9]{11}"
							defaultValue={userInfo?.user?.pesel !== 'null' ? userInfo?.user?.pesel : ''}
							onChange={e => setPesel(e.target.value)}
							isValid={invalidEmail}
							required
						/>
						<Form.Control.Feedback type="invalid">Podaj poprawny numer PESEL.</Form.Control.Feedback>
					</Form.Group>
					<Form.Group as={Col} controlId="treasuryOffice">
						<Form.Label>Urząd skarbowy</Form.Label>
						<Select
							isClearable
							value={treasuryOffice as unknown as OptionType || { label: '', value: '' }}
							options={treasuryOfficeList}
							filterOption={filterOption}
							onChange={(selectedOption) => {
								selectedOption === null ?
									setTreasuryOffice({ label: '', value: '' })
									:
									setTreasuryOffice((selectedOption as OptionType))
							}}
							theme={theme => ({
								...theme,
								colors: {
									...theme.colors,
									primary: 'rgb(173, 210, 255)',
									neutral20: selectTheme,
									neutral30: selectTheme,
								},
							})}
						/>
					</Form.Group>
				</Form.Row>
			) : (
				<Form.Row>
					<Form.Group as={Col} controlId="pesel">
						<Form.Label>PESEL</Form.Label>
						<Form.Control
							type="text"
							pattern="[0-9]{11}"
							defaultValue={userInfo?.user?.pesel !== 'null' ? userInfo?.user?.pesel : ''}
							onChange={e => setPesel(e.target.value)}
							isValid={invalidEmail}
							required
						/>
						<Form.Control.Feedback type="invalid">Podaj poprawny numer PESEL.</Form.Control.Feedback>
					</Form.Group>
					<Form.Group as={Col} controlId="phone">
						<Form.Label>Numer telefonu</Form.Label>
						<Form.Control type="tel" required pattern="[0-9]{9}" defaultValue={userInfo?.user?.phone !== 'null' ? userInfo?.user?.phone : ''} isValid={invalidEmail} />
						<Form.Control.Feedback type="invalid">Podaj poprawny numer telefonu.</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>				
			))}
			{isDesktop ? (
				<Form.Row>
					<Form.Group as={Col} controlId="phone">
						<Form.Label>Numer telefonu</Form.Label>
						<Form.Control type="tel" required pattern="[0-9]{9}" defaultValue={userInfo?.user?.phone !== 'null' ? userInfo?.user?.phone : ''} isValid={invalidEmail} />
						<Form.Control.Feedback type="invalid">Podaj poprawny numer telefonu.</Form.Control.Feedback>
					</Form.Group>
					<Form.Group as={Col} controlId="email">
						<Form.Label>Adres email</Form.Label>
						<Form.Control
							type="email"
							required
							isInvalid={!validated && invalidEmail}
							defaultValue={userInfo?.user?.email} />
						<Form.Control.Feedback type="invalid">Podaj poprawny adres email.</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
			) : ( userType === "trader" ? (
				<div>
					<Form.Row>
						<Form.Group as={Col} controlId="treasuryOffice">
							<Form.Label>Urząd skarbowy</Form.Label>
							<Select	
								isClearable
								value={treasuryOffice as unknown as OptionType || { label: '', value: '' }}
								options={treasuryOfficeList}
								filterOption={filterOption}
								onChange={(selectedOption) => {
									selectedOption === null ?
										setTreasuryOffice({ label: '', value: '' })
										:
										setTreasuryOffice((selectedOption as OptionType))
								}}
								theme={theme => ({
									...theme,
									colors: {
										...theme.colors,
										primary: 'rgb(173, 210, 255)',
										neutral20: selectTheme,
										neutral30: selectTheme,
									},
								})}
							/>
						</Form.Group>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col} controlId="email">
							<Form.Label>Adres email</Form.Label>
							<Form.Control
								type="email"
								required
								isInvalid={!validated && invalidEmail}
								defaultValue={userInfo?.user?.email} />
							<Form.Control.Feedback type="invalid">Podaj poprawny adres email.</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
				</div>
			) : (
				<Form.Row>
					<Form.Group as={Col} controlId="phone">
						<Form.Label>Numer telefonu</Form.Label>
						<Form.Control type="tel" required pattern="[0-9]{9}" defaultValue={userInfo?.user?.phone !== 'null' ? userInfo?.user?.phone : ''} isValid={invalidEmail} />
						<Form.Control.Feedback type="invalid">Podaj poprawny numer telefonu.</Form.Control.Feedback>
					</Form.Group>
					<Form.Group as={Col} controlId="email">
						<Form.Label>Adres email</Form.Label>
						<Form.Control
							type="email"
							required
							isInvalid={!validated && invalidEmail}
							defaultValue={userInfo?.user?.email} />
						<Form.Control.Feedback type="invalid">Podaj poprawny adres email.</Form.Control.Feedback>
					</Form.Group>
				</Form.Row>
			))}			
			<Form.Row>
				<Col>
					<Button type="submit">{submitText}</Button>
				</Col>
			</Form.Row>
		</Form>
	);
};

export default UserRegistrationForm;
