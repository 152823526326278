import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './GutterCreator.scss';
import store from 'store';
import { AppState } from 'reducers';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { getImportedProductsInfo, resetGutteringsCart } from 'lib/communication/orders';
import { GutteringSystemData, ImportedProduct, GutteringSystemDataV2 } from 'lib/types';
import { IDENTIFIER } from './GutterCreator';
import { showCartMessage } from 'containers/NewOrder/AssortmentSelection/AssortmentSelection';
import { setGutterCreatorFilter, setGuttertoBeUpdated } from 'lib/communication/gutterCreator';

export interface ImportedDataV2 {
	resetGutteringsCart: false;
	filters: GutteringSystemDataV2;
	order: {
		SymKar: string;
		count: number;
	}[]
}

enum GutterMessageTypes {
	Loaded = 'GUTTER_LOADED',
    Resize = 'GUTTER_RESIZE',
    UpdateOrder = 'GUTTER_UPDATE_ORDER',
    Submit = 'GUTTER_SUBMIT_KREATOR',
	Filter = 'GUTTER_FILTER'
}

interface GutterMessage {
	type: string;
	content?: any;
}

const GutterCreatorV2 = React.memo(() => {

	const edit = useSelector((state: AppState) => state.data.orders.edit);
	const localCart = useSelector(
		(state: AppState) => edit ? state.data.orders.localEditCart : state.data.orders.localCart
	);

	const GutterCreatorURL = useSelector((state: AppState) => state.conf.GutterCreatorURL);
	const gutterToBeUpdated = useSelector((state: AppState) => state.data.gutterCreator.props.toBeUpdated);

	const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null);
	const [isGutteringsDownloaded, setIsGutteringsDownloaded] = useState(false);
	const [contentHeight, setContentHeight] = useState('500px');
	const [gutterFilters, setGutterFilters] = useState<GutteringSystemDataV2>(store.getState().data.gutterCreator.props.filters);
	
	useEffect(() => {
		if(gutterToBeUpdated){
			setGutterFilters(store.getState().data.gutterCreator.props.filters);
			setGuttertoBeUpdated(false);
			setIsGutteringsDownloaded(false);
		}
	},[gutterToBeUpdated])

	const GutterCreatorDomain = useMemo(() => {
		return GutterCreatorURL.split('/').slice(0, 3).join('/');
	}, [GutterCreatorURL]);

	const gutterMessageHandler = useCallback((event: MessageEvent) => {
		event.preventDefault();
		event.stopPropagation();
		
		if (event.origin !== GutterCreatorDomain) {
			return;
		}

		const {type, content} = event.data as GutterMessage;

		switch(type) {
			case GutterMessageTypes.Loaded:
				setIsGutteringsDownloaded(true);
				break;
			case GutterMessageTypes.Resize:
				setContentHeight(content);
				break;
			case GutterMessageTypes.Submit:
				submitKreator(content);
				break;
			case GutterMessageTypes.Filter:
				setGutterCreatorFilter(content);
				break;
			default:
				break;
		}
	}, [GutterCreatorDomain]);
	
	useEffect(() => {
		if (isGutteringsDownloaded && contentRef && contentRef.contentWindow) {
			const order = JSON.stringify(
				localCart.gutterings.map((g) => {
					return {
						product_id: g.product.symKar,
						product_qty: g.quantity,
						systemData: g.systemData,
					};
				})
			);
			const msg: GutterMessage = {
				type: GutterMessageTypes.UpdateOrder,
				content: order
			}

			contentRef.contentWindow.postMessage(msg, GutterCreatorDomain);
		}
	}, [isGutteringsDownloaded, localCart, contentRef, GutterCreatorDomain]);

	useEffect(() => {
		window.addEventListener('message', gutterMessageHandler, false);

		return () => {window.removeEventListener('message', gutterMessageHandler, false);}
	}, [GutterCreatorURL, GutterCreatorDomain, gutterMessageHandler]);

	return (
		<div>
			<iframe 
				title={IDENTIFIER} 
				style={{
					width: '100%',
					height: contentHeight,
					border: 'none'
				}} 
				ref={setContentRef} 
				src={`${GutterCreatorURL}?mode=ORDER_MODE&origin=${window.origin}`
					+ `&gutter-shape=${gutterFilters.gutterShape}`
					+ `&material=${gutterFilters.material}`
					+ `&grille=${gutterFilters.grille}`
					+ `&tube-shape=${gutterFilters.tubeShape}`
					+ `&size=${gutterFilters.size}`
					+ `&color-name=${gutterFilters.colorName}`
			}
			/>
		</div>
	);
});

function submitKreator(massage: ImportedDataV2): void {
	const edit = store.getState().data.orders.edit;

	const data: ImportedDataV2 = massage;
	const systemData = data.filters as GutteringSystemData;
	if (!data.resetGutteringsCart) {
		const products: ImportedProduct[] = _.map(data.order, (product) => ({
			symKar: product.SymKar,
			quantity: product.count,
			dimension: null,
			additionsSelected: null,
		}));
		getImportedProductsInfo(products, edit ? 'addGutteringsToEditLocal' : 'addGutteringsToLocal', systemData);
	} else {
		resetGutteringsCart(edit, systemData);
	}

	showCartMessage();
}

export default GutterCreatorV2;
