import './DeliveryAddressForm.scss';
import React, { useEffect, useState } from 'react';
import { Form, Modal, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { UserDeliveryAddress } from 'lib/types';
import { MAX_ADDRESS_STREET_NAME_LENGTH, MAX_ADDRESS_HOUSE_NUMBER_LENGTH,
	MAX_ADDRESS_APARTMENT_NUMBER_LENGTH, MAX_ADDRESS_TOWN_LENGTH, MAX_ADDRESS_DESC_LENGTH } from 'lib/util';
import ButtonComponent from 'components/ButtonComponent';
import { Countries, StreetTypes } from './DeliveryAddressData.json';

type CountryOptionType = typeof Countries[0];

function getCountry(country: string) {
	return Countries.find((val) => val.value === country) as CountryOptionType | undefined;
}

type StreetTypeOptionType = typeof StreetTypes[0];

function getStreetType(streetType: string) {
	return StreetTypes.find((val) => val.value === streetType) as StreetTypeOptionType | undefined;
}

type FormValidationError = "empty" | "pattern";

class FormValidationSummary {
	streetName: FormValidationError | null = null;
	houseNumber: FormValidationError | null = null;
	zipCode: FormValidationError | null = null;
	town: FormValidationError | null = null;
	country: FormValidationError | null = null;
	constructor(init?: Partial<FormValidationSummary>) {
        Object.assign(this, init);
    };
	anyError() {
		if (this.streetName || this.houseNumber || this.zipCode || this.town || this.country)
			return true;
		return false;
	};
}

interface DeliveryAddressFormModalProps {
	onCancel: () => void,
	onAccept: (address: UserDeliveryAddress) => void,
	show: boolean,
	editData?: UserDeliveryAddress
}

export default function DeliveryAddressFormModal(props: DeliveryAddressFormModalProps) {
	const [validated, setValidated] = useState<FormValidationSummary>(new FormValidationSummary());
	const [edit, setEdit] = useState<boolean>(props.editData?.id !== undefined)
	const [streetType, setStreetType] = useState<StreetTypeOptionType | null>(getStreetType(props.editData?.streetType ?? StreetTypes[0].value) ?? StreetTypes[0]);
	const [streetName, setStreetName] = useState<string>(props.editData?.streetName ?? "");
	const [houseNumber, setHouseNumber] = useState<string>(props.editData?.houseNumber ?? "");
	const [apartamentNumber, setApartamentNumber] = useState<string>(props.editData?.apartamentNumber ?? "");
	const [zipCode, setZipCode] = useState<string>(props.editData?.zipCode ?? "");
	const [town, setTown] = useState<string>(props.editData?.town ?? "");
	const [country, setCountry] = useState<CountryOptionType>(getCountry(props.editData?.country ?? Countries[0].value) ?? Countries[0]);
	const [description, setDescription] = useState<string>(props.editData?.description ?? "");

	useEffect(() => {
		setValidated(new FormValidationSummary());
		setEdit(props.editData?.id !== undefined);
		setStreetType(getStreetType(props.editData?.streetType ?? StreetTypes[0].value) ?? StreetTypes[0]);
		setStreetName(props.editData?.streetName ?? "");
		setHouseNumber(props.editData?.houseNumber ?? "");
		setApartamentNumber(props.editData?.apartamentNumber ?? "");
		setZipCode(props.editData?.zipCode ?? "");
		setTown(props.editData?.town ?? "");
		setCountry(getCountry(props.editData?.country ?? Countries[0].value) ?? Countries[0]);
		setDescription(props.editData?.description ?? "");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.editData]);

	const getFormUserDeliveryAddress = () => ({
		id: props.editData?.id,
		streetType: streetType?.value ?? "",
		streetName: streetName,
		houseNumber: houseNumber,
		apartamentNumber: apartamentNumber,
		zipCode: zipCode,
		town: town,
		country: country.value,
		description: description,
		selfPickup: false,
		readOnly: false
	} as UserDeliveryAddress);

	const resetFormUserDeliveryAddress = () => {
		setValidated(new FormValidationSummary());
		setEdit(false);
		setStreetType(StreetTypes[0]);
		setStreetName("");
		setHouseNumber("");
		setApartamentNumber("");
		setZipCode("");
		setTown("");
		setCountry(Countries[0]);
		setDescription("");
	}

	const cancel = () => {
		props.onCancel();
		resetFormUserDeliveryAddress();
	}

	const accept = () => {
		props.onAccept(getFormUserDeliveryAddress());
		resetFormUserDeliveryAddress();
	}

	const checkFormValidity = () => {
		const summary = new FormValidationSummary();
		if (streetName === '')
			summary.streetName = 'empty';
		if (houseNumber === '')
			summary.houseNumber = 'empty';
		if (zipCode === '')
			summary.zipCode = 'empty';
		else if (!(new RegExp(country.regex).test(zipCode)))
			summary.zipCode = 'pattern';
		if (town === '')
			summary.town = 'empty';
		if (country.value === '')
			summary.country = 'empty';
		return summary;
	};

	// remove validation error status after updating the value once
	useEffect(() => {
		setValidated(validated => new FormValidationSummary({...validated, streetName: null}))
	}, [streetName]);

	useEffect(() => {
		setValidated(validated => new FormValidationSummary({...validated, houseNumber: null}))
	}, [houseNumber]);

	useEffect(() => {
		setValidated(validated => new FormValidationSummary({...validated, zipCode: null}))
	}, [zipCode]);

	useEffect(() => {
		setValidated(validated => new FormValidationSummary({...validated, town: null}))
	}, [town]);

	useEffect(() => {
		setValidated(validated => new FormValidationSummary({...validated, country: null}))
	}, [country]);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		const validation = checkFormValidity();
		setValidated(validation);
		if (validation.anyError())
			return;
		accept();
	}

	return (
		<Modal show={props.show} onHide={cancel} centered size="lg" className="DeliveryAddressModal-form">
			<Modal.Header closeButton>
				<Modal.Title>PROSZĘ UZUPEŁNIĆ DANE ADRESOWE</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form noValidate onSubmit={handleSubmit}>
					<Form.Group controlId="AddressStreet">
						<Row>
							<Col xl={4} lg={4} md={4} sm={3} xs={12}>
								<Select options={StreetTypes} placeholder="Typ ulicy" isSearchable={false} isClearable={true}
									value={streetType} onChange={(option) => setStreetType(option as StreetTypeOptionType ?? null)} />
								<Row className="label-row">
									<Form.Label>Opcjonalne</Form.Label>
									<Form.Label>&nbsp;</Form.Label>
								</Row>
							</Col>
							<Col xl={8} lg={8} md={8} sm={9} xs={12}>
								<Form.Control type="text" placeholder="Nazwa ulicy" maxLength={MAX_ADDRESS_STREET_NAME_LENGTH}
									value={streetName} onChange={(event) => setStreetName(event.target.value)} />
								<Row className="label-row">
									<Form.Label style={{ color: 'red' }}>{validated.streetName === 'empty' ? <>Pole obowiązkowe</> : <>&nbsp;</>}</Form.Label>
									<Form.Label style={{ color: streetName.length === MAX_ADDRESS_STREET_NAME_LENGTH ? 'red' : 'inherit' }}>
										{streetName.length} / {MAX_ADDRESS_STREET_NAME_LENGTH}
									</Form.Label>
								</Row>
							</Col>
							<Col xl={6} lg={6} md={6} sm={6} xs={12}>
								<Form.Control type="text" placeholder="Nr budynku" maxLength={MAX_ADDRESS_HOUSE_NUMBER_LENGTH}
									value={houseNumber} onChange={(event) => setHouseNumber(event.target.value)} />
								<Row className="label-row">
									<Form.Label style={{ color: 'red' }}>{validated.houseNumber === 'empty' ? <>Pole obowiązkowe</> : <>&nbsp;</>}</Form.Label>
									<Form.Label style={{ color: houseNumber.length === MAX_ADDRESS_HOUSE_NUMBER_LENGTH ? 'red' : 'inherit' }}>
										{houseNumber.length} / {MAX_ADDRESS_HOUSE_NUMBER_LENGTH}
									</Form.Label>
								</Row>
							</Col>
							<Col xl={6} lg={6} md={6} sm={6} xs={12}>
								<Form.Control type="text" placeholder="Nr mieszkania" maxLength={MAX_ADDRESS_APARTMENT_NUMBER_LENGTH}
									value={apartamentNumber} onChange={(event) => setApartamentNumber(event.target.value)} />
								<Row className="label-row">
									<Form.Label>Opcjonalne</Form.Label>
									<Form.Label style={{ color: apartamentNumber.length === MAX_ADDRESS_APARTMENT_NUMBER_LENGTH ? 'red' : 'inherit' }}>
										{apartamentNumber.length} / {MAX_ADDRESS_APARTMENT_NUMBER_LENGTH}
									</Form.Label>
								</Row>
							</Col>
						</Row>
					</Form.Group>
					<Form.Group controlId="AddressCountryAndCity">
						<Row>
							<Col xl={3} lg={3} md={12} sm={12} xs={12}>
								<Select options={Countries} placeholder="Kraj" isSearchable={false}
									value={country} onChange={(option) => setCountry(option as CountryOptionType)} />
								<Row className="label-row">
									<Form.Label style={{ color: 'red' }}>{validated.country === 'empty' ? <>Pole obowiązkowe</> : <>&nbsp;</>}</Form.Label>
									<Form.Label>&nbsp;</Form.Label>
								</Row>
							</Col>
							<Col xl={5} lg={5} md={8} sm={8} xs={12}>
								<Form.Control type="text" placeholder="Miejscowość" maxLength={MAX_ADDRESS_TOWN_LENGTH}
									value={town} onChange={(event) => setTown(event.target.value)} />
								<Row className="label-row">
									<Form.Label style={{ color: 'red' }}>{validated.town === 'empty' ? <>Pole obowiązkowe</> : <>&nbsp;</>}</Form.Label>
									<Form.Label style={{ color: town.length === MAX_ADDRESS_TOWN_LENGTH ? 'red' : 'inherit' }}>
										{town.length} / {MAX_ADDRESS_TOWN_LENGTH}
									</Form.Label>
								</Row>
							</Col>
							<Col xl={4} lg={4} md={4} sm={4} xs={12}>
								<Form.Control type="text" placeholder="Kod pocztowy" maxLength={country.length}
									value={zipCode} onChange={(event) => setZipCode(event.target.value)} />
								<Row className="label-row">
									{validated.zipCode === null && <Form.Label>{country.format}</Form.Label>}
									{validated.zipCode === 'empty' && <Form.Label style={{ color: 'red' }}>Pole obowiązkowe</Form.Label>}
									{validated.zipCode === 'pattern' && <Form.Label style={{ color: 'red' }}>Nieprawidłowy kod</Form.Label>}
									<Form.Label style={{ color: zipCode.length === country.length ? 'green' : 'inherit' }}>
										{zipCode.length} / {country.length}
									</Form.Label>
								</Row>
							</Col>
						</Row>
					</Form.Group>
					<Form.Group controlId="AddressDescription">
						<Row>
							<Col xl={12} lg={12} md={12} sm={12} xs={12}>
								<Form.Control as="textarea" placeholder="Opis" rows={3}
									value={description} onChange={(event) => setDescription(event.target.value)} />
								<Row className="label-row">
									<Form.Label>Opcjonalne</Form.Label>
									<Form.Label style={{ color: description.length === MAX_ADDRESS_DESC_LENGTH ? 'red' : 'inherit' }}>
										{description.length} / {MAX_ADDRESS_DESC_LENGTH}
									</Form.Label>
								</Row>
							</Col>
						</Row>
					</Form.Group>
					<Form.Row>
						<ButtonComponent
							marginLeft="auto"
							variant="danger"
							text="Anuluj edycję"
							onClick={cancel}
						/>
						<ButtonComponent
							variant="success"
							type="submit"
							text={edit ? "Akceptuj edycję" : "Dodaj nowy adres"}
						/>
					</Form.Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
}