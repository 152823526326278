import React, { useEffect, useState } from 'react';
import UserRegistrationForm from 'components/UserRegistrationForm';
import { AppState, UserRegistrationInfo, useUser } from 'reducers';
import { useSelector } from 'react-redux';
import { getUserInfo, updateUserData } from 'lib/communication/userInfo';
import Spinner from 'components/Spinner';

export default function Account() {
	const userInfo = useSelector((state: AppState) => state.data.userInfo.userInfo);
	const userInfoIsFetching = useSelector((state: AppState) => state.data.userInfo.props.isFetching);
	const user = useUser();
	const userLogin = user === null ? '' : user.login;
	const userType = user !== null ? (user.role === 'handlowiec' ? 'trader' : 'roofer') : 'roofer';
	const [error, setError] = useState(false);

	useEffect(() => {
		getUserInfo(userLogin)
			.then(result => setError(!result));
	}, [userLogin]);

	const handleSubmit = (newUserInfo: UserRegistrationInfo) => {
		if (userLogin !== undefined) {
			updateUserData(userLogin, userType, newUserInfo);
		}
	};

	if (userInfoIsFetching || error) {
		return <Spinner showError={error} />;
	}

	return (
		<>
			<h2>Edycja danych konta</h2>
			<UserRegistrationForm
				userType={userType}
				submitText="Zapisz"
				userInfo={userInfo ?? undefined}
				onSubmit={handleSubmit}
			/>
		</>
	);
}
