import React from 'react';
import classNames from 'classnames';

interface OptionProps {
	onClick: (value: string) => void,
	children: any,
	value: string,
	className: string,
	activeClassName?: string,
	active?: boolean,
	disabled?: boolean,
	title: string,
}

export default function Option(props: OptionProps) {

	const onClick = () => {
		const { disabled, onClick, value } = props;
		if (!disabled) {
			onClick(value);
		}
	};

	const { children, className, activeClassName, active, disabled, title } = props;
	return (
		<div
			className={classNames(
				'rdw-option-wrapper',
				className,
				{
					[`rdw-option-active ${activeClassName}`]: active,
					'rdw-option-disabled': disabled,
				},
			)}
			onClick={onClick}
			aria-selected={active}
			title={title}
		>
			{children}
		</div>
	);
}
