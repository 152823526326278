import './NewOrder.scss';
import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import { LG } from 'lib/util';
import { useMediaQuery } from 'react-responsive';
import { FaAngleLeft } from 'react-icons/fa';
import { dataActions } from 'reducers';
import store from 'store';
import CartTable from 'components/CartTable';
import ButtonComponent from 'components/ButtonComponent';

interface CartProps {
	show: boolean;
	close: () => void;
}

export default function Cart(props: CartProps) {
	const isDesktop = useMediaQuery({ minWidth: LG });

	const [clearCart, setClearCart] = useState(false);
	const changeClearCart = () => {
		if (clearCart) setClearCart(false);
		else setClearCart(true);
	}

	return (
		<>
			<Modal show={clearCart} onHide={changeClearCart} centered>
						<Modal.Header closeButton>
							<Modal.Title>Uwaga</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div style={{textAlign: "center"}}>Czy na pewno chcesz usunąć zawartość koszyka?</div>
							<div className="NewOrder-summaryCancelConfirm-buttons">
								<ButtonComponent
									marginLeft="0"
									marginRight="0"
									width="45%"
									text="TAK"
									onClick={() => {
											store.dispatch(dataActions.clearLocalCart());
											changeClearCart();
										}
									}
								/>
								<ButtonComponent
									marginLeft="10%"
									marginRight="0"
									width="45%"
									text="NIE"
									onClick={changeClearCart}
								/>
							</div>
						</Modal.Body>
			</Modal>
			<Modal show={props.show} onHide={props.close} size="lg" dialogClassName="NewOrder-dialog" backdrop="static" centered>
				{isDesktop ?
					(<>
						<Modal.Header closeButton className="NewOrder-modal-header">
							<Modal.Title>Koszyk</Modal.Title>
						</Modal.Header>
						<Modal.Body style={{ paddingTop: '0.5rem'}} className="NewOrder-modal-body">
							<CartTable close={props.close}  />
						</Modal.Body>
					</>)
					:
					(<>
						<Modal.Header>
							<FaAngleLeft size='40px' onClick={props.close} />
							<Modal.Title>Koszyk</Modal.Title>
						</Modal.Header>
						<Modal.Body style={{ padding: '0' }}>
							<CartTable close={props.close}/>
						</Modal.Body>
					</>)
				}
				<Modal.Footer className="NewOrder-modal-footer">
					<ButtonComponent  
						text='WYCZYŚĆ' 
						onClick={changeClearCart}
						marginLeft='3px'
						marginTop='0px'
						marginBottom='0px'
						variant='danger' 
					/>
					<ButtonComponent
						text='ZAMKNIJ'
						onClick={props.close}
						marginRight='3px'
						marginTop='0px'
						marginBottom='0px'
					/>
				</Modal.Footer>
			</Modal>
		</>
	)
}