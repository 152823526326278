import { addServiceWorks, getServiceWorks, removeServiceWorks} from "lib/communication/admin";
import { LG } from "lib/util";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Table, Form, Button} from "react-bootstrap";
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { useMediaQuery } from "react-responsive";
import '../Admin.scss';
import { FaRegTrashAlt, FaPlus } from "react-icons/fa";

type UserEditStatus = 'ready' | 'ok' | 'error' | 'loading';

const ServiceWorks = () => {
	const isDesktop = useMediaQuery({ minWidth: LG });
	const shutdownHours = useSelector((state: AppState) => state.conf.shutdownHours);
	const serviceWorks = useSelector((state: AppState) => state.data.adminInfo.serviceWorks);
	const serviceWorkFetching = useSelector((state: AppState) => state.data.adminInfo.props.isFetching);
	const [dateTimeFrom, setDateTimeFrom] = useState<string>('');
	const [dateTimeTo, setDateTimeTo] = useState<string>('');
	const [serviceWorkMessage, setServiceWorkMessage] = useState<string>('');
	const [invalidDates, setInvalidDates] = useState<boolean>(false);
	const [invalidMessage, setInvalidMessage] = useState<boolean>(false);

    useEffect(() => {
        getServiceWorks();
    }, []);

    const convertDate = (originalDate: Date) => {
		return originalDate.toLocaleString([], {
			hour: '2-digit',
			minute: '2-digit',
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		});
	}

    const planServiceWorks = async () => {
		setInvalidDates(false);
		if (!(dateTimeFrom && dateTimeTo)) {
			setInvalidDates(true);
			return;
		}

		let from = Date.parse(dateTimeFrom);
		let to = Date.parse(dateTimeTo);
		if (!(from && to) || to <= from) {
			setInvalidDates(true);
			return;
		}

		if (!serviceWorkMessage) {
			setInvalidMessage(true);
			return;
		}

		await addServiceWorks(dateTimeFrom, dateTimeTo, serviceWorkMessage);

		setDateTimeFrom("");
		setDateTimeTo("");
		setServiceWorkMessage("");
		setInvalidDates(false);
		setInvalidMessage(false);
		getServiceWorks();
	}

    return <>
        <h2 className="mb-2 pb-2 pt-2">Godziny wyłączenia systemu zamówień:</h2>
        <h2 className="mb-5 pb-2 font-weight-bold">od {shutdownHours.from}:00 do {shutdownHours.to}:00 </h2>
        <h2 className="mb-4">Zaplanowane prace serwisowe </h2>
        {isDesktop ? (
            <>
                <Table borderless striped>
                    <thead style={{ width: '100%' }}>
                        <tr>
                            <td className="col-3">Od</td>
                            <td className="col-3">Do</td>
                            <td className="col-6">Wiadomość</td>
                            <td className="col-1">Opcje</td>
                        </tr>
                    </thead>
                    <tbody>
                        {_.map(serviceWorks, sw => <tr key={sw.message}>
                            <td className="col-3">{convertDate(sw.from)}</td>
                            <td className="col-3">{convertDate(sw.to)}</td>
                            <td className="col-6" style={{ width: '4rem' }}>{sw.message}</td>
                            <td className="col-1" style={{ width: '1rem' }}>
                                <Button
                                    variant="link"
                                    className="action-button"
                                    style={{ color: 'red' }}
                                    disabled={serviceWorkFetching}
                                    onClick={() => {
                                        removeServiceWorks(sw);

                                        getServiceWorks()
                                    }}
                                >
                                    <FaRegTrashAlt />
                                </Button>
                            </td>
                        </tr>)}
                        <tr>
                            <td className="col-3"><Form.Control isInvalid={invalidDates} type="datetime-local" value={dateTimeFrom} min={Date.now()} onChange={(e) => setDateTimeFrom(e.target.value)}></Form.Control></td>
                            <td className="col-3"><Form.Control isInvalid={invalidDates} type="datetime-local" value={dateTimeTo} min={dateTimeFrom ? dateTimeFrom : Date.now()} onChange={(e) => setDateTimeTo(e.target.value)}></Form.Control></td>
                            <td className="col-6" style={{ width: '4rem' }}><Form.Control isInvalid={invalidMessage} value={serviceWorkMessage} onChange={(e) => setServiceWorkMessage(e.target.value)}></Form.Control></td>
                            <td>
                                <Button onClick={planServiceWorks}
                                    disabled={serviceWorkFetching}><FaPlus /></Button>
                            </td>
                        </tr>
                    </tbody>

                </Table>

            </>)
            :
            <>
                {
                    _.map(serviceWorks, sw => <div key={sw.message} className="mobile-user-block">
                        <div className="mobile-user-block-login col-6 ">
                            <div className="pb-3">od:&nbsp;<b>{convertDate(sw.from)}</b></div>
                            <div>do:&nbsp;<b>{convertDate(sw.to)}</b></div>
                        </div>
                        <div className="mobile-user-block-administrator col-6">{sw.message}</div>
                        <div>
                            <Button
                                variant="link"
                                className="action-button"
                                style={{ color: 'red' }}
                                disabled={serviceWorkFetching}
                                onClick={() => {
                                    removeServiceWorks(sw);

                                    getServiceWorks()
                                }}
                            >
                                <FaRegTrashAlt />
                            </Button>
                        </div>
                    </div>)
                }
                <div className="mobile-user-block">
                    <div className="mobile-user-block-login col-6 ">
                        <div className="pb-4">
                            od: <Form.Control className="Admin-datetime-input" isInvalid={invalidDates} type="datetime-local" value={dateTimeFrom} min={Date.now()} onChange={(e) => setDateTimeFrom(e.target.value)}></Form.Control>
                        </div>
                        <div>
                            do: <Form.Control className="Admin-datetime-input" isInvalid={invalidDates} type="datetime-local" value={dateTimeTo} min={dateTimeFrom ? dateTimeFrom : Date.now()} onChange={(e) => setDateTimeTo(e.target.value)}></Form.Control>
                        </div>
                    </div>
                    <div className="mobile-user-block-administrator col-6">
                        <Form.Control isInvalid={invalidMessage} value={serviceWorkMessage} onChange={(e) => setServiceWorkMessage(e.target.value)}></Form.Control>
                    </div>
                </div>
                <Button onClick={planServiceWorks}
                    disabled={serviceWorkFetching}>Dodaj prace serwisowe</Button>
            </>
        }
    </>
}

export default ServiceWorks;
