import './DeliveryAddressSelect.scss';
import React, { useState, useEffect } from 'react';
import Select, { components, OptionProps, MenuListComponentProps, StylesConfig } from 'react-select';
import { FaEdit, FaTrashAlt, FaPlusCircle } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import ButtonComponent from 'components/ButtonComponent';
import DeliveryAddressForm from './DeliveryAddressForm';
import { getUserDeliveryAddresses, addUserDeliveryAddress, editUserDeliveryAddress, deleteUserDeliveryAddress } from 'lib/communication/userInfo';
import { UserDeliveryAddress } from 'lib/types';

interface DeliveryAddressSelectProps {
	required?: boolean;
	name?: string;
	addresses: UserDeliveryAddress[] | null;
	deliveryAddress?: string; // exposes external address state, when not using form submit
	setDeliveryAddress?: (address: string) => void; // exposes external address state, when not using form submit
}

type OptionType = {
	label: string,
	value: string,
	data: UserDeliveryAddress
};

export default function DeliveryAddressSelect(props: DeliveryAddressSelectProps) {
	const [userDeliveryAddressList, setUserDeliveryAddressList] = useState<OptionType[]>([]);
	const [selectedAddress, setSelectedAddress] = useState<string>(props.deliveryAddress ?? "");
	const [editAddress, setEditAddress] = useState<UserDeliveryAddress | undefined>(undefined);
	const [deleteAddress, setDeleteAddress] = useState<UserDeliveryAddress | null>(null);
	const [showFormModal, setShowFormModal] = useState<boolean>(false);

	useEffect(() => {
		let userAddresses: OptionType[] = [];
		if (props.addresses !== null) {
			userAddresses = props.addresses.map((addressData) => {
				const address = addressData.toAddressString();
				return { label: address, value: addressData.id, data: addressData };
			}) as OptionType[];
		}
		setUserDeliveryAddressList(userAddresses);
	}, [props.addresses]);

	const onFormModalCancel = () => {
		setShowFormModal(false);
		setEditAddress(undefined);
	};

	const onFormModalAccept = (deliveryAddress: UserDeliveryAddress) => {
		console.log(deliveryAddress);
		if (deliveryAddress.id) {
			editUserDeliveryAddress(deliveryAddress).then(() => getUserDeliveryAddresses());
		} else {
			addUserDeliveryAddress(deliveryAddress).then(() => getUserDeliveryAddresses());
		}
		onFormModalCancel();
	};

	const onDeleteModalCancel = () => {
		setDeleteAddress(null);
	}

	const onDeleteModalAccept = () => {
		if (deleteAddress) {
			deleteUserDeliveryAddress(deleteAddress).then(() => getUserDeliveryAddresses());
		}
		onDeleteModalCancel();
	}

	const CustomStyle: StylesConfig = {
		menuList: (provided) => {
			return {
				...provided,
				maxHeight: 10 + 5 * 40 // padding + n * option height
			}
		},
		option: (provided, { isSelected }) => {
			return {
				...provided,
				backgroundColor: isSelected ? provided.backgroundColor : "white",
				borderBottom: "solid 1px lightgray",
				"&:hover": {
					backgroundColor: isSelected ? provided.backgroundColor : "#deebff"
				}
			};
		}
	};
	
	const CustomMenuList = (props: MenuListComponentProps<OptionType>) => {
		return (
			<components.MenuList {...props}>
				{props.children}
				<div className="address-add-new" onClick={() => setShowFormModal(true)}>
					<FaPlusCircle />
					<span>Dodaj nowy adres dostawy</span>
				</div>
			</components.MenuList >
		);
	};
	
	const CustomOption = (props: OptionProps<OptionType>) => {
		const option: OptionType = props.data;
		if (!option.data.readOnly) {
			return (
				<components.Option {...props}>
					<div className="address-option">
						<span className="address-option-label">{option.label} <span className="address-option-label-desc">{option.data.description}</span></span>
						<div className="address-option-controls">
							<FaEdit className="address-option-control" onClick={(e) => {
								e.stopPropagation();
								setEditAddress(option.data);
								setShowFormModal(true);
							}} />
							<FaTrashAlt className="address-option-control" onClick={(e) => {
								e.stopPropagation();
								setDeleteAddress(option.data);
							}} />
						</div>
					</div>
				</components.Option>
			);
		} else {
			return (
				<components.Option {...props}>
					{option.label}
				</components.Option>
			);
		}
	};

	return (
		<>
			<Modal show={deleteAddress !== null} onHide={onDeleteModalCancel} centered>
				<Modal.Header closeButton>
					<Modal.Title>Czy na pewno chcesz usunąć adres?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{deleteAddress?.toAddressString()}
				</Modal.Body>
				<Modal.Footer>
					<ButtonComponent
						variant="success"
						text="Anuluj"
						onClick={onDeleteModalCancel}
					/>
					<ButtonComponent
						variant="danger"
						text="Usuń"
						onClick={onDeleteModalAccept}
					/>
				</Modal.Footer>
			</Modal>
			<DeliveryAddressForm show={showFormModal} editData={editAddress} onCancel={onFormModalCancel} onAccept={onFormModalAccept} />
			<Select
				isClearable
				name={props.name ?? "DeliveryAddress"} // form submit name
				required={props.required ?? false}
				placeholder="Wybierz adres dostawy"
				noOptionsMessage={() => "Nie znaleziono adresu"}
				loadingMessage={() => "Wczytywanie..."}
				isLoading={props.addresses === null}
				options={userDeliveryAddressList}
				onChange={(option) => {
					const addressData = (option as OptionType)?.data;
					if (addressData) {
						setSelectedAddress(addressData.id ?? "");
						props.setDeliveryAddress && props.setDeliveryAddress(addressData.id ?? "");
					} else {
						setSelectedAddress("");
						props.setDeliveryAddress && props.setDeliveryAddress("");
					}
				}}
				value={userDeliveryAddressList.find((val) => val.value === selectedAddress)}
				components={{ MenuList: CustomMenuList, Option: CustomOption }}
				styles={CustomStyle}
			/>
		</>
	)
}