import { API_ADDRESS, get, post, put } from "lib/communication";
import { CacheManagement, ServiceWorkType, Statistics } from "lib/types";
import { dataActions } from "reducers";
import store from "store";

export interface UserDTO {
	id: number;
	login: string;
	administrator: boolean;
}

export const getAdmins = async (): Promise<UserDTO[] | false> => {
	const route: string = 'admin/getAdmins';

	try {
		const data = await get<UserDTO[]>(`${API_ADDRESS}${route}`);
		if (data.ok && data.parsedBody) return data.parsedBody;
		return false;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export const updateAdmins = async (users: UserDTO[]): Promise<boolean> => {
	const route: string = 'admin/updateAdmins';

	try {
		const data = await put(`${API_ADDRESS}${route}`, users);
		if (data.ok && data.parsedBody) return true;
		return false;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export const addAdmin = async (users: UserDTO[]): Promise<boolean> => {
	const route: string = 'admin/addAdmin';

	try {
		const data = await put(`${API_ADDRESS}${route}`, users);
		if (data.ok && data.parsedBody) return true;
		return false;
	} catch (error) {
		console.error(error);
		return false;
	}
}


export const getServiceWorks = async (): Promise<Boolean> => {

	const route: string = 'admin/serviceWorks';
	setAdminInfoFetching(true);

	try {
		const data = await get<{ serviceWorks: ServiceWorkType[] }>(`${API_ADDRESS}${route}`);
		if (data.ok && data.parsedBody && data.parsedBody.serviceWorks) {
			store.dispatch(dataActions.setServiceWorks(data.parsedBody.serviceWorks.map((sw) => {
				return {
					...sw,
					from: new Date(sw.from),
					to: new Date(sw.to)
				}
			})));
			return true;
		}
		return false;
	} catch (error) {
		console.error(error);
		return false;
	} finally {
		setAdminInfoFetching(false);
	}
}

export const addServiceWorks = async (from: string, to: string, message: string): Promise<Boolean> => {

	const route: string = 'admin/addServiceWorks';
	setAdminInfoFetching(true);
	try {
		const data = await put(`${API_ADDRESS}${route}`, {
			from: from,
			to: to,
			message: message
		});

		if (data.ok && data.parsedBody) return true;
		return false;
	} catch (error) {
		console.error(error);
		return false;
	} finally {
		setAdminInfoFetching(false);
	}
}

export const removeServiceWorks = async (sw: ServiceWorkType): Promise<Boolean> => {

	const route: string = 'admin/removeServiceWorks';
	setAdminInfoFetching(true);
	try {
		const data = await put(`${API_ADDRESS}${route}`, sw);
		if (data.ok && data.parsedBody) return true;
		return false;
	} catch (error) {
		console.error(error);
		return false;
	} finally {
		setAdminInfoFetching(false);
	}
}

function setAdminInfoFetching(isFetching: boolean) {
	store.dispatch(dataActions.setAdminInfoFetching(isFetching));
}

function setIsCacheManagementFetching(isFetching: boolean) {
	store.dispatch(dataActions.setIsCacheManagementFetching(isFetching));
}

export const getCacheManagement = async (): Promise<Boolean> => {
	const route: string = 'admin/cacheManagement';
	setIsCacheManagementFetching(true);
	try {
		const data = await get<{ cacheManagement: CacheManagement[] }>(`${API_ADDRESS}${route}`);
		if (data.ok && data.parsedBody && data.parsedBody.cacheManagement) {
			store.dispatch(dataActions.setCacheManagement(data.parsedBody.cacheManagement.map((cm) => {
				return {
					...cm,
					date: cm.date !== null ? new Date(cm.date) : null
				}
			})));
			return true;
		}

		return false;
	} catch (error) {
		console.error(error);
		return false;
	} finally {
		setIsCacheManagementFetching(false);
	}
}

export const callArtisanCommand = async (value: string): Promise<Boolean> => {
	const route: string = 'admin/callArtisanCommand';
	try {
		const response = await post<Response>(`${API_ADDRESS}${route}`, {
			value: value
		});
		if (response.ok && response.parsedBody) {
			return true;
		} else {
			return false;
		}
	} catch (error) {
		console.error(error);
		return false;
	} finally {

	}
}

export const getStatistics = async (from: string, to: string) => {
	const route: string = 'admin/getStatistics';
	
	setAdminInfoFetching(true);
	try {
		const response = await get<Statistics>(`${API_ADDRESS}${route}?from=${from}&to=${to}`);
		if (response.ok && response.parsedBody) {
			store.dispatch(dataActions.setStatistics(response.parsedBody));
			return true;
		} else {
			return false;
		}
	} catch (error) {
		console.error(error);
		return false;
	} finally {
		setAdminInfoFetching(false);
	}
}
