import React, { RefObject, useEffect, useRef, useState } from 'react';
import './TreeSelect.scss';
import { makeRoot, foldTree, treeToList, TreeNode } from 'lib/tree';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { buildResourceLink } from 'lib/communication';
import { getProductsByGroup } from 'lib/communication/orders';
import { Product } from 'lib/types';
import Spinner from 'components/Spinner';
import { Spinner as Spin } from 'react-bootstrap';
import { arrayPathToString, firstNGroups, pathToArray } from './AssortmentSelection';
import { useMediaQuery } from 'react-responsive';
import { LG } from 'lib/util';

interface TreeSelectProps {
	error: boolean;
	tree: TreeNode[] | null;
	selectedPath: string;
	selectedProduct: Product | null;
	treeRef: RefObject<HTMLDivElement | null>;
	textInputRef: RefObject<HTMLInputElement | null>;
	toggleTreeNode: (key: string) => void;
	onUpdate: (productSymKar: string, path: string[]) => void;
	focusFirstInput: () => void;
	setShowTree?: (value: boolean) => void;
}

export default function TreeSelect(props: TreeSelectProps) {
	const isDesktop = useMediaQuery({ minWidth: LG });
	
	const company = useSelector((state: AppState) => state.session.user?.company);

	const [cursor, setCursor] = useState<number>(0);
	const [treeFocus, setTreeFocus] = useState<boolean>(false);
	const [keyBoardNav, setKeyBoardNav] = useState<boolean>(false);
	
	const nodesRefs = useRef<HTMLDivElement[] | null[]>([]);
	const isFetchingGroups = useSelector((state: AppState) => state.data.orders.props.isFetchingGroups);
	const nodesLoading = useSelector((state: AppState) => state.data.orders.props.productsOfGroupsFetching);

	const getRoot = () => ({
		...makeRoot(props.tree || []),
		expanded: true,
	});

	const mappedTree = foldTree((node: TreeNode, childrenNodes: ((depth: number) => TreeNode)[]) =>
		(depth: number): TreeNode => ({
			...node,
			isLeaf: childrenNodes.length === 0,
			depth,
			childrenNodes: node.expanded ? childrenNodes.map(
				(child: (depth: number) => TreeNode) => child(depth + 1)
			) : [],
			selected: false,
		}), getRoot()
	)(-1);

	const nodesToDraw = treeToList(mappedTree);
	nodesToDraw.shift();
	
	useEffect(() => {
		nodesRefs.current = nodesRefs.current.slice(0, nodesToDraw.length);

		let newCursor = cursor;
		while(!isFetchingGroups && newCursor < nodesToDraw.length && nodesToDraw[newCursor].expanded) newCursor++;
		setCursor(newCursor)

		if(keyBoardNav && treeFocus && cursor > 0) scrollToSelectedRef(cursor)
		// eslint-disable-next-line
	}, [nodesToDraw])

	useEffect(() => {
		setCursor(0);
		// eslint-disable-next-line
	}, [props.textInputRef?.current?.value])

	useEffect(() => {
		if(!isFetchingGroups && nodesToDraw[cursor]?.expanded) setCursor(cursor + 1);
		// eslint-disable-next-line
	}, [isFetchingGroups])

	const getNodeIndex = (increment: number = 1) => {
		let i = 1;
		
		while(-1 <= cursor + increment*i && cursor + increment*i < nodesToDraw.length && nodesToDraw[cursor + increment*i]?.expanded) i++;
		if(cursor + increment*i === -1) return cursor;
		return cursor + increment * i;
	}

	const foldParentNode = (): number => {
		let nodeIndex = cursor;
		if(nodesToDraw[cursor].level < 3) return nodeIndex;

		nodeIndex = cursor - 1;

		while(nodeIndex >= 0 && (!nodesToDraw[nodeIndex]?.expanded || (nodesToDraw[nodeIndex]?.level >= nodesToDraw[cursor].level))) nodeIndex--;
		props.toggleTreeNode(nodesToDraw[nodeIndex].key)
		nodesRefs.current[nodeIndex]?.focus()
		return nodeIndex;
	}

	const expandNode = (): number => {
		let nodeIndex = cursor;

		if(nodesToDraw[cursor].symKar) {
			props.onUpdate(nodesToDraw[cursor].symKar, nodesToDraw[cursor].path);
			return nodeIndex;
		}

		clickNode(nodesToDraw[cursor], cursor);

		return nodeIndex;
	}

	const goUpInTree = (): number => {
		let nodeIndex = cursor;

		if(cursor === 0) return nodeIndex;
		nodeIndex = getNodeIndex(-1)
		
		return nodeIndex;
	}

	const goDownInTree = (): number => {
		let nodeIndex = cursor;

		if(cursor === (nodesToDraw.length - 1)) return nodeIndex;
		nodeIndex = getNodeIndex();

		return nodeIndex;
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		
		if(!isDesktop) return;
		if(isFetchingGroups) {
			event.preventDefault()
			return;
		}

		let key = event.key;
		let nodeIndex = cursor;
		setKeyBoardNav(true);
		switch (key) {
			case "ArrowLeft":
				nodeIndex = foldParentNode();
				nodesRefs.current[nodeIndex]?.focus();
			break;
			case "ArrowRight":
				nodeIndex = expandNode();
			break;
			case "Tab":
				nodesRefs.current[cursor]?.focus()
				if(event.shiftKey){
					nodeIndex = goUpInTree();
				} else {
					nodeIndex = goDownInTree();
				}
				
			break;
			case "ArrowUp":
				event.preventDefault()
				nodeIndex = goUpInTree();
			break;
			case "ArrowDown":
				event.preventDefault()
				nodeIndex = goDownInTree();
			break;
			case "Backspace":
				props.textInputRef.current?.focus();
			break;
			case "Enter":
				event.preventDefault();
				event.stopPropagation();
				if(nodesToDraw[cursor].symKar) {
					props.focusFirstInput();
					props.onUpdate(nodesToDraw[cursor].symKar, nodesToDraw[cursor].path);
				}
			break;
		}
		
		setCursor(nodeIndex);
		if(treeFocus && nodeIndex > 0) scrollToSelectedRef(nodeIndex)
	}

	const scrollToSelectedRef = (nodeIndex: number) => {
		if(!isDesktop) return;
		let top = (nodesRefs.current[nodeIndex]?.offsetTop ?? 0) - 140;
		if(nodeIndex >= nodesToDraw.length) top = props.treeRef.current?.scrollHeight ?? 0
		props.treeRef.current?.scrollTo({top: top, behavior: 'smooth'})
	}

	const clickNode = (clickedNode: TreeNode, index: number) => {
		
		if (clickedNode.hasProducts && clickedNode.symKar === undefined) {
			getProductsByGroup(clickedNode.path, company).then((status) => {
				if (!status) {
					props.toggleTreeNode(clickedNode.key);
				}
			});
		}

		if (clickedNode.symKar !== undefined) {
			props.onUpdate(clickedNode.symKar, clickedNode.path);
			if(!isDesktop && props.setShowTree) props.setShowTree(false);
		} else {
			props.toggleTreeNode(clickedNode.key);
		}

		setCursor(index);
		
	};

	const isNodeSelected = (node: TreeNode) => {
		if(props.selectedProduct && props.selectedPath === firstNGroups(arrayPathToString(props.selectedProduct.path), pathToArray(props.selectedPath).length) && props.selectedProduct.symKar === node.symKar) return true;
		if(props.selectedProduct && props.selectedPath === firstNGroups(arrayPathToString(props.selectedProduct.path), pathToArray(props.selectedPath).length) && node.path === firstNGroups(arrayPathToString(props.selectedProduct.path), node.level)) return true;
		if(!isDesktop && !node.symKar && node.path === firstNGroups(props.selectedPath, node.level)) return true;
		
		return false;
	}

	const isSoftCursorOnNode = (node: TreeNode) => {
		if(!isDesktop) return false;
		if(!treeFocus) return false;
		if(nodesToDraw[cursor]?.symKar && (node.symKar === nodesToDraw[cursor].symKar || node.path === firstNGroups(arrayPathToString(nodesToDraw[cursor].path), node.level))) return true;
		if(nodesToDraw[cursor]?.symKar === undefined && cursor < nodesToDraw.length && node.path === firstNGroups(nodesToDraw[cursor].path, node.level)) return true;
		return false;
	}

	const drawNode = (node: TreeNode, index: number) => {
		return (
			<div key={node.key} onMouseDownCapture={(event) => clickNode(node, index)} tabIndex={((!node.expanded && treeFocus) || (index === cursor && !treeFocus))? 0: -1} onKeyDown={(event) => handleKeyDown(event)} ref={el => nodesRefs.current[index] = el} 
				className={`TreeSelect-node ${(isNodeSelected(node))? 'TreeSelect-node-selected': ''} ${isSoftCursorOnNode(node)? 'TreeSelect-node-soft-cursor': ''} ${isDesktop && (cursor === index && treeFocus)? 'TreeSelect-node-cursor': ''} ${isDesktop && !keyBoardNav? 'TreeSelect-hovered-node': ''}`} >
				{node.depth === 1 ? (
						<div style={{ minHeight: '35px', display: 'flex' }}>
							{node.image ? (
									<>
										<div style={{ minWidth: '35px', minHeight: '35px' }}>&nbsp;</div>
										<div style={{ minWidth: '35px', minHeight: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
											<img
												src={buildResourceLink(node.image)}
												alt=''
											/>
										</div>
									</>
								) : (
									<>
										<div style={{ minWidth: '35px', minHeight: '35px' }}>&nbsp;</div>
										<div style={{ minWidth: '35px', minHeight: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
											<img
												src={`${process.env.PUBLIC_URL}/images/ikona.jpg`}
												alt=''
											/>
										</div>
									</>
								)
							}
							<li style={{ marginTop: '6px'}}>
								{!node.symKar && (
									node.expanded ? (
										(nodesLoading.indexOf(node.path) > -1) ?
											<Spin style={{ width: '10px', height: '10px', borderWidth: 2, margin: 3 }} animation="border" /> :
											<FaAngleDown />
									) : <FaAngleRight />
								)}
								{node.description}
							</li>
						</div>
					) : (
						<div style={{ minHeight: '35px', display: 'flex' }}>
							<div style={{ height: '35px', maxWidth: '0', marginLeft: node.depth > 1 ? 35 * node.depth + 'px' : 20 * node.depth + 'px' }}>&nbsp;</div>
							{node.image ? (
									<div style={{ minWidth: '35px', minHeight: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										<img
											src={buildResourceLink(node.image)}
											alt=''
										/>
									</div>
								) : (
									<div style={{ minWidth: '35px', minHeight: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										<img
											src={`${process.env.PUBLIC_URL}/images/ikona.jpg`}
											alt=''
										/>
									</div>
								)
							}
							<li style={{ marginLeft: '5px', marginTop: '6px'}}>
								{!node.symKar && (
									node.expanded ? (
										(nodesLoading.indexOf(node.path) > -1) ?
											<Spin style={{ width: '10px', height: '10px', borderWidth: 2, margin: 3 }} animation="border" /> :
											<FaAngleDown />
									) : <FaAngleRight />
								)}
								{node.description}
							</li>
						</div>
					)
				}
			</div>
		)
	};

	return (
		<div className="TreeSelect-root" 
		onFocus={() => setTreeFocus(true)}
		onBlur={() => setTreeFocus(false)}
		onMouseMove={()=> setKeyBoardNav(false)}
		>
			{
				props.tree ? (
					props.tree.length > 0 ? (
						<ul className="TreeSelect-list" >
							{nodesToDraw.map(drawNode)}
						</ul>
					) : (
						<div className="TreeSelect-empty-catalog">Brak asortymentu</div>
					)
				) : <Spinner showError={props.error} />
			}
		</div>
	);
}
