import { UserDTO, addAdmin, getAdmins, getServiceWorks, updateAdmins } from "lib/communication/admin";
import { LG } from "lib/util";
import _, { sortBy } from "lodash";
import React, { useState, useEffect } from "react";
import { Table, Form, Spinner as Spin, Col, Row} from "react-bootstrap";
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { useMediaQuery } from "react-responsive";
import '../Admin.scss';
import Spinner from "components/Spinner";
import ButtonComponent from "components/ButtonComponent";

type UserEditStatus = 'ready' | 'ok' | 'error' | 'loading';

const Admins = () => {
    const isDesktop = useMediaQuery({ minWidth: LG });
	const serviceWorks = useSelector((state: AppState) => state.data.adminInfo.serviceWorks);
	const serviceWorkFetching = useSelector((state: AppState) => state.data.adminInfo.props.isFetching);
	const [users, setUsers] = useState<UserDTO[] | null>(null);
	const [usersToShow, setUsersToShow] = useState(users);
	const [searchString, setSearchString] = useState('');
	const [statuses, setStatuses] = useState<{ [key: number]: UserEditStatus }>([]);
	const [error, setError] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [newAdmin, setNewAdmin] = useState("");

    useEffect(() => {
		downloadUsers();

		if (!serviceWorkFetching && !serviceWorks.length) {
			getServiceWorks()
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const newStatuses: { [key: number]: UserEditStatus } = {};
		_.map(users, u => newStatuses[u.id] = 'ready');
		setStatuses(newStatuses);
	}, [users]);

	useEffect(() => {
		setUsersToShow(sortBy(_.filter(users, (u) => u.login.toUpperCase().indexOf(searchString.toUpperCase()) > -1), u => !u.administrator));
	}, [users, searchString]);

	const downloadUsers = async () => {
		try {
			setLoading(true);
			const newUsers = await getAdmins();
			if (newUsers) {
				setUsers(newUsers);
				const newStatuses: { [key: number]: UserEditStatus } = {};
				_.map(users, u => newStatuses[u.id] = 'ready');
				setStatuses(newStatuses);

				setError(false);
			} else {
				setError(true)
			}
		} catch (error) {
			setError(true);
		} finally {
			setLoading(false);
		}
	}

	const setStatusOf = (id: number, status: UserEditStatus) => {
		setStatuses(prev => ({ ...prev, [id]: status }));
	}

	const makeAdmin = async (user: UserDTO, administrator: boolean) => {
		setStatusOf(user.id, 'loading');

		user.administrator = administrator;

		try {
			const result = await updateAdmins([user]);

			if (result) {
				setStatusOf(user.id, 'ok');
			} else setStatusOf(user.id, 'error');
		} catch (error) {
			setStatusOf(user.id, 'error');
		}
	}

	const createNewAdmin = async () => {
		try {
			if (newAdmin === "") {
				throw new Error("Użytkownik nie może być pusty");
			}
			_.forEach(users, function (value) {
				if (value.login === newAdmin) {
					throw new Error("Użytkownik już instnieje");
				}
			});
			const result = await addAdmin([{
				id: -1,
				login: newAdmin,
				administrator: true,
			}]);
			if (result) {
				showAdminMessage();
			} else {
				throw new Error("Wystąpił błąd podczas dodawania użytkownika");
			}
		} catch (Error) {
			showAdminMessage(Error as string)
		}
		setNewAdmin('');
		downloadUsers();
	}

	const showAdminMessage = (msg: undefined | string = undefined) => {
		const adminMessage = document.getElementsByClassName('Admin-addMessage')[0];
		if (msg === undefined) {
			adminMessage.className = "Admin-addMessage green show";
			adminMessage.textContent = "Dodano nowego administratora";
			setTimeout(() => {
				adminMessage.className = "Admin-addMessage";
			}, 4500);
		} else {
			adminMessage.className = "Admin-addMessage red show";
			adminMessage.textContent = msg;
			setTimeout(() => {
				adminMessage.className = "Admin-addMessage";
			}, 4500);
		}
	}

    return <>
        <h2 className="mx-4 pb-2">Zarządzanie administratorami</h2>
        <div className="Admin-addMessage" />
        {
            loading ? <Spinner showError={error} errMsg="Błąd pobierania listy użytkowników" /> :
                <>
                    <Form.Control
                        type="text"
                        placeholder="Szukaj"
                        id="orderNumber"
                        onChange={(e) => setSearchString(e.target.value)}
                        style={{ position: "relative", top: '-10px' }}
                    />
                    {
                        isDesktop ? <Table borderless striped>
                            <thead>
                                <tr>
                                    <td>Login</td>
                                    <td>Administrator</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {_.map(usersToShow, user => <tr key={user.login}>
                                    <td>{user.login}</td>
                                    <td>
                                        <Form.Check
                                            className="mobile-user-block-administrator-switch"
                                            id={`mobile-user-block-administrator-switch-${user.id}`}
                                            type="switch"
                                            defaultChecked={user.administrator}
                                            onChange={(e) => { makeAdmin(user, e.currentTarget.checked) }}
                                        />
                                    </td>
                                    <td style={{ width: '4rem' }}>
                                        {
                                            statuses[user.id] === 'loading' ? <Spin animation="border" size="sm" /> :
                                                statuses[user.id] === 'ok' ? <span style={{ color: 'green' }}>OK</span> :
                                                    statuses[user.id] === 'error' ? <span style={{ color: 'red' }}>error</span> : null
                                        }
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                            :
                            <>
                                {
                                    _.map(usersToShow, user => <div key={user.login} className="mobile-user-block">
                                        <div className="mobile-user-block-login">{user.login}</div>
                                        <div className="mobile-user-block-administrator">
                                            <Form.Check
                                                className="mobile-user-block-administrator-switch"
                                                id={`mobile-user-block-administrator-switch-${user.id}`}
                                                type="switch"
                                                defaultChecked={user.administrator}
                                                onChange={(e) => { makeAdmin(user, e.currentTarget.checked) }}
                                            />
                                        </div>
                                        <div className="mobile-user-block-state">
                                            {
                                                statuses[user.id] === 'loading' ? <Spin animation="border" size="sm" /> :
                                                    statuses[user.id] === 'ok' ? <span style={{ color: 'green' }}>OK</span> :
                                                        statuses[user.id] === 'error' ? <span style={{ color: 'red' }}>error</span> : null
                                            }
                                        </div>
                                    </div>)
                                }
                            </>
                    }
                    <Form.Row className="pb-5 mb-2" style={{ alignItems: 'center' }}>
                        <Col>
                            <Form.Control
                                type="text"
                                placeholder="Login użytkownika"
                                id="new_admin"
                                onChange={(event) => { setNewAdmin(event.target.value) }}
                            />
                        </Col>
                        <Row>
                            <Col xs="auto" className="search-button">
                                <ButtonComponent
                                    type="submit"
                                    margin="0"
                                    text="Dodaj administatora"
                                    onClick={() => { createNewAdmin() }}
                                />
                            </Col>
                        </Row>
                    </Form.Row>
                </>
        }
    </>
}

export default Admins;
