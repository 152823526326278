import './ButtonComponent.scss';
import React from 'react';
import { Button } from 'react-bootstrap';
import { LG } from 'lib/util';
import { useMediaQuery } from 'react-responsive';
import { ButtonType } from 'react-bootstrap/esm/Button';

export type Visibility = "hidden" | "visible";

interface ButtonComponentProps {
	children?: React.ReactNode;
	text?: string;
	onClick?: (event: any) => void;
	minDesktopResolution?: number;
	width?: string;
	height?: string;
	disabled?: boolean;
	backgroundColor?: string;
	marginTop?: string;
	marginRight?: string;
	marginBottom?: string;
	marginLeft?: string;
	margin?: string;
	variant?: string;
	type?: ButtonType;
	whiteSpace?: string;
	padding?: string;
	visibility?: Visibility;
}

export default function ButtonComponent({
	children,
	text,
	onClick,
	minDesktopResolution = LG,
	width = 'auto',
	height = '42px',
	disabled = false,
	backgroundColor = '',
	marginTop = '10px',
	marginRight = '10px',
	marginBottom = '10px',
	marginLeft = '10px',
	margin = "",
	variant = 'dark',
	type = 'button',
	whiteSpace = 'normal',
	padding = '0.05rem 0.75rem',
	visibility = 'visible'
}: ButtonComponentProps) {
	const isDesktop = useMediaQuery({ minWidth: minDesktopResolution });
	if (margin.length) {
		marginTop = margin;
		marginRight = margin;
		marginBottom = margin;
		marginLeft = margin;
	}

	return (
		<Button
			className={isDesktop ? 'ButtonComponent-buttonDesktop' : 'ButtonComponent-buttonMobile'}
			disabled={disabled}
			onClick={onClick}
			variant={variant}
			type={type}
			style={{
				width: width,
				height: height,
				backgroundColor: backgroundColor,
				marginTop: marginTop,
				marginRight: marginRight,
				marginBottom: marginBottom,
				marginLeft: marginLeft,
				padding: padding,
				visibility: visibility,
				whiteSpace: whiteSpace as React.CSSProperties['whiteSpace'],
			}}
		>
			{text}
			{children}
		</Button>
	)
}