import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Form, Collapse } from 'react-bootstrap';
import { AppState } from 'reducers';
import { FaAngleDown, FaAngleUp, FaTimes } from 'react-icons/fa';
import store from 'store';
import { dataActions } from 'reducers';
import { Product } from 'lib/types';
import { LG } from 'lib/util';
import './AdditionalAssortment.scss';
import NumberInput from 'components/NumberInput';
import ButtonComponent from 'components/ButtonComponent';
import { Spinner as Spin } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { showCartMessage } from '../AssortmentSelection';
import { getDefaultUnitOfProduct, mapLocalCartToCartTableContent } from 'lib/utilityMetods';
import { useNotification } from 'components/NotificationComponent/NotificationComponent';


interface AdditionalAssortmentProps {
	selectedProduct: Product,
	additionalAssortmentFetching: Boolean
}

interface AdditionalProductsGroup {
	description: string;
	icon: string;
	products: Product[];
	indexes: number[];
	shortNames: string[];
}

export default function AdditionalAssortment({ selectedProduct, additionalAssortmentFetching}: AdditionalAssortmentProps) {

	const isDesktop = useMediaQuery({ minWidth: LG });

	const edit = useSelector((state: AppState) => state.data.orders.edit);
	const products = useSelector((state: AppState) => state.data.orders.groupsAndProducts.products);
	const localCart = useSelector((state: AppState) => edit ? state.data.orders.localEditCart : state.data.orders.localCart);
	const showNotification = useNotification();

	const [additionalAssortmentShow, setAdditionalAssortmentShow] = useState(false);
	const [additionalAssortmentQuantity, setAdditionalAssortmentQuantity] = useState(
		Array<number>(selectedProduct.additionalAssortment?.length ?? 0).fill(0)
	);

	const additionalAssortmentRef = useRef<null | HTMLDivElement>(null);

	const additionalList = useMemo(() => _.map(selectedProduct.additionalAssortment, ({symKar}) => symKar), [selectedProduct]);
	const additionalAssortment = useMemo(() => _.filter(products, ({symKar}) => (_.includes(additionalList, symKar))), [additionalList, products]);
	const additionalAssortmentInCart = useMemo(() => {
		return _.map(_.find(mapLocalCartToCartTableContent(localCart).additionalItems, (item, key) => key === selectedProduct.symKar), (grp) => grp.products).flat();
	}, [selectedProduct, localCart]);

	useEffect(() => {
		const quantities = _.map(additionalAssortment, (prd) => {
			return _.find(additionalAssortmentInCart, (prdInCart) => {
				return prdInCart.product.symKar === prd.symKar;
			})?.quantity ?? 0;
		});
		setAdditionalAssortmentQuantity(quantities);
	}, [additionalAssortmentInCart, additionalAssortment]);
	
	// grouping additional assortment
	const AdditionalGroups: AdditionalProductsGroup[] = useMemo(() => {
		const groups: AdditionalProductsGroup[] = [
			{description: 'Blacha płaska', icon: 'blachy.png', products: [], indexes: [], shortNames: []},
			{description: 'Gąsiory', icon: 'gasiory.png', products: [], indexes: [], shortNames: []},
			{description: 'Farby', icon: 'farby.png', products: [], indexes: [], shortNames: []},
			{description: 'Wkręty', icon: 'wkrety.png', products: [], indexes: [], shortNames: []},
			{description: 'Uszczelki', icon: 'uszczelki.png', products: [], indexes: [], shortNames: []},
			{description: 'Uszczelki', icon: 'uszczelki.png', products: [], indexes: [], shortNames: []},
			{description: 'Pozostałe', icon: 'pozostale.png', products: [], indexes: [], shortNames: []}
		];

		_.map(additionalAssortment, (prd, index) => {
			let tag = _.find(selectedProduct.additionalAssortment, (additional) => additional.symKar === prd.symKar)?.tag;
			let grp = 5;
			let short = '';
			switch(tag) {
				case 'IsPF':	grp = 0; short = 'z folią'; break;
				case 'IsGM':	grp = 1; short = 'mały'; break;
				case 'IsGD':	grp = 1; short = 'duży'; break;
				case 'IsGO':	grp = 1; short = 'kalenica OMEGA'; break;
				case 'IsF': 	grp = 2; short = 'farby'; break;
				case 'IsWf':	grp = 3; short = 'farmery'; break;
				case 'IsWs5':	grp = 3; short = 'samowiertne 5mm'; break;
				case 'IsWs12':	grp = 3; short = 'samowiertne 12mm'; break;
				case 'IsUg':	grp = 4; short = 'gąsior'; break;
				case 'IsUr':	grp = 4; short = 'rynna'; break;
				case 'IsUu':	grp = 5; short = 'uniwersalna'; break;
				case 'IsUgr':	grp = 5; short = 'komplet'; break;
				case 'IsFo':	grp = 6; short = 'membrana 135g'; break;
				case 'IsKW':	grp = 6; short = 'kominek wentylacyjny'; break;
			}
			groups[grp].products.push(prd);
			groups[grp].indexes.push(index);
			groups[grp].shortNames.push(short);
		});

		return groups;
		// eslint-disable-next-line
	}, [additionalAssortment]);

	const addAdditionalToCart = () => {
		_.map(additionalAssortment, (prd, index) => {
			const prdInCart = _.find(additionalAssortmentInCart, assInCart => assInCart.product.symKar === prd.symKar);
			if (prdInCart === undefined && additionalAssortmentQuantity[index] > 0) {
				store.dispatch(dataActions[edit ? 'addToLocalEditCart' : 'addToLocalCart']({
					groupPath: _.join(prd.path, ' ') as string,
					product: {
						product: prd as Product,
						dimension: prd.dimensionDefault ?? 1,
						quantity: additionalAssortmentQuantity[index],
						additionsSelected: _.map(prd.additions, (add) => add.default),
						mainProductSymkar: selectedProduct.symKar,
						unit: getDefaultUnitOfProduct(prd)
					},
					productsType: 'additionalProducts'
				}));
			}
			else if (prdInCart && additionalAssortmentQuantity[index] > 0) {
				store.dispatch(dataActions[edit ? 'editProductsInLocalEditCart' : 'editProductsInLocalCart']({
					groupPath: prdInCart.groupPath,
					index: prdInCart.indexInGroup,
					product: {
						...prdInCart,
						quantity: additionalAssortmentQuantity[index]
					},
					productsType: 'additionalProducts'
				}));
			}
			else if (prdInCart && additionalAssortmentQuantity[index] === 0) {
				store.dispatch(dataActions[edit ? 'deleteFromLocalEditCart' : 'deleteFromLocalCart']({
					groupPath: prdInCart.groupPath,
					index: prdInCart.indexInGroup,
					productsType: 'additionalProducts',
					symKar: prd.symKar
				}));
			}
		});
		if(isDesktop) showCartMessage();
		showNotification('Zaktualizowano asortyment dodatkowy w koszyku');
	}

	const handleClearAdditionalAssortmentQuantity = () => {
		_.map(additionalAssortmentInCart, (prdInCart) => {
			store.dispatch(dataActions[edit ? 'deleteFromLocalEditCart' : 'deleteFromLocalCart']({
				groupPath: prdInCart.groupPath,
				index: prdInCart.indexInGroup,
				productsType: 'additionalProducts',
				symKar: prdInCart.product.symKar
			}));
		});
		if(isDesktop) showCartMessage();
		showNotification('Wyczyszczono asortyment dodatkowy');
	}

	const handleAdditionalAssortmentToggle = () => {
		if(additionalAssortmentFetching || !additionalAssortment.length) return; 
		setAdditionalAssortmentShow(!additionalAssortmentShow);
	}

	const handleScrollToAdditionalAssortment = () => {
		if (additionalAssortmentRef && additionalAssortmentRef.current)
			additionalAssortmentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}

	useEffect(() => {
		setAdditionalAssortmentShow(false);
	}, [selectedProduct]);

	return (
		<>
			<div className={`AdditionalAssortment-addAdditionalAssortmentHeader ${(additionalAssortmentFetching || additionalAssortment.length === 0) && 'AdditionalAssortment-addAdditionalAssortmentHeader-locked'} d-flex align-items-center`}
				onClick={handleAdditionalAssortmentToggle}
				ref={additionalAssortmentRef}>
				<b>Asortyment dodatkowy</b>
				{ !additionalAssortmentFetching && additionalAssortment.length === 0 && <FaTimes size="30px" style={{marginRight: '5px'}}/>}
					{ !additionalAssortmentFetching && additionalAssortment.length > 0 && (additionalAssortmentShow ? <FaAngleUp size="40px" /> : <FaAngleDown size="40px" />)}
					{ additionalAssortmentFetching && <Spin animation="border" size="sm" id="AdditionalAssortment-spinner" className='ms-auto'/>}
			</div>
			<div className="AdditionalAssortment-additionalAssortmentContainer">
				<Collapse in={additionalAssortmentShow} onEntered={handleScrollToAdditionalAssortment}>
					<div>
						<div className="AdditionalAssortment-additionalAssortmentGrid">
							{_.map(AdditionalGroups, ({ products, icon, indexes, description, shortNames }, groupId) => {
								return products.length > 0 && <div key={`AdditionalAssortment-${groupId}`} className="AdditionalAssortment-additionalGroupCard">
									<div className="group-header">
										<p>{description}</p>
										<img src={`${process.env.PUBLIC_URL}/images/icons/${icon}`} alt={icon} />
									</div>
									{_.map(products, (prd, index) => {
										return <div key={`AdditionalAssortment-${groupId}-${index}`} className="prd-line">
											<Form.Group controlId="quantity">
												<span>{shortNames[index]} [{prd.unit}.]</span>
												<NumberInput
													value={additionalAssortmentQuantity[indexes[index]]}
													setValue={(value: number) => {
														let newQuantity = [...additionalAssortmentQuantity];
														newQuantity[indexes[index]] = !isNaN(value) ? Math.abs(value) : 1;
														setAdditionalAssortmentQuantity(newQuantity);
													}}
													minDesktopResolution={LG}
													disabledEdit={additionalAssortmentQuantity[indexes[index]] === 0}
													min={0}
												/>
											</Form.Group>
										</div>
									})}
								</div>
							})
							}
						</div>
						<div className="AdditionalAssortment-buttonsContainer">
							<ButtonComponent
								text='AKTUALIZUJ ASORTYMENT DODATKOWY'
								onClick={addAdditionalToCart}
							/>
							<ButtonComponent
								text='WYCZYŚĆ'
								variant='danger'
								onClick={handleClearAdditionalAssortmentQuantity}
							/>
						</div>
					</div>
				</Collapse>
			</div>
		</>

	);
}

