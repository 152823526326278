import React, { useEffect, useLayoutEffect, useState } from 'react';
import './GutterCreator.scss';
import store from 'store';
import { AppState } from 'reducers';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getImportedProductsInfo, resetGutteringsCart } from 'lib/communication/orders';
import { GutteringSystemData, ImportedProduct } from 'lib/types';
import { createPortal } from 'react-dom';
import Spinner from 'components/Spinner/Spinner';
import { IDENTIFIER } from './GutterCreator';
import { showCartMessage } from 'containers/NewOrder/AssortmentSelection/AssortmentSelection';

declare global {
	interface Window {
		rynnyMount(id: string, order: string): void;
		rynnyUnmount(id: string): void;
		updateOrder(order: string): void;
		submitKreator(massage: string): void;
	}
}

export interface ImportedDataV1 {
	resetGutteringsCart: false;
	systemN: string;
	system: string;
	color: string;
	size: string;
	order: {
		SymKar: string;
		count: number;
	}[]
}

const GutterCreatorV1 = React.memo(() => {

	const dispatch = useDispatch();

	const edit = useSelector((state: AppState) => state.data.orders.edit);
	const localCart = useSelector(
		(state: AppState) => edit ? state.data.orders.localEditCart : state.data.orders.localCart
	);

	const [isGutteringsDownloaded, setIsGutteringsDownloaded] = useState(false);
	const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null);
	const iframeWindow = contentRef?.contentWindow;
	const iframeDocument = iframeWindow?.document;

	const gutterScripts = useSelector((state: AppState) => state.conf.GutterCreatorScripts);

	const [shown, setShown] = useState(false);

	useEffect(() => {
		if (iframeWindow) {
			(iframeWindow as Window).submitKreator = submitKreator;
		}
	}, [iframeWindow]);

	useEffect(() => {
		if (isGutteringsDownloaded && iframeWindow && (iframeWindow as Window).updateOrder) {
			const order = JSON.stringify(
				localCart.gutterings.map((g) => {
					return {
						product_id: g.product.symKar,
						product_qty: g.quantity,
						systemData: g.systemData,
					};
				})
			);
			(iframeWindow as Window).updateOrder(order);
		}
	}, [isGutteringsDownloaded, iframeWindow, localCart]);

	useEffect(() => {
		if (isGutteringsDownloaded || !iframeDocument) return;
		async function loadScripts(scriptUrls: string[]) {
			function load(scriptUrl: string) {
				return new Promise(function (resolve) {
					if (!iframeDocument) return;
					var script = iframeDocument.createElement('script');
					script.onload = resolve;
					script.src = `${process.env.PUBLIC_URL}${scriptUrl}`
					iframeDocument.body.appendChild(script);
				});
			}
			var promises = [];
			promises.push(new Promise(function (resolve) {
				if (!iframeDocument) return;
				var sheet = iframeDocument.createElement('link');
				sheet.onload = resolve;
				sheet.rel = 'stylesheet';
				sheet.href = `${process.env.PUBLIC_URL}/rynny/rynny-style.css`;
				sheet.type = 'text/css';
				iframeDocument.head.appendChild(sheet);
			}));
			for (const scriptUrl of scriptUrls) {
				promises.push(load(scriptUrl));
			}
			await Promise.all(promises);
			setIsGutteringsDownloaded(true);
		}
		loadScripts(gutterScripts);
	}, [dispatch, isGutteringsDownloaded, iframeDocument, gutterScripts]);

	useLayoutEffect(() => {
		if (
			!isGutteringsDownloaded ||
			!iframeWindow ||
			!contentRef ||
			!iframeWindow.rynnyMount ||
			!iframeDocument
		) return;
		const base = iframeDocument.createElement('base');
		base.target = "_BLANK";
		iframeDocument.head.appendChild(base);
		iframeWindow.rynnyMount(IDENTIFIER, "");
		contentRef.height = iframeDocument.body.scrollHeight.toString();
		return () => {
			if (!iframeWindow.rynnyUnmount) return;
			iframeWindow.rynnyUnmount(IDENTIFIER);
		};
	}, [isGutteringsDownloaded, iframeWindow, contentRef, iframeDocument]);

	useEffect(() => {
		setTimeout(() => {
			setShown(true);
		}, 100);
	}, []);

	return (
		<div style={{ minHeight: '200px' }}>
			{!isGutteringsDownloaded ? <Spinner/> : <></>}
			<iframe title={IDENTIFIER} style={{
				width: '100%',
				height: '690px',
				border: 'none',
				display: shown ? 'block' : 'none'
			}} ref={setContentRef}>
				{iframeDocument?.body &&
					createPortal(<div id={IDENTIFIER}></div>, iframeDocument.body)}
			</iframe>
		</div>
	);
});

function submitKreator(massage: string): void {
	const edit = store.getState().data.orders.edit;

	const data = JSON.parse(massage);
	const systemData = _.pick(data, ['systemN', 'system', 'color', 'size']) as GutteringSystemData;
	if (!data.resetGutteringsCart) {
		const products: ImportedProduct[] = _.map(data.order, (product) => ({
			symKar: product.SymKar,
			quantity: product.count,
			dimension: null,
			additionsSelected: null,
		}));
		getImportedProductsInfo(products, edit ? 'addGutteringsToEditLocal' : 'addGutteringsToLocal', systemData);
	} else {
		resetGutteringsCart(edit, systemData);
	}

	showCartMessage();
}

export default GutterCreatorV1;