import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Button, Container, NavDropdown, Jumbotron } from 'react-bootstrap';
import { Switch, Route, Link, useLocation, useHistory, Redirect } from 'react-router-dom';
import './App.scss';
import { useDispatch, useSelector } from 'react-redux';
import { dataActions, useUser, AppState } from 'reducers';
import News from '../News';
import NewOrder from '../NewOrder';
import Orders from '../Orders';
import Promotions from '../Promotions';
import ISOD from '../ISOD';
import Settlements from '../Settlements';
import Account from '../Account';
import CookiePolicy from '../CookiePolicy';
import Login from '../Login';
import ChangePassword from '../Account/ChangePassword';
import LinkItem from 'components/LinkItem';
import { useMediaQuery } from 'react-responsive';
import { SM, LG, USER_ROLE_MASTER, USER_ROLE_ROOFER, USER_ROLE_TRADER } from 'lib/util';
import { setSliceState } from 'reducers/conf';
import store from 'store';
import { DetailedOrder } from 'lib/types';
import { API_ADDRESS, get } from 'lib/communication';
import Spinner from 'components/Spinner';
import { SpinnerButtonType } from 'components/Spinner/Spinner';
import ButtonComponent from 'components/ButtonComponent';
import NotificationComponent from 'components/NotificationComponent';
import { logout } from 'lib/communication';
import Admin from '../Admin';
import RequestErrorModal from 'components/RequestErrorModal/RequestErrorModal';
import { getCurrentOrIncomingServiceWorks } from 'lib/communication/userInfo';
import ReactGA from 'react-ga4';
import SicilianFiesta from 'containers/SicilianFiesta/SicilianFiesta';

(window as any).clearCache = () => {
	get(`${API_ADDRESS}clearCache`)
		.then(
			_ => console.log("Cache cleared"),
			error => console.log(error)
		)
}

export default function App() {
	const location = useLocation();
	const user = useUser();
	const dispatch = useDispatch();
	const isPhone = useMediaQuery({ maxWidth: SM });
	const isDesktop = useMediaQuery({ minWidth: LG });
	const isMedium = useMediaQuery({ minWidth: LG });
	const [showPromotionsDropdown, setShowPromotionsDropdown] = useState(false);
	const [showAdminDropdown, setShowAdminDropdown] = useState(false);
	const [isISODVisible, setVisibility] = useState('none');
	const history = useHistory();
	const brandColor = useSelector((state: AppState) => state.conf.brandColor);
	const brandLightColor = useSelector((state: AppState) => state.conf.brandLightColor);
	const brandBrightColor = useSelector((state: AppState) => state.conf.brandBrightColor);
	const tableFontColor = useSelector((state: AppState) => state.conf.tableFontColor);
	const [activeKey, setActiveKey] = useState('news');
	const [activeKeyName, setActiveKeyName] = useState('Aktualności');
	const [expanded, setExpanded] = useState(false);
	const tooManyAttempts = useSelector((state: AppState) => state.requests.tooManyAttempts);
	const invalidToken = useSelector((state: AppState) => state.requests.invalidToken);
	const viewEnabled = useSelector((state: AppState) => state.conf.menu);
	const edit = useSelector((state: AppState) => state.data.orders.edit);
	const logo = useSelector((state: AppState) => state.conf.logo);
	const company = useSelector((state: AppState) => state.conf.companyId);

	const shutdownHours = useSelector((state: AppState) => state.conf.shutdownHours);
	const serviceWorks = useSelector((state: AppState) => state.data.adminInfo.serviceWorks);
	const currentOrIncomigServiceWorks = useSelector((state: AppState) => state.data.userInfo.currentOrIncomigServiceWorks);
	const isFetching = useSelector((state: AppState) => state.data.adminInfo.props.isFetching);
	const [isDisabled, setIsDisabled] = useState(false);
	const [disableMessage, setDisableMessage] = useState('');
	const ga4TrackingId = useSelector((state: AppState) => state.conf.ga4TrackingId);

	const zamowieniaServiceAddress = useSelector((state: AppState) => state.conf.zamowieniaServiceAddress);

	const [cartReset, setCartReset] = useState(sessionStorage.getItem('cartReset') === 'true');

	useEffect(() => {
		if (cartReset) {
			sessionStorage.setItem('cartReset', 'true');
		} else {
			sessionStorage.setItem('cartReset', 'false');
		}
	}, [cartReset]);

	useEffect(() => {
		if (ga4TrackingId) {
			ReactGA.initialize(ga4TrackingId);
		}
	}, [ga4TrackingId]);

	useEffect(() => {
		if (zamowieniaServiceAddress.length > 0) {
		getCurrentOrIncomingServiceWorks();
		if (isFetching) return;
		}
		// eslint-disable-next-line
	}, [zamowieniaServiceAddress]);

	useEffect(() => {
		if (company !== null) {
			if (
				location.pathname.includes(`${process.env.PUBLIC_URL}/news`) ||
				location.pathname === `${process.env.PUBLIC_URL}/` ||
				location.pathname === `${process.env.PUBLIC_URL}`
			) {
				if (viewEnabled.news) {
					setActiveKey('news');
					setActiveKeyName('Aktualności');
				} else if (user?.role !== USER_ROLE_ROOFER) {
					setActiveKey('orders');
					setActiveKeyName('Zamówienia');
					history.push(`${process.env.PUBLIC_URL}/orders`);
				} else {
					setActiveKey('promotions');
					setActiveKeyName('Wspólne korzyści');
					history.push(`${process.env.PUBLIC_URL}/promotions`);
				}
			} else if (location.pathname.includes(`${process.env.PUBLIC_URL}/promotions`)) {
				if (viewEnabled.promotion) {
					setActiveKey('promotions');
					setActiveKeyName('Wspólne korzyści');
					setShowPromotionsDropdown(true);
				} else {
					history.push('/');
				}
			} else if (location.pathname.includes(`${process.env.PUBLIC_URL}/admin`)) {
				if (user?.isAdmin) {
					setActiveKey('admin');
					setActiveKeyName('Admin');
					setShowAdminDropdown(true);
				} else {
					history.push('/');
				}
			} else if (location.pathname.includes(`${process.env.PUBLIC_URL}/orders`)) {
				setActiveKey('orders');
				setActiveKeyName('Zamówienia');
			} else if (location.pathname.includes(`${process.env.PUBLIC_URL}/new-order`)) {
				setActiveKey('new-order');
				setActiveKeyName('Nowe zamówienie');
			} else if (location.pathname.includes(`${process.env.PUBLIC_URL}/isod`)) {
				setActiveKey('isod');
				setActiveKeyName('Isod');
			} else if (location.pathname.includes(`${process.env.PUBLIC_URL}/settlements`)) {
				setActiveKey('settlements');
				setActiveKeyName('Rozliczenia');
			} else if (location.pathname.includes(`${process.env.PUBLIC_URL}/account`)) {
				setActiveKey('account');
				setActiveKeyName('Konto');
			} else if (location.pathname.includes(`${process.env.PUBLIC_URL}/changePassword`)) {
				setActiveKey('change-password');
				setActiveKeyName('Zmiana hasła');
			} else if (location.pathname.includes(`${process.env.PUBLIC_URL}/cookie-policy`)) {
				setActiveKey('cookie-policy');
				setActiveKeyName('Polityka cookies');
			} else if (location.pathname.includes(`${process.env.PUBLIC_URL}/admin`)) {
				setActiveKey('admin');
				setActiveKeyName('Admin');
			} else if (location.pathname.includes(`${process.env.PUBLIC_URL}/sicilian-fiesta`)) {
				if (user?.role !== USER_ROLE_ROOFER) {
					setActiveKey('sicilian-fiesta');
					setActiveKeyName('Konkursy');
				} else {
					history.push('/');
				}
			} else {
				if (user !== null) {
					if (viewEnabled.news) {
						setActiveKey('news');
						setActiveKeyName('Aktualności');
						history.push(`${process.env.PUBLIC_URL}/news`);
					} else if (user?.role !== USER_ROLE_ROOFER)  {
						setActiveKey('orders');
						setActiveKeyName('Zamówienia');
						history.push(`${process.env.PUBLIC_URL}/orders`);
					} else {
						setActiveKey('promotions');
						setActiveKeyName('Wspólne korzyści');
						history.push(`${process.env.PUBLIC_URL}/promotions`);
					}
				}
			}

			const currentDate = new Date();
			const currentHour = currentDate.getHours();
			if (!user?.isAdmin && currentDate > currentOrIncomigServiceWorks.from) {
				setDisableMessage(currentOrIncomigServiceWorks.message);
				setIsDisabled(true);
			} else if (shutdownHours.from > shutdownHours.to && (shutdownHours.from <= currentHour || shutdownHours.to > currentHour)) {
				setDisableMessage(`System zamówień online jest niedostępny w godzinach ${shutdownHours.from}:00 - ${shutdownHours.to}:00.`);
				setIsDisabled(true);
			} else if (shutdownHours.from <= currentHour && shutdownHours.to > currentHour) {
				setDisableMessage(`System zamówień online jest niedostępny w godzinach ${shutdownHours.from}:00 - ${shutdownHours.to}:00.`);
				setIsDisabled(true);		
			} else {
				setIsDisabled(false);
			}
		}
	}, [location.pathname, viewEnabled, history, company, user, shutdownHours, serviceWorks, currentOrIncomigServiceWorks.from, currentOrIncomigServiceWorks.message]);

	useEffect(() => {
		document.documentElement.style.setProperty("--brand", brandColor);
		document.documentElement.style.setProperty("--brand-light", brandLightColor);
		document.documentElement.style.setProperty("--brand-bright", brandBrightColor);
		document.documentElement.style.setProperty("--table-font-color", tableFontColor);
	}, [brandColor, brandLightColor, brandBrightColor, tableFontColor]);

	useEffect(() => {
		dispatch(setSliceState(window.location.hostname, user === null ? null : Number(user.company)));
	}, [user, dispatch]);

	const consoleLog = console.log;

	useEffect(() => {
		if (store.getState().conf.companyId !== null) {
			if (!store.getState().conf.isDebug) {
				console.log = function () { };
			} else {
				console.log = consoleLog;
			}
		}
	});

	useEffect(() => {
		if (!location.pathname.includes('new-order')) {
			if (edit) {
				store.dispatch(dataActions.setDetailedOrder({} as DetailedOrder));
				store.dispatch(dataActions.clearLocalEditCart());
				store.dispatch(dataActions.setEdit(false));
			}
		}
		if (!location.pathname.includes('orders')) {
			store.dispatch(dataActions.setIsErrorGettingDetailedOrder(false));
		}
		setShowPromotionsDropdown(location.pathname.includes('promotions'));
		setShowAdminDropdown(location.pathname.includes('admin'));
		setVisibility((location.pathname.includes('isod')) ? 'block' : 'none');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	if (user === null) {
		if (cartReset === false) {
			setCartReset(true);
		}
		return (
			<>
				<RequestErrorModal />
				<Login />
			</>
		);
	}

	const logoutClick = async () => {
		setExpanded(false);
		logout();
		history.push(process.env.PUBLIC_URL);
	};

	return (
		<div className="App">
			<header className="App-header">
				<RequestErrorModal />
				{!isMedium && <NotificationComponent/>}  
				<div className="App-login-background">
					<div className="App-login-bar">
						{user.forcePwdChange && <Redirect to={`${process.env.PUBLIC_URL}/changePassword`} />}
						<span className="align-items-center">
							<span style={{ whiteSpace: "nowrap" }}>Zalogowany jako:&nbsp;</span> <b style={{ whiteSpace: "nowrap" }}>{user.username}</b>
						</span>
						{isMedium ? (
							<span>
								Opiekun: {user.guardian},
								<a style={{ marginLeft: '4px' }} href={`mailto:${user.guardianMail}`}>{user.guardianMail}</a>,
								<a style={{ marginLeft: '4px' }} href={`tel:${user.guardianPhone}`}>{user.guardianPhone}</a>
							</span>
						) : (<></>)}
						<span className="App-account-buttons align-items-center ">
							<Link to={`${process.env.PUBLIC_URL}/changePassword`}>
								<ButtonComponent
									whiteSpace="nowrap"
									marginBottom="0"
									marginTop="0"
									marginLeft="0"
									marginRight="0"
									text="Zmień hasło"
								/>
							</Link>
							<ButtonComponent
								onClick={logoutClick}
								marginBottom="0"
								marginTop="0"
								marginLeft="0"
								marginRight="0"
								text="Wyloguj"
							/>
						</span>
					</div>
				</div>
				{company !== null && <div className="App-logo-bar">
					<img src={`${process.env.PUBLIC_URL}/${logo}`} alt="Blachy Pruszyński" />
					<h1>System obsługi zleceń online</h1>
				</div>}
				<Navbar variant="dark" className={expanded && !isDesktop ? 'App-navbar-expanded' : 'App-navbar'} expand="lg" collapseOnSelect onToggle={() => setExpanded((val) => !val)}>
					<Container style={{ width: isMedium ? 'unset' : '100%' }}>
						<Navbar.Toggle />{!expanded && !isMedium ? <span className="active-key-name">{activeKeyName}</span> : ""}
						<Navbar.Collapse className="justify-content-center">
							<Nav fill variant="pills" activeKey={activeKey} className="mr-auto">
								{viewEnabled.news && <LinkItem to={`${process.env.PUBLIC_URL}/news`} eventKey="news">Aktualności</LinkItem>}
								{viewEnabled.orders && user?.role !== USER_ROLE_ROOFER && <LinkItem to={`${process.env.PUBLIC_URL}/orders`} eventKey="orders">Zamówienia</LinkItem>}
								{viewEnabled.new_order && user?.role !== USER_ROLE_ROOFER && <LinkItem to={`${process.env.PUBLIC_URL}/new-order`} eventKey="new-order">Nowe zamówienie</LinkItem>}
								{viewEnabled.billings && user?.role !== USER_ROLE_ROOFER && <LinkItem to={`${process.env.PUBLIC_URL}/settlements`} eventKey="settlements">Rozliczenia</LinkItem>}
								{viewEnabled.promotion && (isDesktop ? <LinkItem to={`${process.env.PUBLIC_URL}/promotions`} eventKey="promotions">Wspólne korzyści</LinkItem> :
									<NavDropdown renderMenuOnMount={true} title="Wspólne korzyści" id="promotion-dropdown" className={location.pathname.includes('promotions') ? 'App-navDropdown-active' : (showPromotionsDropdown ? 'App-navDropdown-show' : 'App-navDropdown')} >
										<NavDropdown.Item eventKey="1" className={location.pathname === `${process.env.PUBLIC_URL}/promotions/articles` ? 'App-navDropdownItem-active' : 'App-navDropdownItem'} as={Link} to={`${process.env.PUBLIC_URL}/promotions/articles`}>Artykuły promocyjne</NavDropdown.Item>
										<NavDropdown.Item eventKey="2" className={location.pathname === `${process.env.PUBLIC_URL}/promotions/orders` ? 'App-navDropdownItem-active' : 'App-navDropdownItem'} as={Link} to={`${process.env.PUBLIC_URL}/promotions/orders`}>Zamówienia na artykuły promocyjne</NavDropdown.Item>
										{(user?.role === USER_ROLE_MASTER || user?.role === USER_ROLE_TRADER) && <NavDropdown.Item eventKey="3" className={location.pathname === `${process.env.PUBLIC_URL}/promotions/register-roofer` ? 'App-navDropdownItem-active' : 'App-navDropdownItem'} as={Link} to={`${process.env.PUBLIC_URL}/promotions/register-roofer`}>Rejestruj dekarza</NavDropdown.Item>}
										{(user?.role === USER_ROLE_MASTER) && <NavDropdown.Item eventKey="4" className={location.pathname === `${process.env.PUBLIC_URL}/promotions/register-trader` ? 'App-navDropdownItem-active' : 'App-navDropdownItem'} as={Link} to={`${process.env.PUBLIC_URL}/promotions/register-trader`}>Rejestruj handlowca</NavDropdown.Item>}
										{(user?.role === USER_ROLE_MASTER) && <NavDropdown.Item eventKey="5" className={location.pathname === `${process.env.PUBLIC_URL}/promotions/manage-traders` ? 'App-navDropdownItem-active' : 'App-navDropdownItem'} as={Link} to={`${process.env.PUBLIC_URL}/promotions/manage-traders`}>Administracja handlowcami</NavDropdown.Item>}
										<NavDropdown.Item eventKey="6" className={location.pathname === `${process.env.PUBLIC_URL}/promotions/terms` ? 'App-navDropdownItem-active' : 'App-navDropdownItem'} as={Link} to={`${process.env.PUBLIC_URL}/promotions/terms`}>Regulamin</NavDropdown.Item>
										<NavDropdown.Item eventKey="7" className={location.pathname === `${process.env.PUBLIC_URL}/promotions/promotion-regulations` ? 'App-navDropdownItem-active' : 'App-navDropdownItem'} as={Link} to={`${process.env.PUBLIC_URL}/promotions/promotion-regulations`}>Zasady działania programu promocyjnego</NavDropdown.Item>
									</NavDropdown>)}
								{viewEnabled.isod && user?.role !== USER_ROLE_ROOFER && !isPhone &&
									<LinkItem to={`${process.env.PUBLIC_URL}/isod`} eventKey="isod">ISOD</LinkItem>
								}
								{viewEnabled.account && (user?.role !== USER_ROLE_MASTER && <LinkItem to={`${process.env.PUBLIC_URL}/account`} eventKey="account">Konto</LinkItem>)}
								{user?.isAdmin && (isDesktop ? <LinkItem to={`${process.env.PUBLIC_URL}/admin`} eventKey="admin">Admin</LinkItem> :
									<NavDropdown renderMenuOnMount={true} title="Admin" id="admin-dropdown" className={location.pathname.includes('admin') ? 'App-navDropdown-active' : (showAdminDropdown ? 'App-navDropdown-show' : 'App-navDropdown')} >
										<NavDropdown.Item eventKey='1a' className={location.pathname === `${process.env.PUBLIC_URL}/admin/admins` ? 'App-navDropdownItem-active' : 'App-navDropdownItem'} as={Link} to={`${process.env.PUBLIC_URL}/admin/admins`}>Administratorzy</NavDropdown.Item>
										<NavDropdown.Item eventKey='2a' className={location.pathname === `${process.env.PUBLIC_URL}/admin/service-works` ? 'App-navDropdownItem-active' : 'App-navDropdownItem'} as={Link} to={`${process.env.PUBLIC_URL}/admin/service-works`}>Prace Serwisowe</NavDropdown.Item>
										<NavDropdown.Item eventKey='3a' className={location.pathname === `${process.env.PUBLIC_URL}/admin/test-results` ? 'App-navDropdownItem-active' : 'App-navDropdownItem'} as={Link} to={`${process.env.PUBLIC_URL}/admin/test-results`}>Wyniki testów</NavDropdown.Item>
										<NavDropdown.Item eventKey='4a' className={location.pathname === `${process.env.PUBLIC_URL}/admin/assortment-graphics` ? 'App-navDropdownItem-active' : 'App-navDropdownItem'} as={Link} to={`${process.env.PUBLIC_URL}/admin/assortment-graphics`}>Grafiki asortymentu</NavDropdown.Item>
										<NavDropdown.Item eventKey='5a' className={location.pathname === `${process.env.PUBLIC_URL}/admin/cache-management` ? 'App-navDropdownItem-active' : 'App-navDropdownItem'} as={Link} to={`${process.env.PUBLIC_URL}/admin/cache-management`}>Cache</NavDropdown.Item>
										<NavDropdown.Item eventKey='6a' className={location.pathname === `${process.env.PUBLIC_URL}/admin/statistics` ? 'App-navDropdownItem-active' : 'App-navDropdownItem'} as={Link} to={`${process.env.PUBLIC_URL}/admin/statistics`}>Statystyki</NavDropdown.Item>
									</NavDropdown>)}
								{user?.role !== USER_ROLE_ROOFER && <LinkItem to={`${process.env.PUBLIC_URL}/sicilian-fiesta`} eventKey="sicilian-fiesta">Konkursy</LinkItem>}
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			</header>
			<main className="App-body-container">
				{company !== null && <div className="App-body">
					{
						isDisabled ? <Jumbotron className='App-disabled'>
							<Container><h1>System zamówień online jest niedostępny</h1><span>{disableMessage}</span></Container>
						</Jumbotron> :
						tooManyAttempts ? <Spinner showError={true} errMsg={'Zbyt wiele żądań, spróbuj ponownie za minutę.'} /> :
						invalidToken ? <Spinner showError={true} errMsg={'Sesja wygasła, zaloguj się ponownie.'} button={{label: 'Powrót do logowania', onClick: logoutClick} as SpinnerButtonType}/> :
						<Switch>
							<Route path={`${process.env.PUBLIC_URL}/orders/:page`}>
								{viewEnabled.orders && user?.role !== USER_ROLE_ROOFER && <Orders />}
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/orders`}>
								{viewEnabled.orders && user?.role !== USER_ROLE_ROOFER && <Orders />}
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/new-order`}>
								{viewEnabled.orders && user?.role !== USER_ROLE_ROOFER && <NewOrder cartReset={cartReset} setCartReset={setCartReset} />}
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/settlements/:page`}>
								{viewEnabled.orders && user?.role !== USER_ROLE_ROOFER && <Settlements />}
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/settlements`}>
								{viewEnabled.orders && user?.role !== USER_ROLE_ROOFER && <Settlements />}
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/promotions`}>
								{viewEnabled.promotion && <Promotions />}
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/isod`}>
								{viewEnabled.isod && user?.role !== USER_ROLE_ROOFER && !isPhone && <ISOD visibility={isISODVisible} />}
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/account`}>
								{viewEnabled.account && user?.role !== USER_ROLE_MASTER && <Account />}
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/cookie-policy`}>
								<CookiePolicy />
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/news/:page`}>
								{viewEnabled.news && <News />}
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/news/edit/:id`}>
								{viewEnabled.news && <News />}
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/news`}>
								{viewEnabled.news && <News />}
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/changePassword`}>
								<ChangePassword />
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/admin`}>
								{user.isAdmin && <Admin />}
							</Route>
							<Route path={`${process.env.PUBLIC_URL}/sicilian-fiesta`}>
								{user?.role !== USER_ROLE_ROOFER && <SicilianFiesta/>}
							</Route>
							<Route>
								{ viewEnabled.news ? <News /> : <Promotions /> }
							</Route>
						</Switch>
					}
				</div>}
			</main>
			<footer className="App-footer">
				<div className="App-footer-content">
					<Link to={`${process.env.PUBLIC_URL}/cookie-policy`}>
						<Button variant="link" className="text-white font-weight-light">
							Polityka cookies
						</Button>
					</Link>
					<div style={{ display: 'flex' }}>
						{user.environment === 'dev' ? <div className="text-white font-weight-light">Webserwis testowy</div> : <></>}
						{user.isAdmin || user.role === USER_ROLE_MASTER ? <div className="version-number text-white">
							{process.env.REACT_APP_VERSION}
						</div> : ''}
					</div>
				</div>
			</footer>
		</div >
	);
}
