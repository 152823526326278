import React, { useState, useEffect } from 'react';
import { Table, Form, Spinner as Spin } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { LG } from 'lib/util';
import '../Promotion.scss';
import Collapse from 'react-bootstrap/Collapse';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import _ from 'lodash';
import { getSalesAgents, activateSalesAgent, blockSalesAgent } from 'lib/communication/promotionalProgram';
import Spinner from 'components/Spinner/Spinner';
import { FaLock } from 'react-icons/fa';

const ManageTraders = () => {
	const isFetching = useSelector((state: AppState) => state.data.promotionalProgram.props.isFetching);
	const traderBlockIsFetching = useSelector((state: AppState) => state.data.promotionalProgram.props.traderBlockIsFetching);
	const tradersList = useSelector((state: AppState) => state.data.promotionalProgram.salesAgents);
	const [error, setError] = useState(false);
	const [dataFetched, setDataFetched] = useState(false);
	const isDesktop = useMediaQuery({ minWidth: LG });
	let opensArr = _.fill(Array(tradersList.length), false, 0, tradersList.length);
	const [opens, setOpens] = useState(opensArr);
	const [indexForSpinner, setIndexForSpinner] = useState(-1);

	useEffect(() => {
		getSalesAgents().then(result => {
			setError(!result);
			setDataFetched(true);
		})
	}, []);

	if ((!dataFetched && isFetching) || error) {
		return <Spinner showError={error} />
	}

	return <>
		<h2>Administracja handlowcami</h2>
		{isDesktop ?
			<Table borderless striped>
				<thead>
					<tr>
						<td>Nr</td>
						<td>Logo</td>
						<td>Login</td>
						<td>Imię</td>
						<td>Nazwisko</td>
						<td>Data rejestracji</td>
						<td>Zablokowany</td>
					</tr>
				</thead>
				<tbody>
					{tradersList.map((trader, index) =>
						<tr key={index}>
							<td>{index}</td>
							<td>{trader.logo}</td>
							<td>{trader.login}</td>
							<td>{trader.firstName}</td>
							<td>{trader.lastName}</td>
							<td>{trader.date.substring(0,10)}</td>
							<td><Form.Check onClick={() => {
								setIndexForSpinner(index);
								trader.blocked ? activateSalesAgent(trader) : blockSalesAgent(trader)
							}}
								type="switch"
								id={'ManageTraders-suspended-' + index}
								label={traderBlockIsFetching && (indexForSpinner === index) ? <Spin animation="border" size="sm" /> : (trader.blocked ? 'Tak' : 'Nie')}
								checked={trader.blocked}
								readOnly /></td>
						</tr>
					)}
				</tbody>
			</Table>
			:
			<div>
				{tradersList.map((trader, index) =>
					<div key={index} className={"block-item" + (opens[index] ? " rotate" : "")} onClick={() => {
						const arr = opens.splice(0);
						arr[index] = !arr[index];
						setOpens(arr);
					}}>
						<div>
							<span>{trader.login} &nbsp; {trader.blocked && <FaLock />}</span>
							<b>{trader.firstName} {trader.lastName}</b>
						</div>
						<Collapse in={opens[index]}>
							<section>
								<div>
									<span>Nr:</span>
									<hr />
									<b>{index}</b>
								</div>
								<div>
									<span>Logo:</span>
									<hr />
									<b>{trader.logo}</b>
								</div>
								<div>
									<span>Login:</span>
									<hr />
									<b>{trader.login}</b>
								</div>
								<div>
									<span>Imię:</span>
									<hr />
									<b>{trader.firstName}</b>
								</div>
								<div>
									<span>Nazwisko:</span>
									<hr />
									<b>{trader.lastName}</b>
								</div>
								<div>
									<span>Data rejestracji:</span>
									<hr />
									<b>{trader.date.substring(0,10)}</b>
								</div>
								<div className="block-trader-mobile">
									<span>Zablokowany:</span>
									<hr />
									<div>
										<Form.Check onClick={() => {
											setIndexForSpinner(index);
											trader.blocked ? activateSalesAgent(trader) : blockSalesAgent(trader)
										}}
											type="switch"
											id={'ManageTraders-suspended-' + index}
											checked={trader.blocked}
											className="block-trader-mobile-switch"
											readOnly />
										<b>
											{traderBlockIsFetching && (indexForSpinner === index) ? <Spin animation="border" size="sm" /> : (trader.blocked ? 'Tak' : 'Nie')}
										</b>
									</div>
								</div>
							</section>
						</Collapse>
					</div>
				)
				}
			</div>
		}
	</>;
}

export default ManageTraders;