import _ from 'lodash';
import React from "react";
import { forwardRef } from "react";
import { TooltipProps } from 'react-bootstrap';

interface OrderTooltipProps extends TooltipProps {
	messages: Record<string, string>
}

const OrderTooltip = forwardRef<HTMLDivElement, OrderTooltipProps>((props, ref) => {
	return <div ref={ref} {...props} className="OrderTooltip">
		{_.map(props.messages, (text, label) => (
			text ? <div className="OrderTooltip_message">
				<p className="OrderTooltip_message_label">{label}</p>
				<p className="OrderTooltip_message_text">{text}</p>
			</div> : null
		))}
	</div>;
});

export default OrderTooltip