import { GutteringSystemDataV2 } from "lib/types";
import { dataActions } from "reducers";
import store from "store";

export async function setGutterCreatorFilter(filter: { filterName: string; value: string; }) {
	store.dispatch(dataActions.setGutterCreatorFilter(filter));
}

export async function setGuttertoBeUpdated(value: boolean){
	store.dispatch(dataActions.setGutterToBeUpdated(value));
}

export async function setGutterCreatorFilters(filters: GutteringSystemDataV2) {
	store.dispatch(dataActions.setGutterCreatorFilters(filters));
}