import { addMonths } from 'date-fns';

// bootstrap breakpoints
export const SM = 576;
export const MD = 768;
export const LG = 992;
export const XL = 1200;

export const USER_ROLE_MASTER = 'master';
export const USER_ROLE_TRADER = 'handlowiec';
export const USER_ROLE_ROOFER = 'dekarz';

export const DATE_ERROR_START = "Data publikacji nie może być pusta.";
export const DATE_ERROR_END = "Data zakończenia wyświetlania musi być większa od daty publikacji.";

export const MAX_ORDER_DESC_LENGTH = 200;
export const MAX_ORDER_OWN_NUMBER = 35;

export const MAX_ADDRESS_STREET_NAME_LENGTH = 100;
export const MAX_ADDRESS_HOUSE_NUMBER_LENGTH = 10;
export const MAX_ADDRESS_APARTMENT_NUMBER_LENGTH = 10;
export const MAX_ADDRESS_TOWN_LENGTH = 100;
export const MAX_ADDRESS_DESC_LENGTH = 200;

export const getTodayDate = () => {
	const today = new Date();
	const todayDate = today.toDateString();
	return todayDate;
}

export const compareDates = (dateStartString: string | null, dateEndString: string | null, isEqual: boolean) => {
	let dateStart = dateStartString !== null ? new Date(dateStartString) : new Date();
	let dateEnd = dateEndString !== null ? new Date(dateEndString) : new Date();

	dateStart.setHours(0, 0, 0, 0);
	dateEnd.setHours(0, 0, 0, 0);

	return isEqual ? dateStart <= dateEnd : dateStart < dateEnd;
}

export const getLastMonthDate = () => {
	const today = new Date();
	const last = addMonths(today, -1);
	const lastMonthDate = last.toDateString();
	return lastMonthDate;
}

export const isSafari = () => {
	const userAgentString = navigator.userAgent;
	let chromeAgent = userAgentString.indexOf("Chrome") > -1;
	let safariAgent = userAgentString.indexOf("Safari") > -1;
	if ((chromeAgent) && (safariAgent)) safariAgent = false;
	return safariAgent;
}

export const getFormatedDate = (d: Date): string => {
	let year = `${d.getFullYear()}`;
	let monthNr = d.getMonth() + 1;
	let month = monthNr < 10 ? `0${monthNr}` : `${monthNr}`;
	let dayNr = d.getDate();
	let day = dayNr < 10 ? `0${dayNr}` : `${dayNr}`;
	return `${year}-${month}-${day}`;
}

export const getFormattedDateWithHoursAndMinutes = (d: Date): string => {
	const year = d.getFullYear();
	const month = String(d.getMonth() + 1).padStart(2, '0');
	const day = String(d.getDate()).padStart(2, '0');
	const hours = String(d.getHours()).padStart(2, '0');
	const minutes = String(d.getMinutes()).padStart(2, '0');
	return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export const getCurrentTimeStamp = () => {
	const d = new Date();
	const year = d.getFullYear();
	const month = String(d.getMonth() + 1).padStart(2, '0');
	const day = String(d.getDate()).padStart(2, '0');
	const hours = String(d.getHours()).padStart(2, '0');
	const minutes = String(d.getMinutes()).padStart(2, '0');
	return `${year}${month}${day}${hours}${minutes}`;
}

export const getWeekDay = (d: Date): string => {
	const weekday = ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"];

	return weekday[d.getDay()];
}