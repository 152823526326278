import { User } from 'lib';
import { Param } from 'lib/types';
import _ from 'lodash';

export const userService = {
	get,
	set,
	getUser,
	logout,
	getUserParams,
	getUserParam,
	updateUserParamsInSessionStorage,
};

export const USER_LOCAL_STORAGE = 'user';

export type ParamTypes = 'DefaultNewOrderView' | 'DefaultNewOrderViewMobile' | 'DefWindow' | 'DefZamPageCount';

export const NEW_ORDER_VIEW = 'DefaultNewOrderView';
export const NEW_ORDER_VIEW_MOBILE = 'DefaultNewOrderViewMobile';
export const DEF_WINDOW = 'DefWindow';
export const ORD_PAGE_COUNT = 'DefZamPageCount';

function get(): User | null {
	const user = sessionStorage.getItem(USER_LOCAL_STORAGE);
	return user === null ? null : (JSON.parse(user) as User);
}

function set(user: User | null) {
	user === null ? logout() : sessionStorage.setItem(USER_LOCAL_STORAGE, JSON.stringify(user));
}

function getUser(): User {
	const user = sessionStorage.getItem(USER_LOCAL_STORAGE);
	if (!user) {
		throw new Error("Cannot retrieve user from sessionStorage");
	}
	return JSON.parse(user) as User;
}

function logout() {
	sessionStorage.removeItem(USER_LOCAL_STORAGE);
}

function getUserParams() {
	const user = sessionStorage.getItem(USER_LOCAL_STORAGE);
	if (!user) {
		throw new Error("Cannot retrieve user from sessionStorage");
	}
	return (JSON.parse(user) as User).params as Param[];
}

function getUserParam(key: ParamTypes) {
	const user = sessionStorage.getItem(USER_LOCAL_STORAGE);
	if (!user) {
		return null;
	}
	const param = _.filter(((JSON.parse(user) as User).params as Param[]),{'ParamName': key});
	return param[0].ParamValue;	
}

function updateUserParamsInSessionStorage(params: Param[]) {
	let user = sessionStorage.getItem(USER_LOCAL_STORAGE);
	if (!user) {
		throw new Error("Cannot retrieve user from sessionStorage");
	}
	const updatedUser: User = (JSON.parse(user) as User);
	updatedUser.params = params;
	sessionStorage.setItem('user', JSON.stringify(updatedUser));
}