import React, { useState, useEffect, useCallback } from 'react';
import { ListGroup, Button } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { FaEdit } from 'react-icons/fa';
import { BiArchiveIn, BiArchiveOut } from 'react-icons/bi';
import NewsEditor from './Editor';
import { useUser, AppState, dataActions } from 'reducers';
import _ from 'lodash';
import Pagination from 'components/Pagination';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getNews, removeLinks, modifyNews, addNews, archiveNews, unarchiveNews } from 'lib/communication/news';
import Spinner from 'components/Spinner';
import { News as NewsType } from 'lib/types';
import './News.scss';
import { buildResourceLink } from 'lib/communication';
import { useMediaQuery } from 'react-responsive';
import { LG } from 'lib/util';
import ButtonComponent from 'components/ButtonComponent';
import store from 'store';

export const PAGE_SIZE = 3;

type NewsState = {
	edited: number | null,
	added: Boolean,
};

const NEWS_STATE: NewsState = {
	edited: null,
	added: false,
};

export default function News() {
	const isDesktop = useMediaQuery({ minWidth: LG });

	const history = useHistory();

	const state: NewsState = history.location.state as NewsState || NEWS_STATE;

	const { page } = useParams<{ page: string | undefined }>();

	const news = useSelector((state: AppState) => state.data.news.news);
	const totalCount = useSelector((state: AppState) => state.data.news.totalCount);
	const newsIsFetching = useSelector((state: AppState) => state.data.news.props.isFetching);
	const companyId = useSelector((state: AppState) => state.conf.companyId);
	const user = useUser();

	const [edited, setEdited] = useState<number | null>(null);
	const [added, setAdded] = useState<Boolean>(false);
	const [archievedVisible, setArchievedVisible] = useState(user?.isAdmin ? true : false);
	const [editedChange, setEditedChange] = useState(false);
	const [addedChange, setAddedChange] = useState(false);
	const [error, setError] = useState(false);
	const [bottomPagVisibility, setBottomPagVisibility] = useState(false);

	const pageNumber = parseInt(page ?? '1') - 1;
	const size = Math.max(1, _.ceil(totalCount / PAGE_SIZE));

	const newsModify = async (val: NewsType) => {
		if (companyId) {
			let newLinks = val.links;
			_.forEach(val.links, (link) => {
				if (!val.content.includes(link)) {
					newLinks = newLinks.filter(e => e !== link);
					const newNews: NewsType = { ...val, links: newLinks };
					store.dispatch(dataActions.updateNews(newNews));
					removeLinks(val.id !== undefined ? val.id : -1, link);
				}
			})
			await modifyNews(val, companyId);
			newsGet();
			setEditedChange(true);
		}
	}

	const newsAdd = async (val: NewsType) => {
		if (companyId) {
			await addNews(val, companyId);
			newsGet();
			setAddedChange(true);
		}
	}

	const newsGet = useCallback(() => {
		if (companyId) {
			getNews(companyId, pageNumber, archievedVisible)
				.then(result => setError(!result))
				.then(handleResize);	
		}
	}, [companyId, pageNumber, archievedVisible])

	useEffect(() => {
		setEdited(state.edited);
		setAdded(state.added);
	}, [state.edited, state.added]);

	useEffect(() => {
		if (companyId) {
			newsGet();
		}
		if (editedChange || addedChange) {
			setEditedChange(false);
			setAddedChange(false);
		}
	}, [companyId, pageNumber, newsGet, editedChange, addedChange]);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
	}, []);

	const getNewsContent = (news: NewsType) => {
		let content = news.content;
		_.forEach(news.links, (link) => {
			content = content.replace(link, buildResourceLink(link));
		})
		return content;
	}

	const handleResize = () => {
		if (document.body.clientWidth < window.innerWidth) {
			setBottomPagVisibility(true);
		} else {
			setBottomPagVisibility(false);
		}
	}

	const checkNewsDate = (dateStart: string, dateEnd: string) => {
		if(dateEnd == null && dateStart == null){
			return false;
		}
		const dS = new Date(dateStart);
		const dE = new Date(dateEnd);
		const dN = new Date(Date.now());
		if(dS < dN && dateEnd == null){
			return false;
		}
		if(dE < dN|| dS > dN){
			return true;
		}
		return false;
	}

	if (newsIsFetching || error) {
		return <Spinner showError={error} />;
	}

	if (edited !== null) {
		const editNews = _.findLast(news, (e) => e.id === edited);
		if (editNews !== undefined) {
			return (
				<NewsEditor
					news={editNews}
					onSubmit={(val) => {
						if (companyId) {
							newsModify(val);
							history.goBack();
						}
					}}
					onCancel={() => history.goBack()}
				/>
			);
		}
	}

	if (added) {
		return (
			<NewsEditor
				onSubmit={(val) => {
					if (companyId) {
						newsAdd(val);
						history.goBack();
					}
				}}
				onCancel={() => history.goBack()}
			/>
		);
	}

	return (
		<div className="news-container">
			{user?.isAdmin && (
				<div className= {isDesktop ? "news-admin-buttons d-flex" : "news-admin-buttons d-flex justify-content-center"}>
					<ButtonComponent text="Dodaj" onClick={() => {
						history.push(`${process.env.PUBLIC_URL}${history.location.pathname}`, {
							...state,
							added: true
						});
					}} />
					<ButtonComponent text={!archievedVisible ? "Pokaż zarchiwizowane" : "Ukryj zarchiwizowane"}
						onClick={() => setArchievedVisible(!archievedVisible)} />
				</div>
			)}
			<div className={isDesktop ? "d-flex justify-content-end" : "d-flex justify-content-center"}>
				<Pagination
					selected={pageNumber}
					size={size}
					href={(page) => `${process.env.PUBLIC_URL}/news/${page + 1}`}
					onSelect={(page) => history.push(`${process.env.PUBLIC_URL}/news/${page + 1}`)}
				/>
			</div>
			<ListGroup variant="flush" className="text-left" id="news">
				{_.map(news, (news) => (
					<ListGroup.Item as="article" key={news.id} className={news.isArchived ? 'archived-news' : (checkNewsDate(news.dateStart, news.dateEnd) ? 'expired-news' : undefined)}>
						<h3>
							{!!news.isArchived && (<>
								<b>[Zarchiwizowano]</b>
								<Button
									title="Cofnij archiwizację"
									variant="link"
									onClick={() => {
										unarchiveNews(news.id!)
									}}
								>
									<BiArchiveOut />
								</Button>
								<br />
							</>)}
							{!news.isArchived && archievedVisible &&(<>
								<b>[Widoczny{news.dateStart != null ? ' od ' + news.dateStart : undefined}{news.dateEnd != null ? ' do ' + news.dateEnd : undefined}]</b>
								<br></br></>)}
							{news.title}
							{user?.isAdmin && !news.isArchived && (<>
								<Button
									title="Edytuj"
									variant="link"
									onClick={() => {
										history.push(`${process.env.PUBLIC_URL}${history.location.pathname}`, {
											...state,
											edited: news.id === undefined ? null : news.id
										});
									}}
								>
									<FaEdit />
								</Button>
								<Button
									title="Archiwizuj"
									variant="link"
									onClick={() => {
										archiveNews(news.id!)
									}}
								>
									<BiArchiveIn />
								</Button>
							</>)}
						</h3>
						<p className="text-muted">{news.dateStart}</p>
						<ReactMarkdown source={getNewsContent(news)} escapeHtml={false} />
					</ListGroup.Item>
				))}
			</ListGroup>
			{bottomPagVisibility && (
				<div className={isDesktop ? "d-flex justify-content-end" : "d-flex justify-content-center"}>
					<Pagination
						selected={pageNumber}
						size={size}
						href={(page) => `${process.env.PUBLIC_URL}/news/${page + 1}`}
						onSelect={(page) => history.push(`${process.env.PUBLIC_URL}/news/${page + 1}`)}
					/>
				</div>
			)}
		</div>
	);
}
