import { createSlice } from '@reduxjs/toolkit';

const requestsSlice = createSlice({
	name: 'requests',
	initialState: {
		invalidToken: false,
		tooManyAttempts: false,
		responseError: false,
		errorMessage: 'Niezidentyfikowany błąd'
	},
	reducers: {
		setInvalidToken(state, action) {
			state.invalidToken = action.payload;
		},
		setTooManyAttempts(state, action) {
			state.tooManyAttempts = action.payload;
		},
		setResponseError(state, action) {
			state.responseError = action.payload;
		},
		setErrorMessage(state, action) {
			state.errorMessage = action.payload;
			state.responseError = true;
		}
	},
});

export default requestsSlice.reducer;

export const requestsActions = requestsSlice.actions;