import { buildResourceLink } from 'lib/communication';
import React, { useState } from 'react';
import { useUser } from 'reducers';
import './Cards.scss'
import { Button } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import EditImageModalCard from './EditImageModalCard';

interface ProductCardProps {
	click: () => void;
	description: string | null;
	imgSrc: string | null;
	imgAlt: string;
	path: string;
}

const ProductCard = (props: ProductCardProps) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const user = useUser();

	return (
		<div className="Cards-category" onClick={() => { if (!isModalOpen) props.click() }}>
			<EditImageModalCard path={props.path} setModalVisibility={(visibility: boolean) => { setIsModalOpen(visibility) }} isModalOpen={isModalOpen} name={props.description as string} />
			<h3>{props.description}</h3>
			<img
				src={props.imgSrc ? buildResourceLink(props.imgSrc) : `${process.env.PUBLIC_URL}/images/ikona.jpg`}
				alt={props.imgAlt}
			/>
			{user?.isAdmin && (
				<div className="custom-row">
					<p className="file-name">
						Nazwa pliku: {props.path}
					</p>
					<div className="edit-button">
						<Button
							variant="outline"
							title="Edytuj"
							onClick={(e) => {
								e.stopPropagation();
								setIsModalOpen(true);
							}}
						>
							<FaEdit />
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};


export default ProductCard;