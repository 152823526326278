import React, { useEffect, useRef, useState } from 'react';
import { Form, OverlayTrigger, Popover, PopoverContent, PopoverTitle, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import ProductDescriptionDetails from '../Cards/ProductDescriptionDetails';
import { useMediaQuery } from 'react-responsive';
import { LG } from 'lib/util';
import _ from 'lodash';
import { FoundProduct, Product, ProductsGroup } from 'lib/types';
import TreeSelect from './TreeSelect';
import { TreeNode } from 'lib/tree';
import { firstNGroups } from './AssortmentSelection';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'reducers';
import './AssortmentSelection.scss';
import { dataActions } from 'reducers';
import { Spinner as Spin } from 'react-bootstrap';
import ButtonComponent from 'components/ButtonComponent';
import { AiOutlineInfo } from "react-icons/ai";
import useDebounce from 'lib/hooks/useDebounce';
import { searchProducts } from 'lib/communication/orders';
import ListSelect from './ListSelect';


interface AdvancedViewProps {
	error: boolean;
	tree: TreeNode[] | null;
	findInTree: string;
	selectedPath: string;
	selectedProduct: Product | null;
	setFindInTree: (_: string) => void
	toggleTreeNode: (key: string) => void;
	onUpdate: (productSymKar: string, path: string[]) => void;
	focusFirstInput: () => void;
}

export default function AdvancedView({
	error, tree, findInTree, selectedProduct, selectedPath,
	setFindInTree, toggleTreeNode, onUpdate, focusFirstInput
}: AdvancedViewProps) {

	const dispatch = useDispatch()

	const isDesktop = useMediaQuery({ minWidth: LG });
	const groups = useSelector((state: AppState) => state.data.orders.groupsAndProducts.groups);
	const advancedTree = useSelector((state: AppState) => state.data.orders.advancedTree);

	const [showTree, setShowTree] = useState<Boolean>(false);

	const [searchMode, setSearchMode] = useState<string>('products');
	const [findProduct, setFindProduct] = useState<string>('');
	const [foundProducts, setFoundProducts] = useState<FoundProduct[]>([]);
	const [productsListMessage, setProductsListMessage] = useState<string | null>(null);
	const [findInTreeBackup, setFindInTreeBackup] = useState<string>('');

	const debouncedFindProduct = useDebounce(findProduct);

	const groupsInputRef = useRef<HTMLInputElement | null>(null);
	const productsInputRef = useRef<HTMLInputElement | null>(null);

	const treeRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!advancedTree && groups) {
			createAdvancedTree();
		}
		// eslint-disable-next-line
	}, [dispatch, groups, advancedTree]);

	useEffect(() => {
		searchProductsByName();
	// eslint-disable-next-line
	}, [debouncedFindProduct]);
	
	const searchProductsByName = async () => {
		if (debouncedFindProduct.length === 0) {
			setFoundProducts([]);
			setProductsListMessage("Wprowadź nazwę produktu");
		} else {
			setProductsListMessage(null);
			const products = await searchProducts(debouncedFindProduct);
			if (products) {
				setProductsListMessage(null);
				setFoundProducts(products);
			} else {
				setProductsListMessage("Błąd pobierania listy produktów.");
			}
		}
	}

	const createAdvancedTree = () => {
		// Get all root paths that have products
		const paths = _.uniq(
			_.map(
				_.filter(groups, (group) => group.hasProducts),
				(group) => firstNGroups(group.path, 2)
			)
		);
		
		const groupArray = [...groups];
		const rootGroups: ProductsGroup[] = _.filter(groups, group => _.some(paths, path => path === group.path));
		
		removeItems(groupArray, rootGroups);
		// Map children for each root node
		const tree = _.map(rootGroups, (group, index) => {
			return {
				...group,
				key: `${group.path}`,
				expanded: false,
				childrenNodes: findNodeChildren(1, group.path, groupArray),
			};
		});
		dispatch(dataActions.setAdvancedTree(tree));
	};

	const removeItems = (arr: ProductsGroup[], values: ProductsGroup[]) => {
		for (const value of values) {
			const index = arr.indexOf(value);
			if (index > -1) {
				arr.splice(index, 1);
			}
		}
	}

	const renderTooltip = () => (
		<OverlayTrigger
			key='right'
			placement='right'
			overlay={
				<Popover id="popover-positioned-right">
					<PopoverTitle as="h3">Nawigacja klawiaturą</PopoverTitle>
					<PopoverTitle as="h3">Pole wyszukiwania</PopoverTitle>
					<PopoverContent>
					<div className='key-group'><div className="key-box">A - Ż</div>,<div className="key-box">0 - 9</div>,<div className="key-box">Space</div></div><div className='key-description'>Wpisywanie w polu wyszukiwania.</div>
						<div className='key-group'><div className="key-box">Tab</div></div> <div className='key-description'>Przejście do nawigacji po drzewie.</div>
					</PopoverContent>
					<PopoverTitle as="h3">Drzewo</PopoverTitle>
					<PopoverContent>
						<div className='key-group'><div className="key-box">↑</div>, <div className="key-box">↓</div></div> <div className='key-description'>Poruszanie się w górę i w dół drzewa.</div>
						<div className='key-group'><div className="key-box">Shift + Tab</div>,<div className="key-box">Tab</div></div> <div className='key-description'>Poruszanie się w górę i w dół drzewa.</div>
						<div className='key-group'><div className="key-box">→</div></div> <div className='key-description'>Rozwijanie gałęzi lub wybranie produktu z przejściem do pola parametrów produktu.</div>
						<div className='key-group'><div className="key-box">←</div></div> <div className='key-description'>Zwinięcie gałęzi.</div>
						<div className='key-group'><div className="key-box">Enter</div></div> <div className='key-description'>Wybranie produktu z przejściem do pola parametrów produktu.</div>
						<div className='key-group'><div className="key-box">Backspace</div></div> <div className='key-description'>Powrót do pola wpisywania nazwy.</div>
					</PopoverContent>
					<PopoverTitle as="h3">Pole parametrów produktu</PopoverTitle>
					<PopoverContent>
						<div className='key-group'><div className="key-box">↑</div>, <div className="key-box">↓</div></div> <div className='key-description'>Zwiększanie i zmniejszanie wartości pól numerycznych.</div>
						<div className='key-group'><div className="key-box">Shift + Tab</div>,<div className="key-box">Tab</div></div> <div className='key-description'>Przeskakiwanie do poprzedniego lub następnego pola zaznaczania/wpisywania.</div>
						<div className='key-group'><div className="key-box">Space</div></div> <div className='key-description'>Zmienianie wartości pól typu check-box.</div>
						<div className='key-group'><div className="key-box">Enter</div></div> <div className='key-description'>Dodawanie produktu do koszyka z wybranymi parametrami (Ilość, Wymiar i Folia).</div>
					</PopoverContent>
				</Popover>
			}
		>
			<div className="navigation-info key-box" ><AiOutlineInfo size="20"/></div>
		</OverlayTrigger>
	);

	const findNodeChildren = (levelIndex: number, parentPath: string, restGroups: ProductsGroup[]): TreeNode[] => {
		const paths = _.uniq(
			_.map(
				_.filter(restGroups, (group) => group.hasProducts && parentPath === firstNGroups(group.path, levelIndex + 1)),
				(group) => firstNGroups(group.path, levelIndex + 2)
			)
		);
		const children: ProductsGroup[] = _.filter(restGroups, group => _.some(paths, path => path === group.path));
		const filteredByLevel = _.filter(children, (group) => group.level === levelIndex + 2);
		
		removeItems(restGroups, filteredByLevel);
		return filteredByLevel.length ? _.map(filteredByLevel, (child) => {
			return {
				...child,
				key: `${child.path}`,
				expanded: false,
				childrenNodes: findNodeChildren(levelIndex + 1, child.path, restGroups),
			};
		}) : [];
	};

	const switchMode = (val: any) => {
		if (val === 'products') {
			setFindInTreeBackup(findInTree);
			setFindInTree('');
		} else if (val === 'groups') {
			setFindInTree(findInTreeBackup);
		}

		setSearchMode(val);
	}

	return isDesktop ? (
		<>
			<>
				<div className="AssortmentSelection-SearchBar">
					{searchMode==='groups' ? <Form.Control id="searchGroups" type="text" placeholder="Wyszukaj grupę"
						value={findInTree}
						onChange={(event) => setFindInTree(event.target.value)} 
						ref={groupsInputRef}
					/> : <Form.Control id="searchProducts" type="text" placeholder="Wyszukaj produkt"
						value={findProduct}
						onChange={(event) => setFindProduct(event.target.value)} 
						ref={productsInputRef}
					/>}
					<ToggleButtonGroup 
						type="radio" 
						className="AssortmentSelection-searchMode"
						value={searchMode} 
						name="searchMode" 
						onChange={switchMode}
					>
						<ToggleButton id="tbg-btn-1" className="AssortmentSelection-searchMode-toggleButton" value="groups">
							GRUPY
						</ToggleButton>
						<ToggleButton id="tbg-btn-2" className="AssortmentSelection-searchMode-toggleButton" value="products">
							PRODUKTY
						</ToggleButton>
					</ToggleButtonGroup>
					{(searchMode==='groups' || (searchMode==='products' && findProduct.length === 0)) && <div className='AssortmentSelection-navigation-info'>{renderTooltip()}</div>}
				</div>
				<div className="AssortmentSelection-tree" ref={treeRef}>
					{(searchMode==='groups' || (searchMode==='products' && findProduct.length === 0)) ? <TreeSelect
						error={error}
						tree={tree}
						selectedPath={selectedPath}
						selectedProduct={selectedProduct}
						toggleTreeNode={toggleTreeNode}
						onUpdate={onUpdate}
						focusFirstInput={focusFirstInput}
						treeRef={treeRef}
						textInputRef={groupsInputRef}
					/> : <ListSelect 
						message={productsListMessage}
						products={foundProducts}
					/>}
				</div>
			</>
			<>
				{!selectedProduct ? (
					<div className="AssortmentSelection-AdvancedView-Title">
						<h2>Wybierz produkt z listy</h2>
					</div>
				) : (
					<>
						<div className="AssortmentSelection-AdvancedView-ProductDescription">
							<ProductDescriptionDetails selectedProduct={selectedProduct} />
						</div>
					</>
				)}
			</>
		</>
	) : (
		<div>
			{showTree || !selectedProduct ? (
				<>
					<h2>Wybierz produkt z listy</h2>
					<div className="NewOrder-leftBar">
						<div className="AssortmentSelection-SearchBar">
							<ToggleButtonGroup 
								type="radio" 
								className="AssortmentSelection-searchMode"
								value={searchMode} 
								name="searchMode" 
								onChange={switchMode}
							>
								<ToggleButton id="tbg-btn-1" className="AssortmentSelection-searchMode-toggleButton" value="groups">
									GRUPY
								</ToggleButton>
								<ToggleButton id="tbg-btn-2" className="AssortmentSelection-searchMode-toggleButton" value="products">
									PRODUKTY
								</ToggleButton>
							</ToggleButtonGroup>
							{searchMode==='groups' ? <Form.Control id="searchGroups" type="text" placeholder="Wyszukaj grupę"
								value={findInTree}
								onChange={(event) => setFindInTree(event.target.value)}
								ref={groupsInputRef}
							/> : <Form.Control id="searchProducts" type="text" placeholder="Wyszukaj produkt"
								value={findProduct}
								onChange={(event) => setFindProduct(event.target.value)} 
								ref={productsInputRef}
							/>}
						</div>
						{(searchMode==='groups' || (searchMode==='products' && findProduct.length === 0)) ? (tree ? <div className="AssortmentSelection-tree" ref={treeRef}>
							<TreeSelect
								error={error}
								tree={tree}
								selectedPath={selectedPath}
								selectedProduct={selectedProduct}
								toggleTreeNode={toggleTreeNode}
								focusFirstInput={focusFirstInput}
								onUpdate={onUpdate}
								treeRef={treeRef} 
								textInputRef={groupsInputRef}
								setShowTree={setShowTree}
								/>
						</div> : <Spin animation="border" />) : <div className="AssortmentSelection-tree" ref={treeRef}>
							<ListSelect 
								message={productsListMessage}
								products={foundProducts}
								setShowTree={setShowTree}
								/>
						</div>}
					</div>
				</>
			) : (
				<>
					<ButtonComponent
						text='WRÓĆ DO WYSZUKIWANIA'
						onClick={() => setShowTree(true)}
						marginTop='0px'
					/>
					<ProductDescriptionDetails selectedProduct={selectedProduct} />
				</>
			)}
		</div>
	);
}
