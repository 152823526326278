import React, { useEffect, useState } from 'react';
import './Cards.scss'
import { Product } from 'lib/types';
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import { buildResourceLink } from 'lib/communication';
import { useUser } from 'reducers';

interface PDescProps {
	selectedProduct: Product;
}

const ProductDescriptionDetails = (props: PDescProps) => {
	const user = useUser();
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);

	useEffect(() => {
		const image = new Image();
		image.src = props.selectedProduct.image ? buildResourceLink(props.selectedProduct.image) :`${process.env.PUBLIC_URL}/images/ikona.jpg`;

		image.onload = () => {
			setWidth(image.width);
			setHeight(image.height);
		}
		// eslint-disable-next-line
	}, []);

	return (
		<Card style={{ width: '100%', height: '100%' }}>
			<Card.Header style={{ padding: '0.25rem 1.25rem' }}>
				<span className="NewOrder-selectedProduct">{props.selectedProduct.description}</span>
			</Card.Header>
			<Card.Body className="Cards-ProductDescriptionDetails">
				<ListGroup className="list-group-flush">
					<ListGroupItem className="Cards-ProductDescriptionDetails-data">
						<Card.Title>Dane produktu</Card.Title>
						<div className="Cards-ProductDescriptionDetails-data-row">
							<b>JM: </b>
							{props.selectedProduct.priceInfo.units.reduce((acc, u, index) => {return acc + u.description + (index < props.selectedProduct.priceInfo.units.length-1 ? ', ' : '')}, '' )}
						</div>
						<div className="Cards-ProductDescriptionDetails-data-row">
							<b>Cena przed rabatem: </b>
							{props.selectedProduct.priceInfo.price}
						</div>
						<div className="Cards-ProductDescriptionDetails-data-row">
							<b>Rabat: </b>
							{props.selectedProduct.priceInfo.discount} %
						</div>
						<div className="Cards-ProductDescriptionDetails-data-row">
							<b>Cena po rabacie: </b>
							{props.selectedProduct.priceInfo.priceAfterDiscount}
						</div>
						<div className="Cards-ProductDescriptionDetails-data-row">
							<b>Stawka: </b>
							{props.selectedProduct.priceInfo.vatRate} %
						</div>
					</ListGroupItem>
				</ListGroup>
				<div className={user?.isAdmin ? "Cards-ProductDescriptionDetails-photo-admin" : "Cards-ProductDescriptionDetails-photo"}
						style={{minWidth: (width===height && width<=180) ? "180px" : ""}}>
					<img
						style={{minWidth: (width===height && width<=180) ? "180px" : ""}}
						src={props.selectedProduct.image ?
							buildResourceLink(props.selectedProduct.image) :
							`${process.env.PUBLIC_URL}/images/ikona.jpg`
						}
						alt=""
						height='auto'
					/>
					{user?.isAdmin && (
						<p className="NewOrder-image-name">Nazwa pliku: {props.selectedProduct.symKar}</p>
					)}
				</div>
			</Card.Body>
		</Card>
	);
}

export default ProductDescriptionDetails;
