import { News } from 'lib/types';
import store from 'store';
import { dataActions } from 'reducers';
import { post, API_ADDRESS, put, get } from 'lib/communication';
import { NewsResponse, Response } from './communicationTypes';
import { PAGE_SIZE } from 'containers/News/News';

export async function getNews(company: number, page: number, visible: Boolean): Promise<boolean> {
	const route: string = 'news';
	setNewsFetching(true);
	try {
		const response = await get<NewsResponse>(`${API_ADDRESS}${route}?company=${company}&page=${page}&count=${PAGE_SIZE}
		&archieved=${visible ? 0 : 1}`);
		const data = response.parsedBody;
		if (data) {
			store.dispatch(dataActions.setNewsData(data));
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.log("Error ", err);
		return false;
	} finally {
		setNewsFetching(false);
	}
}

export async function removeLinks(id: number, value: string): Promise<boolean> {
	const route: string = 'news/removeLinks';
	setNewsFetching(true);
	try {
		const response = await post<Response>(`${API_ADDRESS}${route}`, {
			id: id,
			value: value
		});
		if (response.ok && response.parsedBody) {
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.log("Error ", err);
		return false;
	} finally {
		setNewsFetching(false);
	}
}

export async function addNews(news: News, companyId: number): Promise<boolean> {
	const route: string = 'news';
	setNewsFetching(true);
	try {
		const response = await post<Response>(
			`${API_ADDRESS}${route}`, {
			title: news.title,
			dateStart: news.dateStart,
			dateEnd: news.dateEnd,
			content: news.content,
			company: companyId,
			links: news.links
		});
		if (response.ok && response.parsedBody) {
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.log("Error ", err);
		return false;
	} finally {
		setNewsFetching(false);
	}
}

export async function modifyNews(modifiedNews: News, companyId: number): Promise<boolean> {
	const route: string = `news/${modifiedNews.id}/`;
	setNewsFetching(true);
	try {
		const response = await put<Response>(
			`${API_ADDRESS}${route}`,
			{
				company: companyId,
				title: modifiedNews.title,
				dateStart: modifiedNews.dateStart,
				dateEnd: modifiedNews.dateEnd,
				content: modifiedNews.content,
				time: modifiedNews.time,
				links: modifiedNews.links
			}
		);
		store.dispatch(dataActions.updateNews(modifiedNews));
		if (response.ok) {
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.log("Error ", err);
		return false;
	} finally {
		setNewsFetching(false);
	}
}

export async function archiveNews(id: number): Promise<boolean> {
	const route: string = `news/${id}/archive`;
	try {
		const data = await put<Response>(
			`${API_ADDRESS}${route}`,
			{ id: id }
		);
		if (data.ok) {
			store.dispatch(dataActions.setIsNewsArchived({ id, value: true }));
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.log("Error ", err);
		return false;
	} finally {
		setNewsFetching(false);
	}
}

export async function unarchiveNews(id: number): Promise<boolean> {
	const route: string = `news/${id}/unarchive`;
	try {
		const data = await put<Response>(
			`${API_ADDRESS}${route}`,
			{ id: id }
		);
		if (data.ok) {
			store.dispatch(dataActions.setIsNewsArchived({ id, value: false }));
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.log("Error ", err);
		return false;
	} finally {
		setNewsFetching(false);
	}
}

export async function uploadFile(file: File): Promise<string> {
	const route: string = `news/uploadFile`;
	const formData = new FormData();
	formData.append('file', file);
	const args: RequestInit = { method: 'post', body: formData };
	const data = await post<Response>(`${API_ADDRESS}${route}`, formData, args);
	return data.parsedBody!.response;
}

function setNewsFetching(isFetching: boolean) {
	store.dispatch(dataActions.setNewsFetching(isFetching));
}
