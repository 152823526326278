import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dataActions, AppState } from 'reducers';
import { Fade, Alert } from 'react-bootstrap';
import './NotificationComponent.scss';

export default function NotificationComponent() {
	const isVisible = useSelector((state: AppState) => state.data.notificationComponent.props.isVisible);
	const message = useSelector((state: AppState) => state.data.notificationComponent.notificationMessage);
	const variant = useSelector((state: AppState) => state.data.notificationComponent.notificationVariant);

	const dispatch = useDispatch();

	useEffect(() => {
		if (isVisible) {
			setTimeout(() => {
				dispatch(dataActions.hideNotificationComponent());
			}, 3000);
		}
	// eslint-disable-next-line
	}, [isVisible]);

    return (
		<Fade in={isVisible}>
			<Alert variant={variant != null ? variant : 'succes'}  transition={false} className='NotificationComponent text-center'>
				<Alert.Heading className='NotificationComponentAlert'>{message}</Alert.Heading>
			</Alert>
		</Fade>
	);
}

export const useNotification = () => {
	const dispatch = useDispatch();

	return (message: string, variant: string = 'success') => {
		dispatch(dataActions.setNotificationComponent({
			isVisible: true,
			message: message,
			variant: variant
		}));
	};
};
