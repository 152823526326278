import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Col, Form, Row } from 'react-bootstrap';
import store from 'store';
import { AppState, dataActions } from 'reducers';
import { Product, Unit } from 'lib/types';
import { useMediaQuery } from 'react-responsive';
import { LG } from 'lib/util';
import './ProductSelection.scss';
import { useSelector } from 'react-redux';
import { getDefaultUnitOfProduct, mapLocalCartToCartContents } from "lib/utilityMetods";
import NumberInput from 'components/NumberInput';
import ButtonComponent from 'components/ButtonComponent';
import { showCartMessage } from '../AssortmentSelection';
import useCallbackState from 'lib/hooks/useCallbackState';
import { useNotification } from 'components/NotificationComponent/NotificationComponent';


interface ProductSelectionProps {
	selectedProduct: Product;
	inputRef?: React.RefObject<HTMLInputElement>;
}

export default function ProductSelection({ selectedProduct, inputRef }: ProductSelectionProps) {

	const isDesktop = useMediaQuery({ minWidth: LG });

	const edit = useSelector((state: AppState) => state.data.orders.edit);
	const localEditCart = useSelector((state: AppState) => state.data.orders.localEditCart);
	const showNotification = useNotification();

	const productDefaultDimension = Number(selectedProduct.dimensionDefault ?? 1);
	const [productDimension, setProductDimension] = useState(productDefaultDimension);
	const [selectedAdditions, setSelectedAdditions] = useState(Array<boolean>());
	const [productQuantity, setProductQuantity] = useCallbackState<number>(1);

	const [correctAdditions, setCorrectAdditions] = useState(true);
	const [correctQuantity, setCorrectQuantity] = useState(true);
	const [resetNumberInputAdditions, setResetNumberInputAdditions] = useState(false);
	const [resetNumberInputQuantity, setResetNumberInputQuantity] = useState(false);

	const [selectedUnit, setSelectedUnit] = useState<Unit>({unit: 'szt', isDefault: false, converter: 1, description: 'sztuki'});

	const makeGroupName = () => {
		return _.join(selectedProduct?.path, ' ');
	};

	const totalQuantity = +(productQuantity * (selectedProduct.isDimension ? productDimension : 1)).toFixed(2);
	const multiplyByTotalQuantity = (value: number): number => {
		return +(totalQuantity * value).toFixed(2);
	}
	const isFoil = selectedAdditions && selectedAdditions[0];
	const productPriceData = selectedProduct ? ({
		price: multiplyByTotalQuantity(isFoil ? selectedProduct.priceInfo.priceWithFoil : selectedProduct.priceInfo.price),
		discount: isFoil ? selectedProduct.priceInfo.discountWithFoil : selectedProduct.priceInfo.discount,
		priceAfterDiscount: multiplyByTotalQuantity(isFoil ? selectedProduct.priceInfo.priceWithFoilAfterDiscount : selectedProduct.priceInfo.priceAfterDiscount),
		vat: multiplyByTotalQuantity(isFoil ? selectedProduct.priceInfo.vatWithFoil : selectedProduct.priceInfo.vat),
		gross: multiplyByTotalQuantity(isFoil ? selectedProduct.priceInfo.grossWithFoil : selectedProduct.priceInfo.gross),
	}) : null

	const handleUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const unit = selectedProduct.priceInfo.units[parseInt(e.currentTarget.value)];

		setSelectedUnit(unit);
	}

	const addProductSubmit = () => {
		showCartMessage();

		if (productQuantity > 0 && (productDimension ?? 1) > 0 && selectedUnit) {
			store.dispatch(dataActions[edit ? 'addToLocalEditCart' : 'addToLocalCart']({
				groupPath: makeGroupName() as string,
				product: {
					product: selectedProduct as Product,
					dimension: productDimension,
					quantity: productQuantity as number,
					additionsSelected: selectedAdditions,
					mainProductSymkar: null,
					unit: selectedUnit
				},
				productsType: 'mainProducts'
			}));
			setProductQuantity(1);
			setProductDimension(productDefaultDimension);
			setResetNumberInputAdditions(true);
			setResetNumberInputQuantity(true);
		}
	}

	useEffect(() => {
		setProductDimension(productDefaultDimension);
		setProductQuantity(1, () => inputRef?.current?.select());
		setSelectedAdditions(_.map(selectedProduct?.additions, (addition) => addition.default ?? false));
		setSelectedUnit(getDefaultUnitOfProduct(selectedProduct));
		// eslint-disable-next-line
	}, [selectedProduct, productDefaultDimension])

	useEffect(() => {
		store.dispatch(dataActions.setEditCart(mapLocalCartToCartContents(localEditCart)));
	}, [localEditCart])

	if (!productPriceData) return null;



	return isDesktop ? (
		<div className="AssortmentSelection-addProduct-container">
			<div className="ProductSelection-addProduct ProductSelection-addProduct-desktop">
				<div className='ProductSelection-addProduct-desktop-header'>
					<span className='ProductSelection-addProduct-desktop-title'>Dodaj produkt</span>
					<hr />
				</div>
				<div className="NewOrder-typeElem" onKeyPress={({ key }) => key === 'Enter' && addProductSubmit()}>
					<Form.Group controlId="quantity" style={{ width: '35%', margin: '0px' }}>
						<span>Ilość sztuk: </span>
						<NumberInput
							value={productQuantity}
							setValue={(value) => setProductQuantity(value)}
							minDesktopResolution={LG}
							disabledEdit={productQuantity === 1}
							inputRef={inputRef}
							correct={correctQuantity}
							setCorrect={setCorrectQuantity}
							defaultValue={1}
							reset={resetNumberInputAdditions}
							setReset={setResetNumberInputAdditions}
						/>
					</Form.Group>
					<Form.Group controlId="dimension" style={{ width: '35%', margin: '0px' }}>
						<span>Wymiar: </span>
						<NumberInput
							value={productDimension}
							setValue={(value) => setProductDimension(value)}
							step={0.1}
							min={selectedProduct.dimensionMin ?? 0.01}
							max={selectedProduct.dimensionMax ?? Math.pow(10, 5)}
							fractDigits={3}
							disabled={!selectedProduct.dimensionCanEdit}
							minDesktopResolution={LG}
							disabledEdit={productDimension === 0.01}
							correct={correctAdditions}
							setCorrect={setCorrectAdditions}
							defaultValue={productDefaultDimension}
							reset={resetNumberInputAdditions}
							setReset={setResetNumberInputAdditions}
							showMinMaxOnInvalidRange={true}
						/>
					</Form.Group>
					<div className="ProductSelection-productAdditions">
						{selectedProduct.additions.map((addition, index) => (
							<div style={{ marginRight: '5px' }} key={`additionCheck_${addition.name}`}>
								<span>{addition.name}:</span>
								<Form.Group controlId={addition.name} style={{ marginBottom: '0' }}>
									<Form.Check name={addition.name} type="checkbox" style={{ marginTop: '8px' }}
										defaultChecked={addition.default}
										disabled={addition.default}
										onChange={() => {
											let newArr = [...selectedAdditions];
											newArr[index] = !selectedAdditions[index];
											setSelectedAdditions(newArr);
										}}
									/>
								</Form.Group>
							</div>
						))}
					</div>
				</div>
				<hr />
				<Row>
					<Col>
						<div style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }}>
						<span>JM: </span>
							{selectedProduct.priceInfo.units.length > 1 ? <Form.Control 
								as="select"
								onChange={handleUnitChange}
								style={{ padding: '3px', height: 'auto', maxWidth: 'fit-content' }}
								defaultValue={selectedProduct.priceInfo.units.findIndex(u => selectedUnit.unit === u.unit)}
							>
								{selectedProduct.priceInfo.units.map((unit, index) => {
									return	<option 
												key={`${selectedProduct.symKar}-${unit.unit}`} 
												value={index}
											>
												{unit.description}
											</option>
								})}
							</Form.Control> : getDefaultUnitOfProduct(selectedProduct).unit}
						</div>
					</Col>
					<Col>
						<span>Ilość w JM: </span>
						<b>{totalQuantity}</b>
					</Col>
					<Col>
						<span>Ilość w m<sup>2</sup>: </span>
						<b>{(totalQuantity * selectedProduct.priceInfo.converterM2).toFixed(4)}</b>
					</Col>
				</Row>
				<Row>
					<Col>
						<span>Netto: </span>
						<b>{(productPriceData.priceAfterDiscount * (selectedUnit?.converter ?? 1)).toFixed(2)}</b>
					</Col>
					<Col>
						<span>VAT: </span>
						<b>{(productPriceData.vat * (selectedUnit?.converter ?? 1)).toFixed(2)}</b>
					</Col>
					<Col>
						<span>Brutto: </span>
						<b>{(productPriceData.gross * (selectedUnit?.converter ?? 1)).toFixed(2)}</b>
					</Col>
				</Row>
				<hr />
				<div className="ProductSelection-addToCart-mobile">
					<ButtonComponent
						text='DODAJ DO KOSZYKA'
						onClick={addProductSubmit}
						marginBottom='5px'
						marginTop='5px'
						disabled={correctQuantity && correctAdditions ? false : true}
					/>
				</div>
			</div>
		</div >
	) : (
		<div>
			<div className="ProductSelection-addProduct">
				<div className="ProductSelection-addProductHeader">
					<b>{selectedProduct.description}</b>
				</div>
				<div className="NewOrder-typeElem">
					<Form.Group controlId="quantity" style={{ margin: '0 3%' }}>
						<span>Ilość sztuk: </span>
						<NumberInput
							value={productQuantity}
							setValue={(value) => setProductQuantity(value)}
							minDesktopResolution={LG}
							disabledEdit={productQuantity === 1}
							correct={correctQuantity}
							setCorrect={setCorrectQuantity}
							defaultValue={1}
							reset={resetNumberInputQuantity}
							setReset={setResetNumberInputQuantity}
						/>
					</Form.Group>
					<Form.Group controlId="dimension" style={{ margin: '0 3%', marginBottom: '5px' }}>
						<span>Wymiar: </span>
						<NumberInput
							value={productDimension}
							setValue={(value) => setProductDimension(value)}
							step={0.1}
							min={selectedProduct.dimensionMin ?? 0.01}
							max={selectedProduct.dimensionMax ?? Math.pow(10, 5)}
							fractDigits={3}
							disabled={!selectedProduct.dimensionCanEdit}
							minDesktopResolution={LG}
							disabledEdit={productDimension === 0.01}
							correct={correctAdditions}
							setCorrect={setCorrectAdditions}
							defaultValue={productDefaultDimension}
							reset={resetNumberInputAdditions}
							setReset={setResetNumberInputAdditions}
						/>
					</Form.Group>
					<div className="ProductSelection-productAdditions" style={{ marginRight: '5px' }}>
						{selectedProduct.additions.map((addition, index) => (
							<div style={{ marginRight: '5px' }} key={`additionCheck_${addition.name}`}>
								<span>{addition.name}:</span>
								<Form.Group controlId={addition.name}>
									<Form.Check name={addition.name} type="checkbox" style={{ margin: '10px' }}
										defaultChecked={addition.default ?? false}
										disabled={addition.default}
										onChange={() => {
											let newArr = [...selectedAdditions];
											newArr[index] = !selectedAdditions[index];
											setSelectedAdditions(newArr);
										}}
									/>
								</Form.Group>
							</div>
						))}
					</div>
				</div>
				<hr />
				<Row>
					<Col>
						<div style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }}>
						<span>JM: </span>
							{selectedProduct.priceInfo.units.length > 1 ? <Form.Control 
								as="select"
								onChange={handleUnitChange}
								style={{ padding: '3px', height: 'auto', maxWidth: 'fit-content' }}
								defaultValue={selectedProduct.priceInfo.units.findIndex(u => selectedUnit.unit === u.unit)}
							>
								{selectedProduct.priceInfo.units.map((unit, index) => {
									return	<option 
												key={`${selectedProduct.symKar}-${unit.unit}`} 
												value={index}
											>
												{unit.description}
											</option>
								})}
							</Form.Control> : getDefaultUnitOfProduct(selectedProduct).unit}
						</div>
					</Col>
					<Col>
						<span>Ilość w JM: </span>
						<b>{totalQuantity}</b>
					</Col>
					<Col>
						<span>Ilość w m<sup>2</sup>: </span>
						<b>{(totalQuantity * selectedProduct.priceInfo.converterM2).toFixed(4)}</b>
					</Col>
				</Row>
				<Row>
					<Col>
						<span>Netto: </span>
						<b>{(productPriceData.priceAfterDiscount * (selectedUnit?.converter ?? 1)).toFixed(2)}</b>
					</Col>
					<Col>
						<span>VAT: </span>
						<b>{(productPriceData.vat * (selectedUnit?.converter ?? 1)).toFixed(2)}</b>
					</Col>
					<Col>
						<span>Brutto: </span>
						<b>{(productPriceData.gross * (selectedUnit?.converter ?? 1)).toFixed(2)}</b>
					</Col>
				</Row>
			</div>
			<div className="ProductSelection-addToCart-mobile">
				<ButtonComponent
					text='DODAJ DO KOSZYKA'
					marginBottom='0px'
					onClick={() => {
						if (productQuantity > 0 && (productDimension ?? 1) > 0 && selectedUnit) {
							store.dispatch(dataActions[edit ? 'addToLocalEditCart' : 'addToLocalCart']({
								groupPath: makeGroupName() as string,
								product: {
									product: selectedProduct as Product,
									dimension: productDimension,
									quantity: productQuantity as number,
									additionsSelected: selectedAdditions,
									mainProductSymkar: null,
									unit: selectedUnit
								},
								productsType: 'mainProducts'
							}))
						}
						showNotification('Dodano produkt do koszyka');
					}}
					disabled={correctQuantity && correctAdditions ? false : true}
				/>
			</div>
		</div>
	);
}
