import ButtonComponent from "components/ButtonComponent";
import Spinner from "components/Spinner";
import { callArtisanCommand, getCacheManagement } from "lib/communication/admin";
import { LG, getFormattedDateWithHoursAndMinutes } from "lib/util";
import _ from "lodash";
import React, { useEffect, useState } from "react"
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { AppState } from "reducers";

export default function CacheManagement() {
	const isDesktop = useMediaQuery({ minWidth: LG });
	const cacheManagement = useSelector((state: AppState) => state.data.adminInfo.cacheManagement);
	const loading = useSelector((state: AppState) => state.data.adminInfo.isCacheManagementFetching);
	const [message, setMessage] = useState<string>('');
	useEffect(() => {
		getCacheManagement();
	}, []);

	const handleButtonClick = async (value: string) => {
		callArtisanCommand(value);
		setMessage('Uruchomiono komendę: ' + value);
	}

	const handleRefresh = async () => {
		getCacheManagement();
		setMessage('Odświeżono widok');
	}

	return <>
		<div className="row" style={{ justifyContent: 'center', alignItems: 'baseline' }}>
			<h2>Zarządzanie pamięcią cache</h2>
			<ButtonComponent
				text='Odśwież'
				onClick={() => handleRefresh()}
			/>
		</div>

		<div className="Admin-addMessage" />
		{
			loading ? <Spinner errMsg="Błąd pobierania procedur do zarządzania pamięcią cache serwera" /> :
				<>
					{
						isDesktop ? <Table borderless striped>
							<thead>
								<tr>
									<td>Nazwa komendy</td>
									<td>Opis komendy</td>
									<td>Postęp</td>
									<td>Data ostatniego uruchomienia</td>
									<td>Czas ostatniego wykonania</td>
									<td>Uruchom komende</td>
								</tr>
							</thead>
							<tbody>
								{_.map(cacheManagement, cm => <tr className="vertical-align-center" key={cm.id}>
									<td>{cm.name}</td>
									<td>{cm.description}</td>
									<td>{cm.progress}%</td>
									<td>{cm.date !== null ? getFormattedDateWithHoursAndMinutes(cm.date) : "-"}</td>
									<td>{cm.last_execution_time !== null ? cm.last_execution_time + " s" : "-"}</td>
									<td>
										<ButtonComponent
											text='Uruchom'
											onClick={() => handleButtonClick(cm.name)}
											margin='0.0rem'
										/>
									</td>
								</tr>)
								}
							</tbody>
						</Table>
							:
							<>
								{
									_.map(cacheManagement, cm => <div key={cm.name} className="mobile-user-block">
										<div className="mobile-user-block-administrator" style={{ wordBreak: "break-word" }}>{cm.name}</div>
										<div className="mobile-user-block-administrator">{cm.description}</div>
										<div className="mobile-user-block-administrator">{cm.progress}%</div>
										<div className="mobile-user-block-administrator">
											{cm.date !== null ? getFormattedDateWithHoursAndMinutes(cm.date) : "-"}
										</div>
										<div className="mobile-user-block-administrator">
											{cm.last_execution_time !== null ? cm.last_execution_time + " s" : "-"}
										</div>
										<div className="mobile-user-block-administrator">
											<ButtonComponent
												text='Uruchom'
												onClick={() => handleButtonClick(cm.name)}
												margin='0.25rem'
											/>
										</div>
									</div>)
								}
							</>
					}
				</>
		}
		<p>{message}</p>
	</>
}