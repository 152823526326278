import { API_ADDRESS, post } from 'lib/communication';
import store from 'store';
import { dataActions } from 'reducers';
import { OrderDirection, Billing } from 'lib/types';
import { produce } from 'immer';
import { getFormatedDate, isSafari } from 'lib/util';
import { userService } from 'services';


export async function getBillings(): Promise<boolean> {
	const route: string = 'billing';
	setSummaryFetching({ settlementsIsFetching: true });
	try {
		const { logo, company } = userService.getUser();
		const data = await post<Billing[]>(`${API_ADDRESS}${route}`, { logo, company });
		if (data.ok && data.parsedBody) {
			store.dispatch(dataActions.setSettlementsList(data.parsedBody));
			return true;
		} else return false;
	} catch (err) {
		console.log('Error ', err);
		return false;
	} finally {
		setSummaryFetching({ settlementsIsFetching: false });
	}
}

export async function getBillingPdf(
	orderNumber: string,
	dateFrom: string,
	dateTo: string,
	orderBy: string,
	orderDirection: OrderDirection,
	count: number,
	page: number
): Promise<boolean> {
	const route: string = 'billing/pdf';
	setSummaryFetching({ pdfIsFetching: true });
	let windowReference: Window | null;
	if (isSafari()) {
		windowReference = window.open();
	}
	try {
		const { token, logo, company, wydr } = userService.getUser();
		const response = await fetch(`${API_ADDRESS}${route}`, {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			method: 'POST',
			body: JSON.stringify({
				logo,
				company,
				orderBy: orderBy,
				orderDirection: orderDirection,
				number: orderNumber,
				from: getFormatedDate(new Date(dateFrom)),
				to: getFormatedDate(new Date(dateTo)),
				count,
				page,
				wydr
			}),
		}).then((response) => {
			return response.status === 200
				? response.blob().then((blob) => {
					const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
					if (isSafari()) {
						if (windowReference !== null)
							windowReference.location.href = url;
					} else {
						window.open(url)
					}
					return true;
				})
				: false;
		});
		return response;
	} catch (err) {
		console.log('Error ', err);
		return false;
	} finally {
		setSummaryFetching({ pdfIsFetching: false });
	}
}

function setSummaryFetching(isFetching: object) {
	const state = store.getState();
	store.dispatch(
		dataActions.setSettlements(
			produce(state.data.settlements, (draft) => {
				draft.props = {
					...draft.props,
					...isFetching,
				};
			})
		)
	);
}
