import './CartTable.scss';
import React, { useState, Fragment, useEffect, useRef, useMemo } from 'react';
import { Button, Table, Collapse, Form, Row, Col } from 'react-bootstrap';
import { LG } from 'lib/util';
import { useMediaQuery } from 'react-responsive';
import { FaAngleDown, FaAngleRight, FaEdit, FaTrashAlt, FaAngleUp, FaCheck } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { TiArrowUpThick } from 'react-icons/ti';
import _ from 'lodash';
import { ProductAddition, CartContent, CartTableContent, CartTableItem, ProductInCartTable, GutteringSystemData, Unit, GutteringSystemDataV2 } from 'lib/types';
import { useSelector } from 'react-redux';
import { AppState, dataActions } from 'reducers';
import { getSummaryNetto, getSummaryVat, getTotalPrice, mapLocalCartToCartContents, mapLocalCartToCartTableContent } from "lib/utilityMetods";
import store from 'store';
import NumberInput from 'components/NumberInput';
import ButtonComponent from 'components/ButtonComponent';
import { useHistory } from 'react-router-dom';
import { setGutterCreatorFilters, setGuttertoBeUpdated } from 'lib/communication/gutterCreator';
import { AssortmentSelectionState } from 'containers/NewOrder/AssortmentSelection/AssortmentSelection';

interface ItemsExpand {
	[key: string]: {
		isExpanded: boolean;
		isAdditionalExpanded: boolean;
		mobileExpanded: boolean[];
	}
}

interface RemeberedExpanded {
	itemsExpand: ItemsExpand;
	isodCollapse: boolean;
	gutterCollapse: boolean;
}

interface CartTableProps {
	close?: () => void | null;
}

export const CART_EXPANDED = 'cart_expanded';

export default function CartTable(props: CartTableProps) {

	const isDesktop = useMediaQuery({ minWidth: LG });

	const headerRef = useRef<HTMLDivElement | null>(null);

	const edit = useSelector((state: AppState) => state.data.orders.edit);
	const localCart = useSelector((state: AppState) => edit ? state.data.orders.localEditCart : state.data.orders.localCart);
	const cartContent = useSelector((state: AppState) => edit ? state.data.orders.editCart : state.data.orders.cart);
	const detailedOrder = useSelector((state: AppState) => state.data.orders.detailedOrder);
	
	const [similarEditQuantity, setSimilarEditQuantity] = useState(1);
	const [similarEditDimension, setSimilarEditDimension] = useState(1);
	const [similarEditIsDimension, setSimilarEditIsDimension] = useState(false);
	const [similarEditUnit, setSimilarEditUnit] = useState<Unit>({unit: 'szt', isDefault: false, converter: 1, description: 'sztuki'});
	const [similarAdditions, setSimilarAdditions] = useState(Array<boolean>());
	const [isSavable, setSavable] = useState(true);
	const [gutterCollapse, setGutterCollapse] = useState(false);
	const [isodCollapse, setIsodCollapse] = useState(false);
	const [showScrollUpButton, setShowScrollUpButton] = useState(false);
	const [cartTableContent, setCartTableContent] = useState<CartTableContent>({} as CartTableContent);
	const [editedProduct, setEditedProduct] = useState<ProductInCartTable|null>(null);
	const [itemsExpand, setItemsExpand] = useState<ItemsExpand>({} as ItemsExpand);
	const [maxNet, setMaxNet] = useState<number>(0);
	const [maxVat, setMaxVat] = useState<number>(0);
	const [maxGross, setMaxGross] = useState<number>(0);
	const history = useHistory<AssortmentSelectionState>();



	useEffect(() => {
		window.addEventListener("scroll", isButtonDisplayed, true);

		return () => {
			window.removeEventListener("scroll", isButtonDisplayed, true);
		}
	}, []);

	const scrollToTop = () => {
		if (headerRef !== null) headerRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	const isButtonDisplayed = () => {
		(document.body.lastChild! as HTMLInputElement).scrollTop > 300 || window.pageYOffset > 300 ? setShowScrollUpButton(true) : setShowScrollUpButton(false);
	}

	useEffect(() => {
		if(_.isEmpty(itemsExpand) && !_.isEmpty(cartTableContent)) {
			let newExpand: ItemsExpand = {};

			_.map(cartTableContent.mainItems, (val, key) => {
				newExpand[key] = {
					isExpanded: true,
					isAdditionalExpanded: false,
					mobileExpanded: Array<boolean>(val.products.length).fill(false)
				};
			});
			_.map(cartTableContent.isodItems, (val, key) => {
				newExpand[`ISOD${key}`] = {
					isExpanded: true,
					isAdditionalExpanded: false,
					mobileExpanded: Array<boolean>(val.products.length).fill(false)
				};
			});
			_.map(cartTableContent.additionalItems, (val, key) => {
				_.map(val, (val2, key2) => {
					newExpand[key+key2] = {
						isExpanded: true,
						isAdditionalExpanded: false,
						mobileExpanded: Array<boolean>(val2.products.length).fill(false)
					};
				})
			});
			_.map(cartTableContent.gutteringsItems, (val, key) => {
				newExpand[key] = {
					isExpanded: true,
					isAdditionalExpanded: false,
					mobileExpanded: Array<boolean>(val.products.length).fill(false)
				};
			});

			const remeberedExpanded: RemeberedExpanded | null = getRememberedExpanded();
			if(remeberedExpanded) {
				setIsodCollapse(remeberedExpanded.isodCollapse);
				setGutterCollapse(remeberedExpanded.gutterCollapse);
				_.map(newExpand, (val, key) => {
					if(remeberedExpanded.itemsExpand[key]) {
						newExpand[key] = remeberedExpanded.itemsExpand[key];
					}
				});
			}

			setItemsExpand(newExpand);
		}
	// eslint-disable-next-line
	}, [cartTableContent]);

	useEffect(() => {
		if(!_.isEmpty(itemsExpand)) saveExpanded();
	// eslint-disable-next-line
	}, [itemsExpand, isodCollapse, gutterCollapse]);

	useEffect(() => {
		setCartTableContent(mapLocalCartToCartTableContent(localCart));
		
		const cartContentMapped: CartContent = mapLocalCartToCartContents(localCart);
		
		if (edit)
			store.dispatch(dataActions.setEditCart(cartContentMapped));
		else
			store.dispatch(dataActions.setCart(cartContentMapped));
	}, [localCart, detailedOrder.orderNumber, edit]);

	useEffect(() => {
		setSavable(similarEditQuantity > 0 && similarEditDimension > 0);
	}, [similarEditDimension, similarEditQuantity]);

	useEffect(() => {
		setMaxNet(_.max([+totalIsodNetto(), +totalGutterNetto(), _.reduce(cartTableContent.mainItems, (max, item) => {
			return _.max([item.totalNetto, item.additionalItemsSummary.netto, max]) || max;
		}, 0) || 0]) || 0);
		setMaxVat(_.max([+totalIsodVat(), +totalGutterVat(), _.reduce(cartTableContent.mainItems, (max, item) => {
			return _.max([item.totalVat, item.additionalItemsSummary.vat, max]) || max;
		}, 0) || 0]) || 0);
		setMaxGross(_.max([+totalIsodPrice(), +totalGutterPrice(), _.reduce(cartTableContent.mainItems, (max, item) => {
			return _.max([item.totalPrice, item.additionalItemsSummary.gross, max]) || max;
		}, 0) || 0]) || 0);
	// eslint-disable-next-line
	}, [cartTableContent]);

	const saveExpanded = () => {
		sessionStorage.setItem(CART_EXPANDED, JSON.stringify({itemsExpand, isodCollapse, gutterCollapse}));
	}

	const getRememberedExpanded = (): RemeberedExpanded | null => {
		const remembered = sessionStorage.getItem(CART_EXPANDED);
		if(!remembered) return null;
		return JSON.parse(remembered) as RemeberedExpanded;
	}

	const editProduct = (product: ProductInCartTable) => {
		setSimilarEditQuantity(product.quantity);
		setSimilarEditDimension(product.dimension ?? 1);
		setSimilarEditIsDimension(product.product.isDimension);
		setSimilarAdditions(product.additionsSelected ?? []);
		setEditedProduct(product);
		setSimilarEditUnit(product.unit);
	}

	const saveProduct = () => {
		if (!isSavable) return;
		if (similarEditQuantity > 0) {

			if(editedProduct) {
				if(editedProduct.isGutter) {
					store.dispatch(dataActions[edit ? 'editGutteringsInEditLocal' : 'editGutteringsInLocal']({
						symKar: editedProduct.product.symKar,
						quantity: similarEditQuantity,
						systemData: editedProduct.systemData as GutteringSystemData
					}));
				} else {
					store.dispatch(dataActions[edit ? 'editProductsInLocalEditCart' : 'editProductsInLocalCart']({
						groupPath: editedProduct.groupPath,
						productsType: editedProduct.mainProductSymkar ? 'additionalProducts' : 'mainProducts',
						index: editedProduct.indexInGroup,
						product: {
							additionsSelected: similarAdditions,
							dimension: similarEditDimension,
							quantity: similarEditQuantity,
							product: editedProduct.product,
							mainProductSymkar: editedProduct.mainProductSymkar,
							unit: similarEditUnit
						}
					}));
				}
			}

			setEditedProduct(null);
		}
	}

	const deleteProduct = (prd: ProductInCartTable) => {
		if(prd.isGutter && prd.systemData) store.dispatch(dataActions[edit ? 'deleteGutteringsFromEditCart' : 'deleteGutteringsFromCart']({
			symKar: prd.product.symKar,
			systemData: prd.systemData
		}));
		else store.dispatch(dataActions[edit ? 'deleteFromLocalEditCart' : 'deleteFromLocalCart']({
			groupPath: prd.groupPath, 
			index: prd.indexInGroup, 
			productsType: prd.mainProductSymkar ? 'additionalProducts' : 'mainProducts', 
			symKar: prd.product.symKar
		}));
	}

	const additionslist = () => {
		const productGroups = cartContent.productGroups;
		let additions: ProductAddition[] = [];
		_.forEach(productGroups, (pg) =>
			_.forEach({...pg.mainProducts.products, ...pg.additionalProducts.products}, (product) => {
				additions = _.unionBy(additions, product.product.additions, 'name')
			})
		);
		return additions;
	}

	const totalSimilarQuantity = +(similarEditQuantity * (similarEditIsDimension ? similarEditDimension : 1) * (similarEditUnit?.converter ?? 1)).toFixed(2);

	const totalEditedProductPrice = (): number => {
		if(editedProduct) return (similarAdditions[0] ? editedProduct.product.priceInfo.grossWithFoil : editedProduct.product.priceInfo.gross) * totalSimilarQuantity;

		return 0;
	}

	const editedProductVat = (): number => {
		if(editedProduct) return (similarAdditions[0] ? editedProduct.product.priceInfo.vatWithFoil : editedProduct.product.priceInfo.vat) * totalSimilarQuantity;

		return 0;
	}

	const editedProductNetto = (): number => totalEditedProductPrice() - editedProductVat();

	const totalGutterPrice = () => {
		return _.reduce(cartContent.gutterGroups, (sum, grp) => {
			return sum + grp.products.price;
		}, 0).toFixed(2);
	}
	const totalGutterNetto = () => {
		return _.reduce(cartContent.gutterGroups, (sum, grp) => {
			return sum + _.reduce(grp.products.products, (sum2, prd) => {
				return sum2 + getSummaryNetto(prd);
			}, 0);
		}, 0).toFixed(2);
	}
	const totalGutterVat = () => {
		return _.reduce(cartContent.gutterGroups, (sum, grp) => {
			return sum + _.reduce(grp.products.products, (sum2, prd) => {
				return sum2 + getSummaryVat(prd);
			}, 0);
		}, 0).toFixed(2);
	}

	const totalIsodPrice = () => {
		return _.reduce(cartTableContent.isodItems, (sum, item) => {
			return sum + item.totalPrice;
		}, 0).toFixed(2);
	}
	const totalIsodNetto = () => {
		return _.reduce(cartTableContent.isodItems, (sum, item) => {
			return sum + item.totalNetto;
		}, 0).toFixed(2);
	}
	const totalIsodVat = () => {
		return _.reduce(cartTableContent.isodItems, (sum, item) => {
			return sum + item.totalVat;
		}, 0).toFixed(2);
	}

	const expandAll = () => {
		setGutterCollapse(true);
		setIsodCollapse(true);
		let newExpand = {...itemsExpand};
		_.map(newExpand, item => {
			item.isExpanded = true;
			item.isAdditionalExpanded = true;
		});
		setItemsExpand(newExpand);
	}

	const hideAll = () => {
		setGutterCollapse(false);
		setIsodCollapse(false);
		let newExpand = {...itemsExpand};
		_.map(newExpand, item => {
			item.isExpanded = false;
			item.isAdditionalExpanded = false;
		});
		setItemsExpand(newExpand);
	}

	const maxNetWidth = useMemo(() => {
		const d = document.createElement('div');
		d.innerHTML = maxNet.toFixed(2);
		d.classList.add('measurer');
		document.querySelector('.CartTable-similarProductsTable')?.appendChild(d);
		const width = d.getBoundingClientRect().width;
		document.querySelector('.CartTable-similarProductsTable')?.removeChild(d);
		return width;
	// eslint-disable-next-line
	}, [maxNet]);
	const maxVatWidth = useMemo(() => {
		const d = document.createElement('div');
		d.innerHTML = maxVat.toFixed(2);
		d.classList.add('measurer');
		document.querySelector('.CartTable-similarProductsTable')?.appendChild(d);
		const width = d.getBoundingClientRect().width;
		document.querySelector('.CartTable-similarProductsTable')?.removeChild(d);
		return width;
	// eslint-disable-next-line
	}, [maxVat]);
	const maxPriceWidth = useMemo(() => {
		const d = document.createElement('div');
		d.innerHTML = maxGross.toFixed(2);
		d.classList.add('measurer');
		document.querySelector('.CartTable-similarProductsTable')?.appendChild(d);
		const width = d.getBoundingClientRect().width;
		document.querySelector('.CartTable-similarProductsTable')?.removeChild(d);
		return width;
	// eslint-disable-next-line
	}, [maxGross]);

	const renderProduct = (prd: ProductInCartTable, prdIndex: number, identifier: string, isSoloItem: boolean = false) => {
		let isEditing: boolean = editedProduct === prd;
		let isGutter: boolean = prd.isGutter;
		let isAdditional: boolean = Boolean(prd.mainProductSymkar);
		let isIsod = identifier.startsWith('ISOD');
		return <tr className={isSoloItem ? 'CartTable-expandedTr' : `CartTable-productTr ${prdIndex%2===1 ? 'odd' : ''}`} key={`${identifier}-${prdIndex}`}>
			<td></td>
			<td>{ (isGutter || isAdditional || isSoloItem) ? prd.product.description : '' }</td>
			<td className="edit-td"  style={{ padding: "0px 1px" }} >
				{
					isEditing ? 
					<NumberInput
						value={similarEditQuantity}
						setValue={(value) => {
							setSimilarEditQuantity(value);
						}}
						disabledEdit={similarEditQuantity === 1}
					/> 
					:
					prd.quantity 
				}
			</td>
			<td className="edit-td"  style={{ padding: "0px 1px" }} >
				{
					isEditing && prd.product.isDimension ? (
						<NumberInput
							value={similarEditDimension}
							setValue={(value) => setSimilarEditDimension(value)}
							step={0.01}
							min={prd.product.dimensionMin ?? 0.01}
							max={prd.product.dimensionMax ?? Math.pow(10, 5)}
							fractDigits={3}
							disabled={_.isEqual(prd.product.unit, "szt") || !prd.product.dimensionCanEdit}
							disabledEdit={similarEditDimension === 0.01}
						/>
					) : prd.dimension?.toFixed(3)
				}
			</td>
			{_.map(additionslist(), addition => {
				let prdAddIndex = _.findIndex(prd.product.additions, (prdAdd) => prdAdd.name===addition.name);
				return <td className="foil-td edit-td" key={`${identifier}-${prdIndex}-${addition.name}`}>
					{
						prdAddIndex > -1 && prd.additionsSelected ?
						(
							
							isEditing ?
							<Form.Check name={addition.name} type="checkbox" defaultChecked={prd.additionsSelected[prdAddIndex]}
								onChange={() => {
									let newArr = [...similarAdditions];
									newArr[prdAddIndex] = !similarAdditions[prdAddIndex];
									setSimilarAdditions(newArr);
								}}
								disabled={prd.product.additions[prdAddIndex].default}
							></Form.Check> :
							(prd.additionsSelected[prdAddIndex] ? <FaCheck /> : <MdClose />)
						)
						: ""
					}
				</td>
			})}
			<td style={{ padding: 0, textAlign: 'center' }}>
				{
					(isEditing && !isGutter && !isAdditional && !isIsod && prd.product.priceInfo.units.length > 1) ? (
						<Form.Control 
							as="select"
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								setSimilarEditUnit(prd.product.priceInfo.units[parseInt(e.currentTarget.value)]);
							}}
							style={{ padding: '1px', margin: 0, height: 'auto', fontSize: '0.85rem', minWidth: '6rem' }}
							defaultValue={prd.product.priceInfo.units.findIndex(u => similarEditUnit.unit === u.unit)}
						>
							{prd.product.priceInfo.units.map((unit, index) => {
								return	<option 
											key={`${prd.product.symKar}-${unit.unit}`} 
											value={index}
										>
											{unit.description}
										</option>
							})}
						</Form.Control>
					) : prd.unit?.unit ?? prd.product.unit
				}
			</td>
			<td className="value-td">{((prd.additionsSelected && prd.additionsSelected[0] ? prd.product.priceInfo.priceWithFoilAfterDiscount : prd.product.priceInfo.priceAfterDiscount)*(prd.unit?.converter ?? 1)).toFixed(2)}</td>
			<td className="value-td">{ isGutter ? '' : (isEditing ? (totalSimilarQuantity / (prd.unit?.converter ?? 1)) : prd.quantityJm).toFixed(2) }</td>
			<td className="value-td">{ isGutter ? '' : (isEditing ? (totalSimilarQuantity * prd.product.priceInfo.converterM2) : prd.quantityM2).toFixed(4) }</td>
			<td className="value-td">
				<div className="price-td-div" style={{ width: maxNetWidth }}>
					{ (isEditing ? editedProductNetto() : getSummaryNetto(prd)).toFixed(2) }
				</div>
			</td>
			<td className="value-td">
				<div className="price-td-div" style={{ width: maxVatWidth }}>
					{ (isEditing ? editedProductVat() : getSummaryVat(prd)).toFixed(2) }
				</div>
			</td>
			<td className="value-td">
				<div className="price-td-div" style={{ width: maxPriceWidth }}>
					{ (isEditing ? totalEditedProductPrice() : prd.price).toFixed(2) }
				</div>
			</td>
			<td onClick={() => {
				if(isEditing) saveProduct();
				else editProduct(prd);
			}}>
				{isEditing ? <FaCheck className={`manage-cart-icon fa-check ${!isSavable ? 'disabled-icon' : ''}`} /> : 
				<FaEdit className="manage-cart-icon" />}
			</td>
			<td>
				<FaTrashAlt className="manage-cart-icon" onClick={() => {deleteProduct(prd)}} />
			</td>
		</tr>
	}

	const renderItem = (item: CartTableItem) => {
		if(item.products.length === 1 && !item.products[0].isGutter) return renderProduct(item.products[0], 1, item.identifier, true);

		let isGutter: boolean = false;
		let mainProductSymkar = null;
		let isAdditional: boolean = false;
		if(item.products.length > 0) {
			isGutter = item.products[0].isGutter;
			mainProductSymkar = item.products[0].mainProductSymkar;
		}
		isAdditional = mainProductSymkar !== null && mainProductSymkar !== undefined;
		
		const {identifier} = item;

		let isExpanded: boolean = itemsExpand[identifier].isExpanded;
		return <>
			<tr className={`CartTable-expandedTr ${isAdditional ? 'additionalExpandedTr' : ''}`} 
				onClick={() => {
					if(itemsExpand) {
						const newExpand = {...itemsExpand};
						newExpand[identifier].isExpanded = !isExpanded;
						setItemsExpand(newExpand);
					}
				}}>
				<td>{item.products.length > 0 && (isExpanded ? <FaAngleDown /> : <FaAngleRight />)}</td>
				<td>{ item.title }</td>
				<td className="value-td">{ item.totalQuantity }</td>
				<td></td>
				{_.map(additionslist(), ({name}) => <td key={`${identifier}-addition-${name}`}></td>)}
				<td></td>
				<td></td>
				<td className="value-td">{ isGutter ? '' : item.totalQuantityJM.toFixed(2) }</td>
				<td className="value-td">{ isGutter ? '' : item.totalQuantityM2.toFixed(4) }</td>
				<td className="value-td">
					<div className="price-td-div" style={{ width: maxNetWidth }}>
						{ item.totalNetto.toFixed(2) }
					</div>
				</td>
				<td className="value-td">
					<div className="price-td-div" style={{ width: maxVatWidth }}>
						{ item.totalVat.toFixed(2) }
					</div>
				</td>
				<td className="value-td">
					<div className="price-td-div" style={{ width: maxPriceWidth }}>
						{ item.totalPrice.toFixed(2) }
					</div>
				</td>
				{isGutter ?
					<td onClick={(e) => {
						e.stopPropagation();
						if (props.close){
							props.close();
						}
						setGuttertoBeUpdated(true);
						setGutterCreatorFilters(JSON.parse(identifier) as GutteringSystemDataV2);
						history.push({ 
							pathname: `${process.env.PUBLIC_URL}/new-order/`,
							state: {...history.location.state, selectedView: 'gutters'}
						})						
					}}>
					<FaEdit className="manage-cart-icon" />
					</td>
					:
					<td></td>
				}
				<td></td>
			</tr>
			{
				isExpanded && _.map(item.products, (prd, prdIndex) => {
					return renderProduct(prd, prdIndex, identifier);
				})
			}
		</>
	}

	const renderProductMobile = (prd: ProductInCartTable, prdIndex: number, identifier: string) => {
		let isEditing = _.isEqual(editedProduct, prd as ProductInCartTable);
		let isPrdExpanded = itemsExpand[identifier].mobileExpanded[prdIndex];
		const isAdditional = prd.mainProductSymkar !== null && prd.mainProductSymkar !== undefined;
		const isGutter = prd.isGutter;
		const isIsod = prd.isIsod;
		return <div 
				className={`CartTable-cartProductElem block-item ${isPrdExpanded ? 'rotate' : ''}`} 
				style={{ padding: '0 0 1em 0' }}
				onClick={() => {
			const newExpand = {...itemsExpand};
			newExpand[identifier].mobileExpanded[prdIndex] = !isPrdExpanded;
			setItemsExpand(newExpand);
		}}>
			{(isGutter || isAdditional) && <Row>
				<Col>
					<b>{ prd.product.description }</b>
				</Col>
			</Row>}
			<Row>
				<Col>
					Ilość sztuk: <br />
					{isEditing ? <NumberInput
						value={similarEditQuantity}
						setValue={(value) => setSimilarEditQuantity(value)}
						minDesktopResolution={LG}
						stopPropagation={true}
						disabledEdit={similarEditQuantity === 1}
					/>
					: <b>{prd.quantity}</b>}
				</Col>
				{!isGutter && <Col>
					Wymiar: <br />
					{isEditing && prd.product.isDimension ? <NumberInput
							value={similarEditDimension}
							setValue={(value) => setSimilarEditDimension(value)}
							step={0.01}
							min={prd.product.dimensionMin ?? 0.01}
							max={prd.product.dimensionMax ?? Math.pow(10, 5)}
							fractDigits={3}
							minDesktopResolution={LG}
							stopPropagation={true}
							disabled={!prd.product.dimensionCanEdit}
							disabledEdit={similarEditDimension === 0.01}
						/>
					: <b>{prd.dimension?.toFixed(3) || '1.000'}</b>}
				</Col>}
				{_.map(additionslist(), ({ name }, addIndex) => {
					let prdAddIndex = _.findIndex(prd.product.additions, (prdAdd) => prdAdd.name===name);
					return (prdAddIndex > -1 && prd.additionsSelected) ? <Col key={`${identifier}-${prdIndex}-${addIndex}`}>
						{name}: <br />
						{
							(isEditing ?
								<Form.Check 
									name={name} type="checkbox" 
									defaultChecked={prd.additionsSelected[prdAddIndex]}
									onChange={() => {
										let newArr = [...similarAdditions];
										newArr[addIndex] = !similarAdditions[addIndex];
										setSimilarAdditions(newArr);
									}}
									onClick={event => event.stopPropagation()}
									disabled={prd.product.additions[prdAddIndex].default}
								/> :
								(prd.additionsSelected[prdAddIndex] ? <FaCheck /> : <MdClose />)
							)
						}
					</Col> : ''
				})}
			</Row>
			<Collapse in={isPrdExpanded}>
				<section className="CartTable-mobileProductExpanded">
					{!isGutter && <Row>
						<Col>
							JM: <br />
							{
								(isEditing && !isGutter && !isAdditional && !isIsod && prd.product.priceInfo.units.length > 1) ? (
									<Form.Control 
										as="select"
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											setSimilarEditUnit(prd.product.priceInfo.units[parseInt(e.currentTarget.value)]);
										}}
										onClick={(e: React.MouseEvent) => {e.stopPropagation();}}
									>
										{prd.product.priceInfo.units.map((unit, index) => {
											return	<option 
														key={`${prd.product.symKar}-${unit.unit}`} 
														value={index}
														selected={unit.unit === similarEditUnit.unit}
													>
														{unit.description}
													</option>
										})}
									</Form.Control>
								) : prd.unit?.unit ?? prd.product.unit
							}
						</Col>
						<Col>Ilość w JM: <br /> <b>{ (isEditing ? totalSimilarQuantity : prd.quantityJm).toFixed(2) }</b></Col>
						<Col>Ilość w m<sup>2</sup>: <br /> <b>{ (isEditing ? (totalSimilarQuantity * prd.product.priceInfo.converterM2) : prd.quantityM2).toFixed(4) }</b></Col>
					</Row>}
					<Row>
						<Col>Netto: <br /> <b>{ (isEditing ? editedProductNetto() : getSummaryNetto(prd)).toFixed(2) }</b></Col>
						<Col>VAT: <br /> <b>{ (isEditing ? editedProductVat() : getSummaryVat(prd)).toFixed(2) }</b></Col>
						<Col>Brutto: <br /> <b>{ (isEditing ? totalEditedProductPrice() : getTotalPrice(prd)).toFixed(2) }</b></Col>
					</Row>
					<div className="CartTable-SimilarElem">
						{isEditing ?
							<FaCheck className={`manage-cart-icon fa-check ${!isSavable ? 'disabled-icon' : ''}`} size={22} onClick={(event) => {
								event.stopPropagation();
								saveProduct();
							}} /> :
							(prd.product.isEditable ?? true) && <FaEdit className="manage-cart-icon" size={22} onClick={(event) => {
								event.stopPropagation();
								editProduct(prd);
							}} />} <FaTrashAlt className="manage-cart-icon" size={22} onClick={() => {deleteProduct(prd)}
						} />
					</div>
				</section>
			</Collapse>
		</div>
	}

	const renderItemMobile = (item: CartTableItem) => {
		let isGutter: boolean = false;
		let mainProductSymkar = null;
		let isAdditional: boolean = false;
		if(item.products.length > 0) {
			isGutter = item.products[0].isGutter;
			mainProductSymkar = item.products[0].mainProductSymkar;
		}
		isAdditional = mainProductSymkar !== null && mainProductSymkar !== undefined;
		
		const {identifier} = item;
		let isExpanded: boolean = itemsExpand[identifier].isExpanded;

		return <div>
			<div onClick={() => {
				const newExpand = {...itemsExpand};
				newExpand[identifier].isExpanded = !isExpanded;
				setItemsExpand(newExpand);
			}} className={`CartTable-assortAdditElem ${isAdditional ? 'additional-item' : ''}`}>
				<div style={{width: '92%'}}>{item.title}</div>
				<div style={{width: '8%'}}>{isExpanded ? <FaAngleDown size='30px' /> : <FaAngleUp size='30px' />}</div>
			</div>
			{item.products.length > 0 && isExpanded && <>
				<div className="CartTable-collapseSummary">
					{!isGutter && <Row>
						<Col>JM: <br /> <b>{item.products[0].product.priceInfo.units.reduce((acc, u, index) => {return acc + u.description + (index < item.products[0].product.priceInfo.units.length-1 ? ', ' : '')}, '' )}</b></Col>
						<Col>Ilość w JM: <br /> <b>{item.totalQuantityJM.toFixed(2)}</b></Col>
						<Col>Ilość w m<sup>2</sup>: <br /> <b>{item.totalQuantityM2.toFixed(4)}</b></Col>
					</Row>}
					<Row>
						<Col>Netto: <br /> <b>{item.totalNetto.toFixed(2)}</b></Col>
						<Col>VAT: <br /> <b>{item.totalVat.toFixed(2)}</b></Col>
						<Col>Brutto: <br /> <b>{item.totalPrice.toFixed(2)}</b></Col>
					</Row>
				</div>
				{_.map(item.products, (prd, prdIndex) => {
					return <Fragment key={`${identifier}-${prdIndex}`}>{renderProductMobile(prd, prdIndex, identifier)}</Fragment>
				})}
			</>}
		</div>
	}

	return (<>
		<div className="CartTable-expand-buttons" ref={headerRef}>
			<ButtonComponent marginLeft='0' text="ROZWIŃ WSZYSTKO" onClick={expandAll} />
			<ButtonComponent marginRight='0' text="ZWIŃ WSZYSTKO" onClick={hideAll} />
		</div>
		{isDesktop ?
			<Table size="sm" borderless className="CartTable-similarProductsTable">
				<thead style={{ height: '42px', position: 'sticky', top: '0px' }}>
					<tr style={{ height: '42px' }}>
						<th>LP.</th>
						<th style={{ width: '300px' }}>Nazwa</th>
						<th style={{ width: '90px', textAlign: "center" }}>Ilość [sztuk]</th>
						<th style={{ width: '80px', textAlign: "center" }}>Wymiar</th>
						{_.map(additionslist(), (addition, index) =>
							<th key={'cartAddition' + index}>{addition.name}</th>
						)}
						<th>JM</th>
						<th style={{ width: '60px', textAlign: "center" }}>Cena za JM</th>
						<th>Ilość w JM</th>
						<th>ILOŚĆ w m<sup>2</sup></th>
						<th>Netto</th>
						<th>Vat</th>
						<th>BRUTTO</th>
						<th style={{ width: '21px' }} />
						<th style={{ width: '21px' }} />
					</tr>
				</thead>
				{(_.isEmpty(cartContent.productGroups) && _.isEmpty(cartContent.gutterGroups)) || _.isEmpty(itemsExpand) ?
					<tbody>
						<tr style={{ textAlign: 'center' }}>
							<td colSpan={10}>Brak wybranych produktów.</td>
						</tr>
					</tbody>
					:
					<>
						{_.map(cartTableContent.mainItems, (val, key) => {
							const {isAdditionalExpanded} = itemsExpand[key];
							return <Fragment key={key}>
								<tbody className="CartTable-item" key={`${key}-container`}>
									{renderItem(val)}
									{cartTableContent.additionalItems[key] && [
									<tr key={`${key}-additionalOvergroupTr`} className="additionalOvergroupTr" onClick={() => {
										let newExpand = {...itemsExpand};
										newExpand[key].isAdditionalExpanded = !isAdditionalExpanded;
										setItemsExpand(newExpand);
									}}>
										<td>{ isAdditionalExpanded ? <FaAngleDown /> : <FaAngleRight />}</td>
										<td>Asortyment Dodatkowy</td>
										<td className="value-td">{ val.additionalItemsSummary.quantity }</td>
										<td colSpan={4}></td>
										{_.map(additionslist(), ({name}) => <td key={`Additional-addition-${name}`}></td>)}
										<td className="value-td">
											<div className="price-td-div" style={{ width: maxNetWidth }}>
												{ val.additionalItemsSummary.netto.toFixed(2) }
											</div>
										</td>
										<td className="value-td">
											<div className="price-td-div" style={{ width: maxVatWidth }}>
												{ val.additionalItemsSummary.vat.toFixed(2) }
											</div>
										</td>
										<td className="value-td">
											<div className="price-td-div" style={{ width: maxPriceWidth }}>
												{ val.additionalItemsSummary.gross.toFixed(2) }
											</div>
										</td>
										<td></td>
										<td></td>
										<td></td>
									</tr>, (() => {
										if(isAdditionalExpanded) {
											let prdIndex = 1;
											return _.map(Object.values(cartTableContent.additionalItems[key]), (additionalItem) => {
												return _.map(additionalItem.products, (prd) => {
													prdIndex++;
													return renderProduct(prd, prdIndex, additionalItem.identifier);
												})
											})
										}
									})()]}
								</tbody>
								<tbody key={`${key}-gap`}><tr style={{ height: '7px' }}></tr></tbody>
							</Fragment>
						})}
						{!_.isEmpty(cartTableContent.isodItems) && <>
								<tbody className="CartTable-group">
									<tr className="CartTable-expandedTr overgroup-expand" onClick={() => setIsodCollapse(!isodCollapse)}>
										<td>{ isodCollapse ? <FaAngleDown /> : <FaAngleRight />}</td>
										<td>ISOD</td>
										<td></td>
										<td></td>
										<td></td>
										{_.map(additionslist(), ({name}) => <td key={`ISOD-addition-${name}`}></td>)}
										<td></td>
										<td></td>
										<td></td>
										<td className="value-td">
											<div className="price-td-div" style={{ width: maxNetWidth }}>
												{totalIsodNetto()}
											</div>
										</td>
										<td className="value-td">
											<div className="price-td-div" style={{ width: maxVatWidth }}>
												{totalIsodVat()}
											</div>
										</td>
										<td className="value-td">
											<div className="price-td-div" style={{ width: maxPriceWidth }}>
												{totalIsodPrice()}
											</div>
										</td>
										<td></td>
										<td></td>
									</tr>
									{isodCollapse && _.map(cartTableContent.isodItems, (val, key) => {
										return <Fragment key={key}>{renderItem(val)}</Fragment>
									})}
								</tbody>
								<tbody><tr style={{ height: '7px' }}></tr></tbody>
							</>
						}
						{!_.isEmpty(cartTableContent.gutteringsItems) && 
							<tbody className="CartTable-group">
								<tr className="CartTable-expandedTr overgroup-expand" onClick={() => setGutterCollapse(!gutterCollapse)}>
									<td>{ gutterCollapse ? <FaAngleDown /> : <FaAngleRight />}</td>
									<td>Orynnowanie</td>
									<td></td>
									<td></td>
									{_.map(additionslist(), ({name}) => <td key={`Gutters-addition-${name}`}></td>)}
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td className="value-td">
										<div className="price-td-div" style={{ width: maxNetWidth }}>
											{totalGutterNetto()}
										</div>
									</td>
									<td className="value-td">
										<div className="price-td-div" style={{ width: maxVatWidth }}>
											{totalGutterVat()}
										</div>
									</td>
									<td className="value-td">
										<div className="price-td-div" style={{ width: maxPriceWidth }}>
											{totalGutterPrice()}
										</div>
									</td>
									<td></td>
									<td></td>
								</tr>
								{gutterCollapse && _.map(cartTableContent.gutteringsItems, (val, key) => {
									return <Fragment key={key}>{renderItem(val)}</Fragment>
								})}
							</tbody>
						}
					</>
				}
			</Table>
			:
			<>
				{((_.isEmpty(cartContent.productGroups) && _.isEmpty(cartContent.gutterGroups)) || _.isEmpty(itemsExpand)) ? (
					<div className='d-flex justify-content-center'>
						<div className='d-flex CartTable-empty-info'>
							Brak wybranych produktów.
						</div>
					</div>
				) : 
				[
					_.map(cartTableContent.mainItems, (val, key) => {
						const {isAdditionalExpanded} = itemsExpand[key];
						return <div style={{marginBottom: '15px'}} key={key}>
							{renderItemMobile(val)}
							{Boolean(cartTableContent.additionalItems[key]) && 
								[<div key={`${key}-additionalOvergroup-expander`} onClick={() => {
									let newExpand = {...itemsExpand};
									newExpand[key].isAdditionalExpanded = !isAdditionalExpanded;
									setItemsExpand(newExpand);
								}} className="CartTable-assortAdditElem CartTable-productGroup CartTable-additionalAssortmentContainer">
									<div style={{width: '92%'}}><b>Asortyment dodatkowy</b></div>
									<div style={{width: '8%'}}>{isAdditionalExpanded ? <FaAngleDown size='30px' /> : <FaAngleUp size='30px' />}</div>
								</div>, (() => {
									if(isAdditionalExpanded) {
										let prdIndex = 0;
										return <Fragment key={`${key}-additionalOvergroup`}>
											<div className="CartTable-collapseSummary">
												<Row>
													<Col>Netto: <br /> <b>{val.additionalItemsSummary.netto.toFixed(2)}</b></Col>
													<Col>VAT: <br /> <b>{val.additionalItemsSummary.vat.toFixed(2)}</b></Col>
													<Col>Brutto: <br /> <b>{val.additionalItemsSummary.gross.toFixed(2)}</b></Col>
												</Row>
											</div>
											<div>
												{
													_.map(Object.values(cartTableContent.additionalItems[key]), (additionalItem) => {
														return _.map(additionalItem.products, (prd) => {
															prdIndex++;
															return <Fragment key={`${key}-additional-${additionalItem.identifier}-${prd.indexInGroup}`}>
																{renderProductMobile(prd, prdIndex, additionalItem.identifier)}
															</Fragment>
														})
													})
												}
											</div>
										</Fragment>
									}
								})()]
							}
						</div>
					}),
					!_.isEmpty(cartTableContent.isodItems) && <div key="ISOD-overgroup" onClick={() => {setIsodCollapse(!isodCollapse)}} className="CartTable-assortAdditElem CartTable-productGroup">
						<div style={{width: '92%'}}><b>ISOD</b></div>
						<div style={{width: '8%'}}>{isodCollapse ? <FaAngleDown size='30px' /> : <FaAngleUp size='30px' />}</div>
					</div>,
					isodCollapse && _.map(cartTableContent.isodItems, (val, key) => {
						return <Fragment key={key}>{renderItemMobile(val)}</Fragment>
					}),
					!_.isEmpty(cartTableContent.gutteringsItems) && <div  key="Gutters-overgroup" onClick={() => {setGutterCollapse(!gutterCollapse)}} className="CartTable-assortAdditElem CartTable-productGroup">
						<div style={{width: '92%'}}><b>Orynnowanie</b></div>
						<div style={{width: '8%'}}>{gutterCollapse ? <FaAngleDown size='30px' /> : <FaAngleUp size='30px' />}</div>
					</div>,
					gutterCollapse && _.map(cartTableContent.gutteringsItems, (val, key) => {
						return <Fragment key={key}>{renderItemMobile(val)}</Fragment>
					})
				]}

				<div className='CartTable-pageUpButton-box' >
					<div className="CartTable-pageUpButton" >
						{showScrollUpButton && (
							<Button className="ml-auto" onClick = { scrollToTop } >
								<TiArrowUpThick />
							</Button>
						)}
					</div>
				</div>
			</>
		}
	</>);
}
