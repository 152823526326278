import { UserRegistrationInfo, dataActions } from "reducers";
import { post, API_ADDRESS, put, get } from "lib/communication";
import store from "store";
import { produce } from 'immer';
import { PromotionalOrder, PromotionalProduct, SaleAgent, Option, DeliveryOption, Address, TermsResponse } from "lib/types";
import { Response, PromotionalProductResponse, PromotionalPointsResponse, PromotionalProductsListResponse } from "./communicationTypes";
import { userService } from "services";

export async function addRoofer(roofer: UserRegistrationInfo) {
	const route: string = 'user/addRoofer';
	setPromotionalProgramFetching({ isFetching: true });
	try {
		const { login, logo } = userService.getUser();
		const data = await post(
			`${API_ADDRESS}${route}`,
			{
				login,
				logo,
				firstName: roofer.user.firstName,
				lastName: roofer.user.lastName,
				street: roofer.user.street,
				buildingNo: roofer.user.buildingNo,
				apartmentNo: roofer.user.apartmentNo,
				postalCode: roofer.user.postalCode,
				town: roofer.user.town,
				nip: roofer.user.nip,
				regon: roofer.company.regon,
				phone: roofer.user.phone,
				email: roofer.user.email,
				company: roofer.company.name,
			}
		);
		if (data.ok) {
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.log("Error ", err);
		throw err;
	} finally {
		setPromotionalProgramFetching({ isFetching: false });
	}
}

export async function addSalesAgent(salesAgent: UserRegistrationInfo): Promise<boolean> {
	const route: string = 'user/addSalesAgent';
	setPromotionalProgramFetching({ isFetching: true });
	try {
		const { login, logo } = userService.getUser();
		const data = await post(
			`${API_ADDRESS}${route}`,
			{
				login,
				logo,
				firstName: salesAgent.user.firstName,
				lastName: salesAgent.user.lastName,
				street: salesAgent.user.street,
				buildingNo: salesAgent.user.buildingNo,
				apartmentNo: salesAgent.user.apartmentNo,
				postalCode: salesAgent.user.postalCode,
				pesel: salesAgent.user.pesel,
				taxOffice: salesAgent.user.treasuryOffice,
				town: salesAgent.user.town,
				nip: salesAgent.user.nip,
				regon: salesAgent.company.regon,
				phone: salesAgent.user.phone,
				email: salesAgent.user.email,
				company: salesAgent.company.name,
			}
		);
		if (data.ok) {
			return true;
		}
	} catch (err) {
		console.log("Error ", err);
		throw err;
	} finally {
		setPromotionalProgramFetching({ isFetching: false });
	}
	return false;
}

export async function getSalesAgents(): Promise<boolean> {
	const route: string = 'user/getSalesAgents';
	setPromotionalProgramFetching({ isFetching: true });
	try {
		const { login, logo } = userService.getUser();
		const data = await post<SaleAgent[]>(
			`${API_ADDRESS}${route}`,
			{
				login,
				logo
			}
		);
		if (data.ok && data.parsedBody) {
			store.dispatch(dataActions.setSaleAgents(data.parsedBody));
			return true;
		}
	} catch (err) {
		console.log("Error ", err);
	} finally {
		setPromotionalProgramFetching({ isFetching: false });
	}
	return false;
}

export async function blockSalesAgent(salesAgent: SaleAgent): Promise<boolean> {
	const route: string = 'user/blockSalesAgent';
	setPromotionalProgramFetching({ traderBlockIsFetching: true });
	try {
		const { login, logo } = userService.getUser();
		store.dispatch(dataActions.blockSalesAgent(salesAgent));
		const data = await put<Response>(
			`${API_ADDRESS}${route}`,
			{
				login,
				logo,
				salesAgentLogin: salesAgent.login
			}
		);

		if (!data.ok) {
			store.dispatch(dataActions.activateSalesAgent(salesAgent));
		}
	} catch (err) {
		store.dispatch(dataActions.activateSalesAgent(salesAgent));
		console.log("Error ", err);
		return false;
	} finally {
		setPromotionalProgramFetching({ traderBlockIsFetching: false });
	}
	return true;
}

export async function activateSalesAgent(salesAgent: SaleAgent): Promise<boolean> {
	const route: string = 'user/activateSalesAgent';
	setPromotionalProgramFetching({ traderBlockIsFetching: true });
	try {
		const { login, logo } = userService.getUser();
		store.dispatch(dataActions.activateSalesAgent(salesAgent));
		const data = await put<Response>(
			`${API_ADDRESS}${route}`,
			{
				login,
				logo,
				salesAgentLogin: salesAgent.login
			}
		);

		if (!data.ok) {
			store.dispatch(dataActions.blockSalesAgent(salesAgent));
		}
	} catch (err) {
		store.dispatch(dataActions.blockSalesAgent(salesAgent));
		console.log("Error ", err);
		return false;
	} finally {
		setPromotionalProgramFetching({ traderBlockIsFetching: false });
	}
	return true;
}

export async function getPromotionalProducts(promotion: String): Promise<boolean> {
	const route: string = 'promotion/promotionalProducts';
	setPromotionalProgramFetching({ isFetchingProducts: true });
	try {
		const { login } = userService.getUser();
		const data = await get<PromotionalProductsListResponse>(`${API_ADDRESS}${route}?login=${login}&&promotion=${promotion}`);

		if (data.ok && data.parsedBody) {
			store.dispatch(dataActions.setPromotionalProgram({
				products: data.parsedBody.products.reduce((acc: PromotionalProduct[], product: PromotionalProductResponse, index: number) => {
					acc[index] = {
						symKar: product.symKar,
						photo: product.photo,
						price: +product.price,
						description: product.description,
						points: +product.points,
						options: product.options.slice(0, -1).split(';') as Option[],
						group: product.group,
						isElectronicShipping: product.isElectronicShipping
					};
					return acc;
				}, []),
				delivery: data.parsedBody.delivery
			}));

			return true;
		}
	} catch (err) {
		console.log("Error ", err);
	} finally {
		setPromotionalProgramFetching({ isFetchingProducts: false });
	}
	return false;
}

export async function getPromotionOrders(promotion: String): Promise<boolean> {
	const route: string = 'promotion/promotionOrders';
	setPromotionalProgramFetching({ isFetching: true });
	try {
		const { login } = userService.getUser();
		const data = await post<PromotionalOrder[]>(
			`${API_ADDRESS}${route}`,
			{
				login,
				promotion
			}
		);

		if (data.ok) {
			const state = store.getState();
			store.dispatch(dataActions.setPromotionalProgram(
				produce(state.data.promotionalProgram, (draft) => {
					if (data.parsedBody)
						draft.orders = data.parsedBody.map((order: PromotionalOrder) => {
							return {
								date: order.date,
								quantity: order.quantity,
								description: order.description,
								price: +order.price,
								points: +order.points
							}
						});
				})
			));

			return true;
		}
	} catch (err) {
		console.log("Error ", err);
	} finally {
		setPromotionalProgramFetching({ isFetching: false });
	}
	return false;
}

export async function acceptTermsAndConditions(): Promise<boolean> {
	const route: string = 'promotion/acceptTermsAndConditions';
	setPromotionalProgramFetching({ isFetching: true });
	try {
		const {login, logo, company, promotionalProgramEnroll, promotionTermsChange} = userService.getUser();
		const data = await post<string>(`${API_ADDRESS}${route}`,
			{
				login,
				logo,
				company,
				promotionalProgramEnroll,
				promotionTermsChange
			});
		if (data.ok && data.parsedBody && data.parsedBody === 'OK.') {
			return true;
		}

		return false;
	} catch (err) {
		console.log("Error ", err);
		return false;
	}
}

export async function getTermsAndConditions(termName: string): Promise<boolean> {
	const route: string = 'promotion/getTermsAndConditions';
	setPromotionalProgramFetching({ isFetching: true });
	try {
		const data = await get<TermsResponse[]>(`${API_ADDRESS}${route}?termName=${termName}`);
		if (data.ok) {
			const state = store.getState();
			store.dispatch(dataActions.setPromotionalProgram(
				produce(state.data.promotionalProgram, (draft) => {
					if (data.parsedBody && data.parsedBody.length === 1)
						draft[termName === 'cookie_policy' ? 'cookiePolicy' : 'termsAndConditions'] = data.parsedBody[0]['content'];
				})
			));
			return true;
		} else
			return false;
	} catch (err) {
		console.log("Error ", err);
		return false;
	} finally {
		setPromotionalProgramFetching({ isFetching: false });
	}
}

export async function setTermsAndConditions(modifyTerms: string, termName: string): Promise<boolean> {
	const route: string = 'promotion/setTermsAndConditions';
	setPromotionalProgramFetching({ isFetching: true });
	try {
		const response = await put<Response>(
			`${API_ADDRESS}${route}`,
			{
				content: modifyTerms,
				name: termName
			}
		);

		getTermsAndConditions(termName);

		if (response.ok) {
			return true;
		}
		else
			return false;
	} catch (err) {
		console.log("Error ", err);
		return false;
	} finally {
		setPromotionalProgramFetching({ isFetching: false });
	}
}

export async function addPromotionalOrder(products: { quantity: number, symKar: string, option: Option }[], deliveryOption: DeliveryOption, address: Address): Promise<boolean> {
	const route: string = 'promotion/addOrder';
	setPromotionalProgramFetching({ isAddingOrder: true });
	try {
		let addr;
		switch (deliveryOption) {
			case "inputAddress":
				addr = `${address.firstName} ${address.lastName} ${address.companyName} ${address.addressId}`;
				break;
			case "distributorAddress":
				addr = "Distributor Address";
				break;
			case "selfPickup":
				addr = address.addressId; // TODO
				break;
			default:
				break;
		}
		const { login, logo } = userService.getUser();
		const data = await post<{error?: boolean;}>(
			`${API_ADDRESS}${route}`,
			{
				login,
				logo,
				address: addr,
				products: products.map((product) => {
					return {
						quantity: product.quantity,
						symKar: product.symKar,
						size: product.option === '' ? null : product.option
					}
				})
			}
		);

		if (data.parsedBody?.error) {
			return false;
		}

		if (data.ok) {
			return true;
		}

		return false;
	} catch (err) {
		console.log("Error ", err);
		return false;
	} finally {
		setPromotionalProgramFetching({ isAddingOrder: false });
	}
}

export async function getPromotionalPoints(promotion: String): Promise<boolean> {
	const route: string = 'promotion/points';
	setPromotionalProgramFetching({ isFetchingPoints: true });
	try {
		const { login } = userService.getUser();
		const data = await post<PromotionalPointsResponse>(
			`${API_ADDRESS}${route}`,
			{
				login,
				promotion
			}
		);

		if (data.ok && data.parsedBody) {
			store.dispatch(dataActions.setPromotionalProgramPoints(data.parsedBody.points));
			store.dispatch(dataActions.setPromotionalProgramPointsSpent(data.parsedBody.pointsSpent));
			return true;
		}
		return false;
	} catch (err) {
		console.log("Error ", err);
		return false;
	} finally {
		setPromotionalProgramFetching({ isFetchingPoints: false });
	}
}

function setPromotionalProgramFetching(isFetching: object) {
	const state = store.getState();
	store.dispatch(dataActions.setPromotionalProgram(
		produce(state.data.promotionalProgram, (draft) => {
			draft.props = {
				...draft.props,
				...isFetching,
			};
		})
	));
}
