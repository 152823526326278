import { API_ADDRESS, get, post } from 'lib/communication';
import store from 'store';
import { dataActions } from 'reducers';
import { WebserviceTestResult } from 'lib/types';
import { produce } from 'immer';


export async function getTestsResults(): Promise<boolean> {
	const route: string = 'tests/getTestsResults';
	setWebserviceTestsFetching({ isFetching: true });
	try {
		const data = await get<WebserviceTestResult[]>(`${API_ADDRESS}${route}`);
		if (data.ok && data.parsedBody) {
			store.dispatch(dataActions.setWebserviceTestsResults(data.parsedBody));
			return true;
		} else return false;
	} catch (err) {
		console.log('Error ', err);
		return false;
	} finally {
		setWebserviceTestsFetching({ isFetching: false });
	}
}

export async function runTests(value: string): Promise<boolean> {
	const route: string = 'tests/runTests';
	try {
		const response = await post<Response>(`${API_ADDRESS}${route}`, {
			value: value
		});
		if (response.ok && response.parsedBody) {
			return true;
		} else {
			return false;
		}
	} catch (error) {
		console.error(error);
		return false;
	} finally {

	}
}

function setWebserviceTestsFetching(isFetching: object) {
	const state = store.getState();
	store.dispatch(
		dataActions.setWebserviceTests(
			produce(state.data.webserviceTestResults, (draft) => {
				draft.props = {
					...draft.props,
					...isFetching,
				};
			})
		)
	);
}
