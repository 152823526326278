import React, { useState, useEffect } from 'react';
import { Col, Form, FormLabel, Modal, Spinner } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { LG } from 'lib/util';
import { Param } from 'lib/types';
import { userService, ORD_PAGE_COUNT } from 'services';
import { updateUserParams } from 'lib/communication/userInfo';
import ButtonComponent from 'components/ButtonComponent';

interface OrdersSettingsProps {
	show: boolean;
	close: () => void;
    updateState: (newValue: number) => void;
}

export default function OrdersSettings(props: OrdersSettingsProps) {
    const isFetching = useSelector((state: AppState) => state.data.userInfo.props.isFetching);

	const isDesktop = useMediaQuery({ minWidth: LG });
    const [Size, setSize] = useState(10);
    const [settingsMessage, setSettingsMessage] = useState('');

    useEffect(() => {
        setSize(parseInt(userService.getUserParam(ORD_PAGE_COUNT) ?? '10'));
    },[]);

    const handleSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSize(parseInt(event.currentTarget.value));
    }

    const handleSave = async () => {

        const updateParamPageCount: Param = {
			ParamName: ORD_PAGE_COUNT,
			ParamValue: Size.toString()
		}

        const params = await updateUserParams(updateParamPageCount);
        setSettingsMessage(params.ok? 'Zapisano': 'Błąd');

        props.updateState(Size);
    }

    return (
        <>
            <Modal  show={props.show && isDesktop} 
                    onHide={props.close} 
                    size="lg" 
                    dialogClassName="Order-dialog dialog-settings" 
                    backdrop="static" 
                    centered>
                <Modal.Header closeButton>
					<Modal.Title>
						Ustawienia
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
                    <FormLabel column xs="auto" style={{paddingLeft: "0"}} htmlFor="rowCount" className="pr-0">
						Ilość na stronie
					</FormLabel>
                    <Col xs="3" style={{paddingLeft: "0"}}>
						<Form.Control
							as="select"
							size="sm"
							id="rowCount"
							value={Size}
							onChange={handleSizeChange}
						>
							<option value={10}>10</option>
							<option value={20}>20</option>
							<option value={50}>50</option>
							<option value={100}>100</option>
						</Form.Control>
					</Col>
				</Modal.Body>
                <Modal.Footer>
                    <ButtonComponent
                        text='Zapisz'
                        onClick={handleSave}
                        variant='primary'
                        margin='0.25rem'
                    />
                    <div style={{ fontStyle: 'italic', marginRight: 'auto', color: settingsMessage==='Błąd' ? 'red' : 'green' }}>{isFetching ? <Spinner animation="border" /> : settingsMessage}</div>
                    <ButtonComponent  
                        text='Zamknij' 
                        onClick={props.close}
                        variant='secondary'
                        margin='0.25rem'
                    />
                </Modal.Footer>
            </Modal>
        </>
        );
}