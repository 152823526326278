import React, { useState, useEffect, useMemo } from 'react';
import {
	Table,
	Navbar,
	Button,
	OverlayTrigger,
	Tooltip,
	Nav,
	Form,
	Col,
	Row,
	Collapse,
	FormLabel,
	Modal,
	Popover,
} from 'react-bootstrap';
import {
	FaRegTrashAlt,
	FaRegFilePdf,
	FaRegEdit,
	FaSearch,
	FaSlidersH,
	FaCheck,
	FaRegClock,
	FaTimes,
	FaCopy,
} from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { getTodayDate, LG } from 'lib/util';
import './Order.scss';
import _ from 'lodash';
import { AppState, dataActions } from 'reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersList, deleteOrder, getOrderPdf, getOrderStatus } from 'lib/communication/orders';
import { OrderDirection, Order, CartContent, OrderStatusName, OrderStatusNameToLabel, OrderStatusNames } from 'lib/types';
import Pagination from 'components/Pagination';
import SortButtons from 'components/SortButtons';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { SortButtonsValue } from 'components/SortButtons/SortButtons';
import Spinner from 'components/Spinner/Spinner';
import store from 'store';
import { isSafari } from 'lib/util';
import ButtonComponent from 'components/ButtonComponent';
import { userService, ORD_PAGE_COUNT } from 'services';
import { BsFillGearFill } from 'react-icons/bs';
import OrdersSettings from './OrdersSettings';
import OrderStatus from './OrderStatus';
import OrderTooltip from './OrderTooltip';
import { MdMoreHoriz } from 'react-icons/md';

const ORDER_STATUS_NAMES_ICON: Record<string, JSX.Element> = {
	Przyjęte: <FaCheck />,
	Oczekujące: <FaRegClock />,
	Realizowane: <FaRegClock />,
	Zrealizowane: <FaCheck />,
	Zamknięte: <FaTimes />,
};

const SORT_VALUES_MOBILE: Record<string, SortButtonsValue> = {
	idDown: { direction: OrderDirection.Desc, group: 'orderNumber' },
	idUp: { direction: OrderDirection.Asc, group: 'orderNumber' },
	clientIdDown: { direction: OrderDirection.Desc, group: 'customerOrderNumber' },
	clientIdUp: { direction: OrderDirection.Asc, group: 'customerOrderNumber' },
	dateDown: { direction: OrderDirection.Desc, group: 'date' },
	dateUp: { direction: OrderDirection.Asc, group: 'date' },
	statusDown: { direction: OrderDirection.Desc, group: 'status' },
	statusUp: { direction: OrderDirection.Asc, group: 'status' },
	netDown: { direction: OrderDirection.Desc, group: 'net' },
	netUp: { direction: OrderDirection.Asc, group: 'net' },
	vatDown: { direction: OrderDirection.Desc, group: 'vat' },
	vatUp: { direction: OrderDirection.Asc, group: 'vat' },
	grossDown: { direction: OrderDirection.Desc, group: 'gross' },
	grossUp: { direction: OrderDirection.Asc, group: 'gross' },
	weightDown: { direction: OrderDirection.Desc, group: 'weight' },
	weightUp: { direction: OrderDirection.Asc, group: 'weight' },
};

type OrdersState = {
	orderNumber: string;
	customerOrderNumber: string;
	from: string;
	to: string;
	description: string;
	statusFilter: { [NAME in OrderStatusName]: boolean };
	advancedSearch: boolean;
	orderBy: string;
	orderDirection: OrderDirection;
	pageSize: number;
}

const ORDER_STATE: OrdersState = {
	orderNumber: '',
	customerOrderNumber: '',
	from: '',
	to: '',
	description: '',
	statusFilter: Object.fromEntries(_.map(OrderStatusNames, (name) => name === 'accepted' ? [name, true] : [name, false])) as { [NAME in OrderStatusName]: boolean },
	advancedSearch: false,
	orderBy: 'date',
	orderDirection: OrderDirection.Desc,
	pageSize: 10
};

export default function Orders() {

	const isDesktop = useMediaQuery({ minWidth: LG });

	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const state: OrdersState = (location.state as OrdersState) || ORDER_STATE;

	const { page } = useParams<{ page: string | undefined }>();

	const orders = useSelector((state: AppState) => state.data.orders.orders);
	const isFetchingOrders = useSelector((state: AppState) => state.data.orders.props.isFetchingOrders);
	const pdfIsFetching = useSelector((state: AppState) => state.data.orders.props.pdfIsFetching);
	const orderStatus = useSelector((state: AppState) => state.data.orders.orderStatus);

	const [errorPdf, setErrorPdf] = useState(false);

	const [scrollPosition, setScrollPosition] = useState(0);

	const [error, setError] = useState(false);
	const [showOrdersSettings, setShowOrdersSettings] = useState(false);
	const [validated, setValidated] = useState(false);

	const [orderNumber, setOrderNumber] = useState('');
	const [customerOrderNumber, setCustomerOrderNumber] = useState('');
	const [from, setFrom] = useState('');
	const [to, setTo] = useState('');
	const [description, setDescription] = useState('');

	const [orderBy, setOrderBy] = useState('date');
	const [orderDirection, setOrderDirection] = useState(OrderDirection.Desc);

	const [statusFilter, setStatusFilter] = useState(Object.fromEntries(_.map(OrderStatusNames, (name) => name === 'accepted' ? [name, true] : [name, false])) as { [NAME in OrderStatusName]: boolean });
	const statusFilterCount = useMemo(() => _.reduce(statusFilter, (acc, val) => acc += val ? 1 : 0, 0), [statusFilter]);
	const [advancedSearch, setAdvancedSearch] = useState(false);
	const [opens, setOpens] = useState([false]);

	const [selectedFilteredOrders, setSelectedFilteredOrders] = useState([] as Order[]);
	const getAllSelectedOrders = () => Object.keys(orders)
		.reduce((acc, key) => statusFilter[key as OrderStatusName] && orders[key as OrderStatusName]
		? acc.concat(orders[key as OrderStatusName] as Order[]) : acc, [] as Order[]);
	const replaceStatusFilter = (status: OrderStatusName) => Object.fromEntries(_.map(OrderStatusNames, (name) => name === status ? [name, true] : [name, false])) as { [NAME in OrderStatusName]: boolean };
	const toggleStatusFilter = (status: OrderStatusName) => ({ ...statusFilter, [status]: !statusFilter[status] });

	const pageNumber = parseInt(page ?? '1') - 1;
	const calculateNumberOfPagesForPageSize = (pageSize: number): number =>
		Math.max(1, _.ceil(_.size(selectedFilteredOrders) / pageSize));
	const numberOfPages = calculateNumberOfPagesForPageSize(state.pageSize);
	const ordersOnPage = _.take(_.slice(selectedFilteredOrders, pageNumber * state.pageSize), state.pageSize);

	const [orderToDelete, setOrderToDelete] = useState<Order | undefined>(undefined);
	const [orderToCopy, setOrderToCopy] = useState<Order | undefined>(undefined);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [bottomPagVisibility, setBottomPagVisibility] = useState(false);
	const [showDeleteStatus, setShowDeleteStatus] = useState(false);
	const [showCopyOrderConfirmation, setShowCopyOrderConfirmation] = useState(false);

	const [maxNet, setMaxNet] = useState(0);
	const [maxVat, setMaxVat] = useState(0);
	const [maxGross, setMaxGross] = useState(0);
	const [maxWeight, setMaxWeight] = useState(0);

	useEffect(() => {
		(document.activeElement as HTMLElement).blur();
	}, [history.location.state]);

	useEffect(() => {
		const newPageSize = parseInt(userService.getUserParam(ORD_PAGE_COUNT) ?? '10');
		const newNumberOfPages = calculateNumberOfPagesForPageSize(newPageSize);
		const pushedPageNumber = pageNumber + 1 > newNumberOfPages ? newNumberOfPages : pageNumber + 1;
		history.push(`${process.env.PUBLIC_URL}/orders/${pushedPageNumber}`, {
			...state,
			pageSize: newPageSize
		});
		// eslint-disable-next-line
	}, []);

	// orders page initial load effect
	useEffect(() => {
		store.dispatch(dataActions.clearLocalEditCart());
		store.dispatch(dataActions.setEditCart({
			summaryPrice: 0,
			itemsCount: 0,
			summaryPriceBeforeDiscount: 0,
			summaryDiscont: 0,
			summaryVat: 0,
			productGroups: [],
			gutterGroups: []
		} as CartContent))
	}, []);

	useEffect(() => {
		window.scrollTo(0, scrollPosition);
	}, [scrollPosition]);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
	}, []);

	// on page navigation
	useEffect(() => {
		setOrderNumber(state.orderNumber);
		setCustomerOrderNumber(state.customerOrderNumber);
		setFrom(state.from);
		setTo(state.to);
		setDescription(state.description);
		setStatusFilter(state.statusFilter);
		setAdvancedSearch(state.advancedSearch);
		setOrderBy(state.orderBy);
		setOrderDirection(state.orderDirection);
	}, [state]);

	const filterAndSetOrders = () => {
		let filteredOrders = getAllSelectedOrders();
		if (orderNumber) {
			filteredOrders = _.filter(filteredOrders, (order) =>
				order.orderNumber.includes(orderNumber)
			);
		}

		if (advancedSearch) {
			if (customerOrderNumber) {
				filteredOrders = _.filter(
					filteredOrders,
					(order) => order.customerOrderNumber.includes(customerOrderNumber)
				);
			}
			if (from) {
				filteredOrders = _.filter(
					filteredOrders,
					(order) => new Date(from).getTime() - Date.parse(order.date) <= 0
				);
			}
			if (to) {
				filteredOrders = _.filter(
					filteredOrders,
					(order) => new Date(to).getTime() - Date.parse(order.date) >= 0
				);
			}
			if (description) {
				filteredOrders = _.filter(filteredOrders, (order) => {
					const keywords = description.toLowerCase().split(/[ ,]+/);
					return _.some(keywords, (key) => order.description.toLowerCase().includes(key));
				});
			}
		}
		setSelectedFilteredOrders(_.orderBy(filteredOrders, orderBy, orderDirection));
	};

	// on any status filter change
	useEffect(() => {
		let allFetched = true;
		_.forEach(_.pickBy(statusFilter, (value) => value === true), (value, status) => {
			if (orders[status as OrderStatusName] === null && !isFetchingOrders) {
				allFetched = false;
				getOrdersList(status as OrderStatusName).then(result => setError(!result));
			}
		});
		if (allFetched) {
			filterAndSetOrders();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orders, orderNumber, customerOrderNumber, from, to, description, statusFilter, advancedSearch, orderBy, orderDirection]);

	// after getOrdersList finishes and sets new orders
	useEffect(() => {
		filterAndSetOrders();
		// eslint-disable-next-line
	}, [orders]);

	useEffect(() => {
		handleResize();
	}, [state.pageSize, pageNumber]);

	// calculate width of rendered prices in table for design purpose
	const netPriceWidth = useMemo(() => {
		const d = document.createElement('div');
		d.innerHTML = maxNet.toFixed(2);
		d.classList.add('measurer');
		document.querySelector('.App-body')?.appendChild(d);
		return d.getBoundingClientRect().width;
	}, [maxNet]);
	const vatPriceWidth = useMemo(() => {
		const d = document.createElement('div');
		d.innerHTML = maxVat.toFixed(2);
		d.classList.add('measurer');
		document.querySelector('.App-body')?.appendChild(d);
		return d.getBoundingClientRect().width;
	}, [maxVat]);
	const grossPriceWidth = useMemo(() => {
		const d = document.createElement('div');
		d.innerHTML = maxGross.toFixed(2);
		d.classList.add('measurer');
		document.querySelector('.App-body')?.appendChild(d);
		return d.getBoundingClientRect().width;
	}, [maxGross]);
	const weightWidth = useMemo(() => {
		const d = document.createElement('div');
		d.innerHTML = maxWeight.toFixed(2);
		d.classList.add('measurer');
		document.querySelector('.App-body')?.appendChild(d);
		return d.getBoundingClientRect().width;
	}, [maxWeight]);

	useEffect(() => {
		if (ordersOnPage.length > 0) {
			setMaxNet((_.maxBy(ordersOnPage, order => order.net) as Order).net);
			setMaxVat((_.maxBy(ordersOnPage, order => order.vat) as Order).vat);
			setMaxGross((_.maxBy(ordersOnPage, order => order.gross) as Order).gross);
			setMaxWeight((_.maxBy(ordersOnPage, order => order.weight) as Order).weight);
		}
	}, [ordersOnPage]);

	const handleResize = () => {
		if (document.body.clientWidth < window.innerWidth) {
			setBottomPagVisibility(true);
		} else {
			setBottomPagVisibility(false);
		}
	}

	const handleFilterChange = (evt: React.FormEvent<HTMLInputElement>) => {
		const status = evt.currentTarget.value as OrderStatusName;
		// keep at least one checkbox selected
		if (!(statusFilter[status] && statusFilterCount === 1)) {
			setOpens([false]);
			history.push(`${process.env.PUBLIC_URL}/orders`, {
				...state,
				statusFilter: toggleStatusFilter(status),
				advancedSearch: advancedSearch
			});
		}
	};

	const handleSortChange = (value: SortButtonsValue) => {
		if (value.group && value.direction) {
			setOpens([false]);
			history.push(`${process.env.PUBLIC_URL}/orders`, {
				...state,
				orderBy: value.group,
				orderDirection: value.direction,
				advancedSearch: advancedSearch
			});
		}
	};

	const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			setValidated(true);
			return;
		}
		let dateTo = form.to.value;
		if (form.from.value && !form.to.value) {
			dateTo = getTodayDate();
		}
		setOpens([false]);
		history.push(`${process.env.PUBLIC_URL}/orders`, { ...state, orderNumber, customerOrderNumber, from, to: dateTo, description, statusFilter, advancedSearch, orderBy, orderDirection });
		handleResize();
	};

	const handleEditOrder = (orderId: string) => {
		history.push({
			pathname: `${process.env.PUBLIC_URL}/new-order`,
			state: {
				...(typeof history.location.state === 'object' ? history.location.state : {}),
				editedOrderId: orderId
			}
		});
	};

	const handleCopyOrder = (orderId: string) => {
		history.push({
			pathname: `${process.env.PUBLIC_URL}/new-order`,
			state: {
				...(typeof history.location.state === 'object' ? history.location.state : {}),
				copiedOrderId: orderId
			}
		});
	};

	const handleOrderDelete = () => {
		setShowDeleteConfirmation(false);
		if (orderToDelete) {
			deleteOrder(orderToDelete.id).then(result => {
				if (result) {
					setShowDeleteStatus(true);
				}
			});
			setOpens([false]);
		}
	};

	const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newPageSize = parseInt(event.currentTarget.value);
		const newNumberOfPages = calculateNumberOfPagesForPageSize(newPageSize);
		const pushedPageNumber = pageNumber + 1 > newNumberOfPages ? newNumberOfPages : pageNumber + 1;
		history.push(`${process.env.PUBLIC_URL}/orders/${pushedPageNumber}`, {
			...state,
			pageSize: newPageSize
		});
	};

	const handlePageSizeUpdate = (newValue: number) => {
		const newNumberOfPages = calculateNumberOfPagesForPageSize(newValue);
		const pushedPageNumber = pageNumber + 1 > newNumberOfPages ? newNumberOfPages : pageNumber + 1;
		history.push(`${process.env.PUBLIC_URL}/orders/${pushedPageNumber}`, {
			...state,
			pageSize: newValue
		});
	}

	const showOrderStatus = (order: Order) => {
		dispatch(dataActions.setOrderStatus({parts: orderStatus.parts, show: true, order: order}));
		getOrderStatus(order.id);
	}

	if (isFetchingOrders || error) {
		return <Spinner showError={error} />;
	}

	return (
		<>
			<OrderStatus />
			<OrdersSettings show={showOrdersSettings} close={() => setShowOrdersSettings(false)} updateState={handlePageSizeUpdate} />
			<Modal show={errorPdf || pdfIsFetching} onHide={() => setErrorPdf(false)} centered className="pdf-modal">
				<Modal.Header closeButton={errorPdf} className="pdf-modal-header" />
				<Modal.Body className="pdf-modal-body">
					<Spinner showError={errorPdf} toPdf={true} />
				</Modal.Body>
				<Modal.Footer className="pdf-modal-footer">
					{!pdfIsFetching && (
						<ButtonComponent
							text='Zamknij'
							onClick={() => setErrorPdf(false)}
						/>
					)}
				</Modal.Footer>
			</Modal>
			<Navbar className="sub-navbar" expand="lg">
				<Nav justify variant="pills" className="flex-grow-1">
					{_.map(OrderStatusNameToLabel, (name, status) => (
						<Nav.Item key={status}>
							<Nav.Link active={statusFilter[status as OrderStatusName] && statusFilterCount === 1}
								onClick={() => {
									history.push(`${process.env.PUBLIC_URL}/orders`, {
										...state,
										statusFilter: replaceStatusFilter(status as OrderStatusName)
									});
								}}>
								{name}
							</Nav.Link>
						</Nav.Item>
					))}
				</Nav>
			</Navbar>
			<Form onSubmit={handleSearchSubmit}
				className={isDesktop ? 'mb-2' : 'search-form-mobile'}
				style={{ minHeight: 112 }}
				noValidate
				validated={validated}>
				<div>
					{isDesktop ? (
						<Form.Row>
							<Form.Group as={Col}>
								<Form.Label>NUMER</Form.Label>
								<Form.Control type="text" placeholder="Numer zamówienia" id="orderNumber"
									value={orderNumber}
									onChange={(e) => setOrderNumber(e.target.value)} />
							</Form.Group>
							<Collapse in={advancedSearch}>
								<Form.Group as={Form.Row} style={{ width: '72%' }}>
									<Form.Group as={Col}>
										<Form.Label>NUMER KLIENTA</Form.Label>
										<Form.Control type="text" placeholder="Numer klienta" id="customerOrderNumber"
											value={customerOrderNumber}
											onChange={(e) => setCustomerOrderNumber(e.target.value)} />
									</Form.Group>
									<Form.Group as={Col}>
										<Form.Label>DATA OD</Form.Label>
										<Form.Control type="date" id="from" placeholder="YYYY-MM-DD" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
											value={from}
											onChange={(e) => setFrom(e.target.value)} />
										{isSafari() && <Form.Control.Feedback type="invalid" tooltip>
											Użyj formatu YYYY-MM-DD
										</Form.Control.Feedback>}
									</Form.Group>
									<Form.Group as={Col}>
										<Form.Label>DATA DO</Form.Label>
										<Form.Control type="date" id="to" placeholder="YYYY-MM-DD" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
											value={to}
											onChange={(e) => setTo(e.target.value)} />
										{isSafari() && <Form.Control.Feedback type="invalid" tooltip>
											Użyj formatu YYYY-MM-DD
										</Form.Control.Feedback>}
									</Form.Group>
									<Form.Group as={Col}>
										<Form.Label>OPIS</Form.Label>
										<Form.Control type="text" placeholder="Opis zamówienia/własny" id="description"
											value={description}
											onChange={(e) => setDescription(e.target.value)} />
									</Form.Group>
								</Form.Group>
							</Collapse>
							<Form.Group as={Form.Row}>
								<Form.Group as={Col} xs="auto" className="search-button">
									<ButtonComponent
										onClick={(_: React.MouseEvent) => setAdvancedSearch((prev) => !prev)}
										margin="0"
									>
										<FaSlidersH />
									</ButtonComponent>
								</Form.Group>
								<Form.Group as={Col} xs="auto" className="search-button">
									<ButtonComponent
										type="submit"
										margin="0"
										text="Szukaj"
									/>
								</Form.Group>
							</Form.Group>
						</Form.Row>
					) : (
						<>
							<Form.Row>
								<Form.Group className="flex-end" as={Col}>
									<Form.Label>NUMER ZAMÓWIENIA</Form.Label>
									<Form.Control type="text" placeholder="Numer zamówienia" id="orderNumber"
										value={orderNumber}
										onChange={(e) => setOrderNumber(e.target.value)} />
								</Form.Group>
								<Collapse in={advancedSearch}>
									<Form.Group className="flex-end" as={Col}>
										<Form.Label>NUMER KLIENTA</Form.Label>
										<Form.Control type="text" placeholder="Numer klienta" id="customerOrderNumber"
											value={customerOrderNumber}
											onChange={(e) => setCustomerOrderNumber(e.target.value)} />
									</Form.Group>
								</Collapse>
							</Form.Row>
							<Collapse in={advancedSearch}>
								<div>
									<Form.Row>
										<Form.Group className="flex-end" as={Col}>
											<Form.Label>DATA OD</Form.Label>
											<Form.Control type="date" id="from" placeholder="YYYY-MM-DD" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
												value={from}
												onChange={(e) => setFrom(e.target.value)} />
											{isSafari() && <Form.Control.Feedback type="invalid" tooltip>
												Użyj formatu YYYY-MM-DD
											</Form.Control.Feedback>}
										</Form.Group>
										<Form.Group className="flex-end" as={Col}>
											<Form.Label>DATA DO</Form.Label>
											<Form.Control type="date" id="to" placeholder="YYYY-MM-DD" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
												value={to}
												onChange={(e) => setTo(e.target.value)} />
											{isSafari() && <Form.Control.Feedback type="invalid" tooltip>
												Użyj formatu YYYY-MM-DD
											</Form.Control.Feedback>}
										</Form.Group>
									</Form.Row>
									<Form.Row>
										<Form.Group className="flex-end" as={Col}>
											<Form.Label>OPIS ZAMÓWIENIA/WŁASNY</Form.Label>
											<Form.Control type="text" id="description"
												value={description}
												onChange={(e) => setDescription(e.target.value)} />
										</Form.Group>
									</Form.Row>
								</div>
							</Collapse>
							<Form.Row className="adv-search-buttons-row">
								<ButtonComponent
									marginTop="0"
									marginLeft="5px"
									onClick={(_: React.MouseEvent) => setAdvancedSearch((prev) => !prev)}
								>
									<FaSlidersH />
								</ButtonComponent>
								<ButtonComponent
									marginTop="0"
									marginRight="5px"
									type="submit"
									text="Szukaj"
								/>
							</Form.Row>
						</>
					)}
					<Collapse in={advancedSearch}>
						<Form.Row>
							<Col>
								{_.map(OrderStatusNameToLabel, (name, status) => (
									<Form.Check
										inline
										key={status}
										id={'Order-filter-' + status}
										label={name}
										value={status}
										checked={statusFilter[status as OrderStatusName]}
										onChange={handleFilterChange}
									/>
								))}
							</Col>
						</Form.Row>
					</Collapse>
				</div>
			</Form>
			{isDesktop && (
				<Row className="justify-content-end">
					<FormLabel column xs="auto" htmlFor="rowCount" className="pr-0">
						Ilość na stronie
					</FormLabel>
					<Col xs="auto">
						<Form.Control
							as="select"
							size="sm"
							id="rowCount"
							value={state.pageSize}
							onChange={handleChangePageSize}
						>
							<option value={10}>10</option>
							<option value={20}>20</option>
							<option value={50}>50</option>
							<option value={100}>100</option>
						</Form.Control>
					</Col>
					<BsFillGearFill
						size={24}
						className="settings-icon"
						onClick={() => setShowOrdersSettings(true)}
						style={{ marginRight: "auto" }}
					/>
					<Col xs="auto" style={{ marginLeft: '15px' }}>
						<div className="d-flex justify-content-end">
							<Pagination
								selected={pageNumber}
								size={numberOfPages}
								href={(page) => `${process.env.PUBLIC_URL}/orders/${page + 1}`}
								onSelect={(page) => history.push(`${process.env.PUBLIC_URL}/orders/${page + 1}`, state)}
							/>
						</div>
					</Col>
				</Row>
			)}
			{isDesktop ? (
				<Table className="Order-table" size="sm" borderless striped>
					<thead>
						<tr>
							<th className="cell-width-15per">
								Numer
								<SortButtons
									value={{ direction: orderDirection, group: 'orderNumber' }}
									onChange={handleSortChange}
									orderBy={orderBy}
								/>
							</th>
							<th className="cell-width-15per">
								Numer klienta
								<SortButtons
									value={{ direction: orderDirection, group: 'customerOrderNumber' }}
									onChange={handleSortChange}
									orderBy={orderBy}
								/>
							</th>
							<th className="cell-width-9per">
								Data
								<SortButtons
									value={{ direction: orderDirection, group: 'date' }}
									onChange={handleSortChange}
									orderBy={orderBy}
								/>
							</th>
							<th className="cell-width-9per">
								Status
								<SortButtons
									value={{ direction: orderDirection, group: 'status' }}
									onChange={handleSortChange}
									orderBy={orderBy}
								/>
							</th>
							<th className="cell-width-9per">
								Netto
								<SortButtons
									value={{ direction: orderDirection, group: 'net' }}
									onChange={handleSortChange}
									orderBy={orderBy}
								/>
							</th>
							<th className="cell-width-9per">
								VAT
								<SortButtons
									value={{ direction: orderDirection, group: 'vat' }}
									onChange={handleSortChange}
									orderBy={orderBy}
								/>
							</th>
							<th className="cell-width-9per">
								Brutto
								<SortButtons
									value={{ direction: orderDirection, group: 'gross' }}
									onChange={handleSortChange}
									orderBy={orderBy}
								/>
							</th>
							<th className="cell-width-9per">
								Waga
								<SortButtons
									value={{ direction: orderDirection, group: 'weight' }}
									onChange={handleSortChange}
									orderBy={orderBy}
								/>
							</th>
							<th className="cell-max-width-60">Opis</th>
							<th className="cell-max-width-60">Akcje</th>
						</tr>
					</thead>
					<tbody>
						{ordersOnPage.map((order) => (
							<tr key={order.orderNumber}>
								<td>{order.orderNumber}</td>
								<td>{order.customerOrderNumber}</td>
								<td>{order.date}</td>
								{order.status !== null ? (
									<OverlayTrigger
										overlay={<Tooltip id="order_status_description_tooltip">{order.status}</Tooltip>}
									>
										<td style={{ cursor: 'pointer' }} onClick={() => {showOrderStatus(order)}}>{order.status}</td>
									</OverlayTrigger>
								) : <td style={{ cursor: 'pointer' }} onClick={() => {showOrderStatus(order)}}>{order.status}</td>}
								<td><div className="price-td-div net-price-td" style={{ width: netPriceWidth }}>{order.net.toFixed(2)}</div></td>
								<td><div className="price-td-div vat-price-td" style={{ width: vatPriceWidth }}>{order.vat.toFixed(2)}</div></td>
								<td><div className="price-td-div gross-price-td" style={{ width: grossPriceWidth }}>{order.gross.toFixed(2)}</div></td>
								<td><div className="price-td-div wieght-price-td" style={{ width: weightWidth }}>{order.weight.toFixed(2)}</div></td>
								<td className="cell-width-50">
									{((order.description && order.description.length !== 0) || (order.customerDescription && order.customerDescription.length !== 0)) && (
										<OverlayTrigger overlay={<OrderTooltip id="order_description_tooltip" messages={{
											'Opis zamówienia': order.description, 
											'Opis własny klienta': order.customerDescription
										}}/>}>
											<FaSearch />
										</OverlayTrigger>
									)}
								</td>
								<td className="cell-width-140">
									<OverlayTrigger
										trigger="click"
										placement="left"
										rootClose
										overlay={
											<Popover className="popover-visibility" id={`order-${order.orderNumber}-action-popover`}>
												<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
													{order.isEditable ?
														<Button
															variant="link"
															className="action-button"
															style={{ color: 'black' }}
															onClick={() => handleEditOrder(order.id)}
														>
															<FaRegEdit /> Edytuj zamówienie
														</Button>
														:
														<></>
													}
													{order.isDeletable ?
														<Button
															variant="link"
															className="action-button"
															style={{ color: 'black' }}
															disabled={order.status === "Zamknięte"}
															onClick={() => {
																setOrderToDelete(order);
																setShowDeleteConfirmation(true);
															}}
														>
															<FaRegTrashAlt /> Usuń zamówienie
														</Button>
														:
														<></>
													}
													{order.isPrintable ?
														<Button
															variant="link"
															className="action-button"
															style={{ color: 'black' }}
															onClick={() => {
																getOrderPdf(order.id, order.net, order.vat, order.gross);
															}}
														>
															<FaRegFilePdf /> Pobierz PDF
														</Button>
														:
														<></>
													}
													{order.isDeletable ?
														<Button
															variant="link"
															className="action-button"
															style={{ color: 'black', visibility: order.isDeletable ? 'visible' : 'hidden' }}
															onClick={() => {
																setOrderToCopy(order);
																setShowCopyOrderConfirmation(true);
															}}
														>
															<FaCopy /> Kopiuj zamówienie
														</Button>
														:
														<></>
													}
												</div>
											</Popover>
										}
									>
										<Button
											variant="link"
											className="action-button"
											style={{ color: 'black', visibility: order.isEditable ? 'visible' : 'hidden' }}
										>
											<MdMoreHoriz />
										</Button>
									</OverlayTrigger>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			) : (
				<div>
					<div className="sort-block-mobile">
						<span>Sortuj:</span>
						<Form.Control as="select" size="sm" defaultValue={'dateDown'}
							onChange={evt => handleSortChange(SORT_VALUES_MOBILE[evt.currentTarget.value])}>
							<option value="idDown">Po numerze malejąco</option>
							<option value="idUp">Po numerze rosnąco</option>
							<option value="clientIdDown">Po numerze klienta malejąco</option>
							<option value="clientIdUp">Po numerze klienta rosnąco</option>
							<option value="dateDown">Po dacie malejąco</option>
							<option value="dateUp">Po dacie rosnąco</option>
							<option value="statusDown">Po statusie malejąco</option>
							<option value="statusUp">Po statusie rosnąco</option>
							<option value="netDown">Po cenie netto malejąco</option>
							<option value="netUp">Po cenie netto rosnąco</option>
							<option value="vatDown">Po wartości VAT malejąco</option>
							<option value="vatUp">Po wartości VAT rosnąco</option>
							<option value="grossDown">Po cenie brutto malejąco</option>
							<option value="grossUp">Po cenie brutto rosnąco</option>
							<option value="weightDown">Po wadze malejąco</option>
							<option value="weightUp">Po wadze rosnąco</option>
						</Form.Control>
					</div>
					{_.take(selectedFilteredOrders, (pageNumber + 1) * state.pageSize).map((order, index) => (
						<div key={index}
							className={'Order-block-item' + (opens[index] ? ' rotate' : '')}
							onClick={() => {
								const arr = opens.splice(0);
								arr[index] = !arr[index];
								setOpens(arr);
							}}>
							<div>
								<div className="Order-block-item-content-left">
									<span>Numer:</span>
									<hr />
									<b>{order.orderNumber}</b>
								</div>
								<div className="d-flex align-items-center" onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									showOrderStatus(order);
								}}>
									<span style={{ marginRight: '6px' }}>Status:</span>
									{ORDER_STATUS_NAMES_ICON[order.status]}
								</div>
							</div>
							<div>
								<div className="Order-block-item-content-left">
									<span>Numer klienta:</span>
									<hr />
									<b>{order.customerOrderNumber}</b>
								</div>
								<div className="Order-block-item-content-right">
									<span>Data:</span>
									<hr />
									<b>{order.date}</b>
								</div>
							</div>
							<Collapse in={opens[index]}>
								<section>
									<div>
										<div className="Order-block-item-content-left">
											<span>Netto:</span>
											<hr />
											<b>{order.net.toFixed(2)}</b>
										</div>
										<div className="Order-block-item-content-right">
											<span>Brutto:</span>
											<hr />
											<b>{order.gross.toFixed(2)}</b>
										</div>
									</div>
									<div>
										<div className="Order-block-item-content-left">
											<span>VAT:</span>
											<hr />
											<b>{order.vat.toFixed(2)}</b>
										</div>
										<div className="Order-block-item-content-right">
											<span>Waga:</span>
											<hr />
											<b>{order.weight}</b>
										</div>
									</div>
									<div className="Order-block-item-content-left">
										<span>Opis:</span>
										<hr />
										<b style={{
											wordWrap: "break-word",
											maxWidth: "100%",
											textAlign: "right"
										}}>{order.description}</b>
									</div>
									<div className="Order-block-item-content-left" >
										<span style={{ whiteSpace: "nowrap" }}>Opis klienta:</span>
										<hr />
										<b style={{
											wordWrap: "break-word",
											maxWidth: "100%",
											textAlign: "right"
										}}>{order.customerDescription}</b>
									</div>
									<div style={{ marginBottom: '15px' }}>
										{order.isEditable ?
											<Button
												onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
													e.stopPropagation();
													handleEditOrder(order.id);
												}}
												variant="link"
												style={{ color: 'black' }}
											>
												<FaRegEdit size={24} />
											</Button>
											:
											<></>
										}
										{order.isPrintable ?
											<Button
												onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
													e.stopPropagation();
													getOrderPdf(order.id, order.net, order.vat, order.gross);
												}}
												variant="link"
												style={{ color: 'black' }}
											>
												<FaRegFilePdf size={24} />
											</Button>
											:
											<></>
										}
										{order.isDeletable ?
											<Button
												onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
													e.stopPropagation();
													setOrderToDelete(order);
													setShowDeleteConfirmation(true);
												}}
												variant="link"
												style={{ color: 'black' }}
											>
												<FaRegTrashAlt size={24} />
											</Button>
											:
											<></>
										}
										{order.isDeletable ?
											<Button
												onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
													e.stopPropagation();
													setOrderToCopy(order);
													setShowCopyOrderConfirmation(true);
												}}
												variant="link"
												style={{ color: 'black' }}
											>
												<FaCopy size={24} />
											</Button>
											:
											<></>
										}
									</div>
								</section>
							</Collapse>
						</div>
					))}
					<div>
						{(pageNumber + 1) * state.pageSize > _.size(selectedFilteredOrders) ? (<div></div>) : (
							<Button
								onClick={() => {
									setScrollPosition(window.pageYOffset);
									history.push(`${process.env.PUBLIC_URL}/orders/${+(page ? page : 1) + 1}`, state);
								}}
								style={{ marginTop: 10 }}
							>
								Pokaż więcej
							</Button>
						)}
					</div>
				</div>
			)}
			{isDesktop && bottomPagVisibility && (
				<Row className="justify-content-end">
					<Col xs="auto" style={{ marginLeft: '15px' }}>
						<div className="d-flex justify-content-end">
							<Pagination
								selected={pageNumber}
								size={numberOfPages}
								href={(page) => `${process.env.PUBLIC_URL}/orders/${page + 1}`}
								onSelect={(page) => history.push(`${process.env.PUBLIC_URL}/orders/${page + 1}`, state)}
							/>
						</div>
					</Col>
				</Row>
			)}
			<Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)} backdrop="static">
				<Modal.Body>{`Czy na pewno chcesz usunąć zamówienie o numerze ${orderToDelete?.orderNumber}`}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
						Anuluj
					</Button>
					<Button variant="primary" onClick={() => handleOrderDelete()}>
						Usuń
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showDeleteStatus} onHide={() => setShowDeleteStatus(false)} backdrop="static">
				<Modal.Body>{`Poprawnie usunięto zamówienie o numerze ${orderToDelete?.orderNumber}`}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowDeleteStatus(false)}>
						Ok
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showCopyOrderConfirmation} onHide={() => setShowCopyOrderConfirmation(false)} backdrop="static">
				<Modal.Body>{`Ta akcja spowoduje wyczyszcenie koszyka i zastąpienie jego zawartości produktami zamówienia ${orderToCopy?.orderNumber}`}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowCopyOrderConfirmation(false)}>
						Anuluj
					</Button>
					<Button variant="primary" onClick={() => handleCopyOrder(orderToCopy!.id)}>
						Kontynuuj
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
