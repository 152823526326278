import React, { useState, useEffect } from 'react';
import EditableText from 'components/EditableText';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getTermsAndConditions, setTermsAndConditions } from 'lib/communication/promotionalProgram';
import Spinner from 'components/Spinner/Spinner';

export default function Terms() {
	const termsAndConditions = useSelector((state: AppState) => state.data.promotionalProgram.termsAndConditions);
	const isFetching = useSelector((state: AppState) => state.data.promotionalProgram.props.isFetching);
	const termName = useSelector((state: AppState) => state.conf.promotion);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (termName !== "") {
			getTermsAndConditions(termName)
				.then(result => setError(!result));
		}
	}, [termName]);

	if (isFetching || error) {
		return <Spinner showError={error} />
	}

	return <>
		<EditableText value={termsAndConditions} onSubmit={(val) => setTermsAndConditions(val, termName)} />
	</>;
}
